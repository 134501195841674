(function () {
  'use strict';

  angular
    .module('app')
    .service('AchievementsService', AchievementsService);

  /* @ngInject */
  function AchievementsService($http, $httpParamSerializer, config, TokenService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getAchievements = function (pageNumber, pageSize, fieldSort, orderSort, filters, idDatosIniciativa) {
      ///api/logroes
      var url = config.api + "/api/logroes";


      var filtroCodigo = null;
      if (filters && filters.code) {
        filtroCodigo = filters.code;
      }

      var filtroFecha = null;
      if (filters && filters.date) {
        filtroFecha = filters.date;
      }


      var params = $httpParamSerializer({
        datosIniciativa: idDatosIniciativa,
        size: pageSize,
        page: pageNumber, //antes habia que restarle 1
        sort: getSort(fieldSort, orderSort),
        codigoCorto: filtroCodigo,
        fechaEvaluacion: filtroFecha,

      });
      url += "?" + params;

      return $http.get(url, {headers: headers}).then(function (response) {
        if (response.data) {
          return response.data.map(function (element) {
            var prettyDate = moment(element.fechaEvaluacion, "YYYY-MM-DD").format("DD/MM/YYYY");
            element.fechaBonita = prettyDate;
			
			if (element.lstOrganizacionesBeneficiariasNumber != null && element.lstOrganizacionesBeneficiariasNumber != '' && typeof(element.lstOrganizacionesBeneficiariasNumber) != 'undefined')
			{
				element.numOrgBeneficiarias = element.numOrgBeneficiarias + parseInt(element.lstOrganizacionesBeneficiariasNumber);
			}
                  
            if (isNaN(parseInt(element.numBeneficiariosIndirectos)))
                element.numBeneficiariosIndirectos = 0;
            else
                element.numBeneficiariosIndirectos = parseInt(element.numBeneficiariosIndirectos);
            
            return element;
          });
        }
        else {
          return [];
        }

      });
    }

    service.createAchievement = function (entity) {
      //post /api/entidads
      var url = api + "/api/logroes";
      return $http.post(url, entity, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.getAchievement = function (id) {
      var url = api + "/api/logroes";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.updateAchievement = function (entity) {
      var url = api + "/api/logroes";
      if (entity && entity.id != null) {
        // url += "/" + entity.id;
        return $http.put(url, entity, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.deleteAchievement = function (entity) {
      var url = api + "/api/logroes";
      if (entity.id != null) {
        url += "/" + entity.id;
        return $http.delete(url, {headers: headers}).then(function (response) {
          return response;
        });
      }
      else {
        return false;
      }
    };

    service.activeDesactiveAchievement = function (user) {
      var url = api + "/api/logroes/validate";
      if (user && user.id != null) {
        url += "/" + user.id;
        return $http.put(url, {}, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    return service;
  }


  function getSort(fieldSort, orderSort) {
    var sort = null;
    if (fieldSort) {
      sort = fieldSort + ",";
      if (orderSort) {
        sort += "asc"
      }
      else {
        sort += "desc"
      }
    }

    return sort;
  }

})();
