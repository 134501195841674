angular
  .module('app')
  .constant('translations_EN', {
	"yes":"Yes",
	"no":"No",
	"location":"ODS_iconsEN",
	"logout.cerrarSesion":"Sign out",
	"logout.preguntaCerrarSesion":"Do you wish to sign out?",
	"error.internalServerError":"An error has occurred. Please contact the administrator for more information.",
	"err.genericError":"An error has occurred. Please contact the administrator for more information.",
	"Bad credentials":"Authentication error: 'Incorrect user or password'",
	"error.accessDenied":"You do not have permission to perform this action",
	"error.requiredFieldEmpty":"Required fields have not been filled in",
	"error.notFoundEntity":"The item you are trying to access does not exist",
	"error.noPossibleEditEntity":"The item cannot be edited because it is currently in use",
	"error.duplicateEntity":"Cannot create item because it already exists",
	"err.excelRequired":"An excel file must be attached before saving",
	"err.grupoLBGHasAResposible":"A coordinator already exists for the selected LBG group",
	"err.entidadHasAResposible":"A top manager already exists for the selected entity",
	"err.planUsoMaxUserExceeded":"The plan does not allow more users with the selected role",
	"err.excelRequired":"The file with the exchange rate values ​​is required",
	"err.budgetOverflow":"The budget of the entity should not be greater than the total budget of the project",
	"err.notTypeChange":"There is no exchange rate to apply to this project",
	"err.notValueTypeChange":"There is no exchange rate value for this project",
	"err.deleteDocument":"Error while trying to delete a document",
	"err.DateOutRange":"The date entered is outside the project range",
	"err.percentajeArea":"The sum of the percentage of subject focuses exceeds 100%",
	"err.percentajeODS":"The sum of the SDG percentages exceeds 100%",
	"err.requiredFieldColaboration":"Required fields from the list of collaborators are empty",
	"err.DateOutRange":"Date of the contribution",
	"err.FailsBeneficiaryRequirement":"The impact does not meet the requirements",
	"err.EmpEvaluatedGreaterParticipant":"The nº of evaluated employees cannot be greater than the participants",
	"err.EntityEvaluatedGreaterParticipant":"The nº of evaluated entities cannot be greater than the participants",
	"err.incompleteArea":"Subject focus information is not complete",
	"err.incompleteODS":"Goals information is not complete",
	"err.dateIniciativeBeforeDateProject":"The start date of the initiative cannot be prior to the start of the project",
	"err.dateIniciativeAfterDateProject":"The start date of the initiative cannot be after the end date of the project",
	"err.NotDeleteValidContribution":"You cannot delete a contribution that has been validated",
	"err.NotEditValidContribution":"You cannot edit a contribution that has been validated",
	"err.NotEditValidAchievement":"You cannot edit an achievement that has been validated",
	"err.NotEditValidImpact":"You cannot edit an impact that has been validated",
	"usuario.rol.error.coordinador":"It was not possible to change the user's role. The group already has a coordinator",
	"usuario.rol.error.responsable":"It was not possible to change the user's role. The entity already has a top manager",
	"usuario.rol.error.disponibles":"It was not possible to change the user's role. The user plan has no available roles",
	"resumen.proyecto.no.existe":"The selected project does not exist",
	"resumen.proyecto.usuario.no.existe":"No user in session",
	"agrupacion.interna.no.existe":"Internal classification does not exist",
	"agrupacion.interna.duplicate":"Group name already in use",
	"agrupacion.IniciativaInterna.duplicate":"Group name already in use",
	"agrupacion.interna.requerido.nombre":"You must indicate the name of the group",
	"colaborador.no.existe":"The selected collaborator does not exist",
	"colaborador.presupuesto.no.existe":"The collaborator budget does not exist",
	"colaborador.representante.no.existe":"The representative collaborator does not exist",
	"colaborador.requerido.nombre":"You must enter the name of the collaborator",
	"colaborador.requerido.tipo":"You must indicate the type of collaborator",
	"contribucion.no.existe":"The selected contribution does not exist",
	"contribucion.tabla.salarial.no.existe":"The contributions table does not exist",
	"contribucion.fuera.rango":"Contribution out of range",
	"contribucion.requerido.tipo":"You must enter the type of contribution",
	"contribucion.requerido.contribuyente":"You must enter the contributor",
	"contribucion.requerido.fecha":"Please enter the date of the contribution",
	"contribucion.requerido.datos.iniciativa":"No initiative has been indicated for the contribution",
	"contribucion.requerido.cantidad.y.divisa":"You must enter the amount and currency of the contribution",
	"contribucion.requerido.numero.persona.y.numero.horas.persona":"You must enter the nº of people and total hours of the contribution",
	"iniciativa.no.existe":"The initiative does not exist",
	"usuario.no.existe":"The selected user does not exist",
	"err.mailExist":"This email address is already in use",
	"err.usuarioExist":"A user with the same email address already exists",
	"usuario.requerido.nombre":"You must enter the username",
	"usuario.requerido.apellidos":"You must enter the user's last name",
	"usuario.requerido.email":"You must enter the user's email address",
	"usuario.requerido.rol":"You must select the user's role",
	"usuario.requerido.entidad.alta":"You must enter the user's entity",
	"usuario.requerido.pais":"You must specify the user's country",
	"usuario.requerido.email.contacto.coordinador.grupo":"You must enter the contact email address",
	"usuario.requerido.grupo.lbg":"You must enter the LBG group",
	"proyecto.no.existe":"The selected project does not exist",
	"entidad.no.existe":"The selected entity does not exist",
	"entidad.representante.no.existe":"The represented entity does not exist",
	"entidad.representante.requerido":"You must enter the represented entity",
	"entidad.alta.no.existe":"The entity does not exist",
	"entidad.requerido.nombre":"You must enter the name of the entity",
	"entidad.requerido.abreviatura":"You must enter the abbreviated name of the entity",
	"entidad.requerido.tipo":"You must enter the type of entity",
	"entidad.requerido.sector":"You must enter the LBG sector",
	"entidad.requerido.plan.uso":"You must enter the user plan",
	"entidad.requerido.pais":"You must enter the country",
	"entidad.requerido.tipo.cambio":"You must enter at least one exchange rate",
	"entidad.requerido.grupo.lbg":"You must enter the entity's LBG group",
	"impacto.no.existe":"The selected impact does not exist",
	"impacto.requerido.fecha.evaluacion":"Please enter the evaluation date",
	"impacto.requerido.datos.iniciativa":"Your must select the initiative",
	"impacto.validacion.beneficiarios.evaluados":"The total under \"degree of impact\" cannot be higher than the nº of beneficiaries evaluated",
	"impacto.empleado.requerido.contribuyente":"You must enter the contributor",
	"impacto.empleado.validacion.evaluados.mayor.participantes":"The nº of evaluated participants cannot be higher than the nº of participants",
	"impacto.participante.requerido.entidad":"You must enter the participating entity",
	"logro.no.existe":"The selected output does not exist",
	"logro.requerido.fecha.evaluacion":"You must enter the evaluation date",
	"logro.requerido.datos.iniciativa":"You must enter the output's initiative",
	"plan.uso.no.existe":"The selected user plan does not exist",
	"plan.uso.requerido.nombre":"You must enter the name of the plan",
	"plan.uso.requerido.gerente":"You must enter the nº of managers",
	"plan.uso.requerido.supervisor.proyecto":"You must enter the nº of project supervisors",
	"plan.uso.requerido.supervisor.iniciativa":"You must enter the nº of initiative supervisors",
	"plan.uso.requerido.agente":"You must enter the nº of agents",
	"tabla.salarial.no.existe":"The contributions table does not exist",
	"tabla.salarial.requerido.nombre":"You must enter a name for the contribution table",
	"tabla.salarial.requerido.total.coste":"You must enter the total cost",
	"tabla.salarial.requerido.divisa":"You must enter the currency associated with the total cost",
	"tabla.salarial.requerido.agno":"You must enter the year",
	"tabla.salarial.requerido.agno.aplicacion":"You must enter the year of application",
	"unidad.negocio.no.existe":"The business unit does not exist",
	"unidad.negocio.requerido.nombre":"You must enter the name of the business unit",
	"ods.no.existe":"The selected SDG does not exist",
	"pais.no.existe":"The selected country does not exist",
	"pais.requerido":"You must enter the country",
	"divisa.no.existe":"The selected currency does not exist",
	"asignacion.presupuesto.iniciativa.requerido.presupuesto":"You must enter a budget amount",
	"asignacion.presupuesto.iniciativa.requerido.divisa":"You must enter the budget currency",
	"datos.iniciativa.requerido.nombre":"You must enter the name of the initiative",
	"datos.iniciativa.requerido.agrupacionIniciativaInterna":"You must indicate the internal initiative grouping",
	"datos.iniciativa.requerido.fecha.inicio":"You must enter the start date of the initiative",
	"datos.iniciativa.requerido.pais":"You must enter the country",
	"datos.proyecto.requerido.fecha.inicio":"You must enter the start date of the project",
	"datos.proyecto.requerido.unidad":"You must select the business unit for the project",
	"datos.proyecto.requerido.nombre":"You must enter the name of the project",
	"datos.proyecto.requerido.divisa.ppto.entidad":"You must enter the currency of the entity's budget",
	"datos.proyecto.requerido.divisa.ppto.total":"You must enter the currency of the total budget",
	"organizaciones.beneficiarias.requerido.nombre":"You must enter the name of the organisation",
	"tipo.cambio.requerido.nombre":"You must enter the name of the exchange rate",
	"tipo.cambio.requerido.descripcion":"You must enter a description",
	"tipo.cambio.requerido.fecha.desde":"You must enter the date on which the period of validity of the exchange rate starts",
	"tipo.cambio.requerido.fecha.hasta":"You must enter the date on which the period of validity of the exchange rate ends",
	"tipo.cambio.requerido.grupo.lbg":"You must enter the LBG group for which the exchange rate will be applied",
	"permiso.denegado":"You do not have permission to perform this action",
	"permiso.denegado.iniciativa":"You do not have permission to access the initiative",
	"permiso.denegado.contribucion":"You do not have permission to access contributions",
	"permiso.denegado.logro":"You do not have permission to access outputs",
	"permiso.denegado.impacto":"You do not have permission to access impacts",
	"datos.iniciativa.validacion.fuera.rango.proyecto":"The dates of the initiative must be within the start and the end date of the project",
	"colaboracion.iniciativa.requerido.lista.colaboradores.motivacion.propia":"You must enter a motivation for your entity",
	"colaboracion.iniciativa.requerido.lista.colaboradores.grado.implicacion":"You must enter the collaborator's degree of involvement",
	"iniciativa.ods.requerido.porcentaje.primario":"You must enter the percentage of the primary SDG",
	"iniciativa.ods.validacion.porcentaje.incompleto":"The sum of the total percentage of primary and secondary SDGs should be 100%",
	"iniciativa.requerido.area.primaria":"You must enter the primary subject focus",
	"iniciativa.validacion.area.porcentaje.incompleto":"The sum of the total percentages of primary and secondary subject focuses should be 100%",
	"unidad.negocio.validacion.nombre.duplicado":"This business unit's name is already in use",
	"tabla.salarial.requerido.divisa.coste.hora":"You must enter the currency associated with the hour cost",
	"entidad.validacion.nombre.duplicado":"The entity name already exists",
	"contacto.email.campos.requeridos":"All fields are required",
	"impacto.empleado.validacion.evaluados.mayor.participantes.actitud":"The nº of participants whose attitude has been evaluated cannot be greater than the nº of participants",
	"impacto.empleado.validacion.evaluados.mayor.participantes.habilidad":"The nº of participants whose skills have been evaluated cannot be greater than the nº of participants",
	"impacto.empleado.validacion.evaluados.mayor.participantes.desarrollo":"The nº of participants whose personal development has been evaluated cannot be greater than the nº of participants",
	"ADMINISTRADOR":"Administrator",
	"COORDINADOR_GRUPO":"Group coordinator",
	"RESPONSABLE":"Top manager",
	"GERENTE":"Manager",
	"SUPERVISOR_PROYECTO":"Project supervisor",
	"SUPERVISOR_INICIATIVA":"Initiative supervisor",
	"AGENTE":"Agent",
	"SOLOVISTA":"Manager view only",
	"BASICO":"Basic",
	"DINERO":"Cash",
	"ESPECIE":"In kind",
	"TIEMPO":"Time",
	"PROBONO":"Pro bono",
	"COSTE_GESTION":"Management costs",
	"ALTO":"High",
	"MEDIO":"Medium",
	"BAJO":"Low",
	"COLABORADOR":"Collaborator",
	"ORG_BENEFICIARIA":"Beneficiary organisation",
	"SI":"Yes",
	"NO":"No",
	"SIN_CAMBIOS":"No difference",
	"POCO":"A little",
	"ALGO":"Some difference",
	"BASTANTE":"A lot",
	"Nueva Entidad":"New entity",
	"outputsform.new":"Edit output",
	"outputsform.edit":"New output",
	"outputsform.fechaevaluacion":"Date of evaluation",
	"outputsform.descripcion":"Description",
	"outputsform.benefiariosDirectos":"Nº of beneficiaries",
	"outputsform.numBeneficiarios":"Nº of beneficiaries",
	"outputsform.tipo":"Type",
	"outputsform.situacion":"Situation",
	"outputsform.observaciones":"Description",
	"outputsform.beneficiariosIndirectos":"Indirect beneficiaries",
	"outputsform.numLogrosMedioambientales":"Nº of environmental outputs",
	"outputsform.organizacionesBeneficiarias":"Beneficiary organisations",
	"outputsform.tipoSimple":"Type",
	"outputsform.nombreOrganizacion":"Name of the organisation",
	"outputsform.descripcionSimple":"Description",
	"outputsform.beneficiariaPregunta":"Is it a beneficiary?",
	"outputsform.addNuevaOrganizacion":"Add new organisation",
	"outputsform.deleteOrganizacion":"Delete organisation",
	"outputsform.LogrosEntidadesParticipantes":"Outputs from participating entities",
	"outputsform.logrosGrupoIntereses":"Outputs with stakeholders",
	"outputsform.entidadGrupoIntereses":"Entity",
	"outputsform.gruposInteresAlcanzados":"Stakeholders reached",
	"outputsform.logrosInteresNumAbrev":"Nº",
	"outputsform.logrosInteresObservaciones":"Comments",
	"outputsform.alianzasEntidad":"Entity",
	"outputsform.alianzasGenerando":"Collaborations achieved",
	"outputsform.alianzasNum":"Nº",
	"outputsform.alianzasComentarios":"Comments",
	"outputsform.addAlianza":"Add new collaboration",
	"outputsform.deleteAlianza":"Delete collaboration",
	"outputsform.mediosComunicacion":"Media",
	"outputsform.mediosComunicacionEntidad":"Entity",
	"outputsform.mediosComunicacionValor":"Media value",
	"outputsform.mediosComunicacionDivisa":"Currency",
	"outputsform.mediosComunicacionObservaciones":"Comments",
	"outputsform.addMedioComunicacion":"Add a new media group",
	"outputsform.deleteMediosComunicacion":"Delete media information",
	"outputsform.cumplimiento":"Goals fulfilment and initiative's general evaluation",
	"outputsform.cumplimientoEntidad":"Entity",
	"outputsform.cumplimientoAlineacion":"Alignment with the mission",
	"outputsform.cumplimientoObservaciones":"Comments",
	"outputsform.addCumplimiento":"Add goal fulfilment",
	"outputsform.deleteCumplimiento":"Delete goal",
	"outputsform.alineacion":"Degree of alignment of results with the initiative's goals",
	"outputsform.eficiencia":"Efficiency in the execution of the initiative",
	"outputsform.sostenibilidad":"Economic sustainability",
	"outputsform.eficacia":"Effectiveness in the implementation of the initiative",
	"outputsform.conclusionesAlcanzadas":"Conclusions reached",
	"outputs.output":"Output",
	"outputs.codigo":"Code",
	"outputs.fecha":"Date",
	"outputs.descripcion":"Description",
	"outputs.beneficiariosDirectos":"Direct beneficiaries",
	"outputs.organizacionesBeneficiarias":"Beneficiary organisations",
	"outputs.logosAmbientales":"Environmental outputs",
	"outputs.editar":"Edit",
	"outputs.adjuntarArchivo":"Attach file",
	"outputs.duplicar":"Duplicate",
	"outputs.devalidate":"Remove validation",
	"outputs.validate":"Validate",
	"outputs.delete":"Delete",
	"outputs.deleteMessage":"Delete output",
	"outputs.areSure":"Are you sure?",
	"outputs.cancel":"Cancel",
	"breadcrumb.gestion":"MANAGEMENT",
	"breadcrumb.entidades":"ENTITIES",
	"breadcrumb.usuarios":"USERS",
	"breadcrumb.planesUso":"USER PLAN",
	"breadcrumb.tiposCambio":"EXCHANGE RATES",
	"breadcrumb.colaboradores":"COLLABORATORS",
	"breadcrumb.resumen":"SUMMARY",
	"breadcrumb.contribuciones":"CONTRIBUTIONS",
	"breadcrumb.logros":"OUTPUTS",
	"breadcrumb.impactos":"IMPACTS",
	"breadcrumb.iniciativas":"INITIATIVES",
	"breadcrumb.proyectos":"PROJECTS",
	"breadcrumb.nuevaEntidad":"New entity",
	"breadcrumb.editarEntidad":"Edit entity",
	"collaboratorform.edit":"Edit collaborator",
	"collaboratorform.new":"New collaborator",
	"collaboratorform.nombre":"Name",
	"collaboratorform.descripcion":"Description",
	"collaboratorform.cif":"VAT/Tax ID Code",
	"collaboratorform.pais":"Country",
	"collaboratorform.region":"Region",
	"collaboratorform.tipoEntidad":"Type of entity",
	"collaboratorform.guardar":"Save",
	"collaboratorform.cancelar":"Cancel",
	"collaboratorform.volver":"Go back to the list of collaborators",
	"collaboratorform.agrupacionInternaIniciativasObligatorio":"It is mandatory to fill in the field of Initiative Internal classification",
	"collaborator.codigo":"Code",
	"collaborator.nombre":"First name",
	"collaborator.descripcion":"Description",
	"collaborator.pais":"Country",
	"collaborator.region":"Region",
	"collaborator.tipoEntidad":"Entity type",
	"collaborator.adjuntar":"Attach file",
	"collaborator.editar":"Edit",
	"collaborator.borrar":"Delete",
	"collaborator.errorBorrando":"Deleting error",
	"collaborator.noPermission":"You do not have permission to perform this action",
	"collaborator.ok":"Ok",
	"collaborator.borrarColaborador":"Delete collaborator",
	"collaborator.estasSeguro":"Are you sure?",
	"collaborator.borrar":"Delete",
	"collaborator.cancelar":"Cancel",
	"contact.titulo":"Contact",
	"contact.mensajeEnviadoCorrectamente":"Message sent successfully",
	"contact.asunto":"Subject focus",
	"contact.comentario":"Comments",
	"contact.enviar":"Submit",
	"contact.volver":"Go back",
	"contributions.atribucion":"Attribution",
	"contributions.codigo":"Code",
	"contributions.tipo":"Type",
	"contributions.contribuyente":"Contributor",
	"contributions.fecha":"Date",
	"contributions.coste":"Amount",
	"contributions.divisa":"Currency",
	"contributions.descripcion":"Description",
	"contributions.aportacionesLey112018":"Foundation or NGO?",
	"contributions.aportacionesLey112018Tooltip":"Is it a contribution to a foundation/NGO?",
	"contributions.esFundacionONL":"Contribution to a foundation/NGO",
	"contributions.NOesFundacionONL":"Not a contribution to a foundation/NGO",
	"contributions.tablaSalarial":"Salary table",
	"contributions.numPersonas":"Nº of people",
	"contributions.horasTotales":"Hours",
	"contributions.costeHora":"Cost / hour",
	"contributions.divisa":"Currency",
	"contributions.eliminar":"Delete",
	"contributions.archivoAdjunto":"Attach file",
	"contributions.duplicar":"Duplicate",
	"contributions.addContribucion":"New contribution",
	"contributions.sumatorio":"Total amount",
	"contributions.borrarContribucion":"Delete contribution",
	"contributions.estasSeguro":"Are you sure?",
	"contributions.borrar":"Delete",
	"contributions.cancelar":"Cancel",
	"contributions.quitarValidacion":"Invalidate",
	"contributions.validar":"Validate",
	"contributions.contribucion":"Contribution",
	"contributions.estasSeguro":"Are you sure?",
	"contributions.validarContribucion":"Validate contribution",
	"contributions.invalidarContribucion":"Invalidate contribution",
	"data.tipoEntidad":"Entity type",
	"data.sector":"Sector",
	"data.responsable":"Top manager",
	"data.nombre":"Name",
	"data.nombreAbreviado":"Short name",
	"data.plan":"PLAN",
	"data.totalUsuarios":"TOTAL users",
	"data.numResponsables":"Nº of top managers",
	"data.numGerentes":"Nº of managers",
	"data.numSupProy":"Nº of project supervisors",
	"data.numSupIni":"Nº of initiative supervisors",
	"data.numAgentes":"Nº of agents",
	"entityform.nombre":"Name",
	"entityform.campoRequerido":"This field is required",
	"entityform.nombreAbreviado":"Short name",
	"entityform.cif":"VAT/Tax ID Code",
	"entityform.pais":"Country",
	"entityform.tipo":"Type",
	"entityform.sectorLBG":"LBG Sector",
	"entityform.grupoLBG":"LBG Group",
	"entityform.responsable":"Top manager",
	"entityform.plan":"Plan",
	"entityform.tipoCambio":"Exchange rate",
	"entityform.activo":"Active",
	"entityform.guardar":"Save",
	"entityform.cancelar":"Cancel",
	"entityform.volver":"Go back to the list of entities",
	"entityform.isLBG":"LBG/ONLBG Entity",
	"entityform.activityHistoryActive":"Enable traceability visualisation",
	"entityform.aportacionesLey112018":"Contribution box Law 11/2018",
	"entityform.activoIniciativasInternas":"Activate initiatives classification",
	"entity.codigo":"Code",
	"entity.nombre":"Name",
	"entity.tipo":"Type",
	"entity.pais":"Country",
	"entity.grupoLbg":"LBG Group",
	"entity.plan":"Plan",
	"entity.numUsuarios":"Nº of users",
	"entity.estado":"Status",
	"entity.editar":"Edit",
	"entity.borrar":"Delete",
	"entity.activar":"Activate",
	"entity.desactivar":"Deactivate",
	"entity.entidad":"Entity",
	"entity.estasSeguro":"Are you sure?",
	"entity.borrarEntidad":"Delete entity",
	"entity.estasSeguro":"Are you sure?",
	"entity.borrar":"Delete",
	"entity.cancelar":"Cancel",
	"entitytoolbar.perfil":"PROFILE",
	"entitytoolbar.datos":"DATA",
	"entitytoolbar.clasificacion":"LAYOUT",
	"entitytoolbar.tablasSalarial":"CONTRIBUTION TABLES",
	"entitytoolbar.datosMin":"Data",
	"entitytoolbar.clasificacionMin":"Classification",
	"entitytoolbar.tablaSalarialMin":"Contribution tables",
	"entitytoolbar.nuevaTablaSalarial":"New table",
	"error.mensajeNoAccion":"You cannot perform this action because there has been an error in currency conversion",
	"error.periodoNoCompletado":"The administrator has not completed the minimum period of USD currency changes. The period comprised is from",
	"error.hasta":"To",
	"exchangerateform.edit":"Edit exchange rate",
	"exchangerateform.new":"New exchange rate",
	"exchangerateform.nombre":"First name",
	"exchangerateform.descripcion":"Description",
	"exchangerateform.desde":"From",
	"exchangerateform.hasta":"To",
	"exchangerateform.grupoLBG":"LBG Group",
	"exchangerateform.archivoExcel":"Excel File",
	"exchangerateform.guardar":"Save",
	"exchangerateform.cancelar":"Cancel",
	"exchangerateform.volver":"Go back to the list of exchange rates",
	"exchangerate.desde":"The minimum period of USD currency changes has not been completed. The period comprised is from 01/01/2012 to 12/31/2027",
	"exchangerate.hasta":"To",
	"exchangerate.nombre":"Name",
	"exchangerate.descripcion":"Description",
	"exchangerate.grupoLGB":"LGB Group",
	"exchangerate.desde":"From",
	"exchangerate.hasta":"To",
	"exchangerate.editar":"Edit",
	"exchangerate.borrarEntidad":"Delete entity",
	"exchangerate.estasSeguro":"Are you sure?",
	"exchangerate.borrar":"Delete",
	"exchangerate.cancelar":"Cancel",
	"forgotpassnew.recuperarPass":"Recover password",
	"forgotpassnew.establecerPass":"Set password",
	"forgotpassnew.requisitos":"Your password must be at least 8 characters long and at least one uppercase, one lowercase, and one number.",
	"forgotpassnew.newPass":"New password",
	"forgotpassnew.repetirPass":"Repeat new password",
	"forgotpassnew.guardar":"Save",
	"forgotpassnew.cancelar":"Cancel",
	"forgotpassnew.passChangedOk":"Password changed correctly",
	"forgotpassnew.passChangedFail1":"The new password must be at least 8 characters long and contain one lowercase capital letter and one digit",
	"forgotpassnew.passChangedFail2":"Passwords do not match",
	"forgotpass.olvidoPass":"You forgot your password",
	"forgotpass.mailEnviado":"Email sent to",
	"forgotpass.reviseBandeja":"Check your inbox",
	"forgotpass.email":"Email",
	"forgotpass.guardar":"Save",
	"forgotpass.cancelar":"Cancel",
	"forgotpass.mailRegistrado":"The email address you have entered is not registered",
	"forgotpass.mailFormatoIncorrecto":"The email address you have entered is not in a correct format",
	"impactsform.edit":"Edit impact",
	"impactsform.new":"New impact",
	"impactsform.fechaEvaluacion":"Evaluation date",
	"impactsform.descripcion":"Description",
	"impactsform.impactosSobreBeneficiarios":"Impact on beneficiaries",
	"impactsform.numBenefDirectos":"Nº of direct beneficiaries",
	"impactsform.numBenefDirectosEval":"Nº of direct beneficiaries evaluated",
	"impactsform.gradoImpacto":"Degree of impact",
	"impactsform.sensibilizados":"Connected",
	"impactsform.num":"Nº",
	"impactsform.observaciones":"Comments",
	"impactsform.conseguidoMejora":"Improved",
	"impactsform.transformados":"Transformed",
	"impactsform.total":"Total",
	"impactsform.tipoImpacto":"Type of impact",
	"impactsform.cambioPositivo":"Experienced a positive change in behaviour or attitude",
	"impactsform.adquiridoNuevasHabilidades":"Developed new skills or an increase in their personal effectiveness",
	"impactsform.impactoPositivoCalidadVida":"Experienced a direct positive impact on their quality of life",
	"impactsform.medioAmbiente":"Environment",
	"impactsform.logrosMedioambientales":"Environmental outputs",
	"impactsform.laIniciativaHaConsegidoImpactosMedioambientales":"Did the initiative have any environmental impacts?",
	"impactsform.cambioComportamientoAmbiental":"Did the initiative change people's environmental behaviour?",
	"impactsform.impactoEmpleadosVoluntarios":"Impact on employees / volunteers",
	"impactsform.seleccionParticipantes":"Selection of participants",
	"impactsform.numParticipantes":"Nº of participants",
	"impactsform.impactoSobreEntidadesParticipantes":"Impact on participating entities",
	"impactsform.entidadesAEvaluar":"Entities to be evaluated",
	"impactsform.entidadesParticipantes":"Participating entities",
	"impactsform.entidadesEvaluadas":"Evaluated entities",
	"impactsform.otrosImpactosConseguidos":"Other impacts achieved",
	"impactsform.guardar":"Save",
	"impactsform.cancelar":"Cancel",
	"impactsform.mejoraronHabilidadesLaborales":"How many people have developed new skills or improved existing ones that allow them to improve their academic / social / physical performance as a result of the activity?",
	"impactsform.mejoraronDesarrolloPersonal":"How many people have experienced a change in their personal development as a result of the initiative?",
	"impactsform.tuvieronCambioPositivoComportamiento":"How many people have experienced behavioural changes that will improve their lives as a result of the activity? Changes in negative attitudes or prejudices should also be taken into account",
	"impactsform.mejoradoRelacionesCGII":"Has the activity helped to improve the relationship with the stakeholders?",
	"impactsform.mejoradoSistemasGestion":"Did the initiative help to improve the entity's management systems (e.g. IT, HR, finance)?",
	"impactsform.aumentadoCapacidadEmplearPersonal":"Did the initiative help the entity to employ more staff / take on more volunteers?",
	"impactsform.experimentadoMejoraServicios":"Did the initiative help to improve existing services or products and/or to provide new services or products?",
	"impactsform.generadoIngresos":"Did the initiative help to generate more income?",
	"impactsform.nuevasOportunidadesAlianzas":"Have new opportunities or alliances arisen?",
	"impactsform.mejoraCredibilidadReputacion":"Have the credibility and reputation of the entity improved?",
	"impactsform.mejoraVisibilidadEntidad":"Has the company's visibility increased?",
	"impact.codigo":"Code",
	"impact.fecha":"Date",
	"impact.descripcion":"Description",
	"impact.beneficiariosEvaluados":"Evaluated beneficiaries",
	"impact.entidadesEvaluadas":"Evaluated entities",
	"impact.impactosAmbientales":"Environmental impacts",
	"impact.editar":"Edit",
	"impact.adjuntarArchivo":"Attach file",
	"impact.duplicar":"Duplicate",
	"impact.borrar":"Delete",
	"impact.quitarValidacion":"Remove validation",
	"impact.validar":"Validate",
	"impact.impacto":"Impact",
	"impact.estasSeguro":"Are you sure?",
	"impact.cancelar":"Cancel",
	"impact.validarImpacto":"Validate impact",
	"impact.borrarImpacto":"Delete impact",
	"initiativeform.edit":"Edit initiative",
	"initiativeform.new":"NEW INITIATIVE",
	"initiativeform.nombre":"Name",
	"initiativeform.pais":"Country",
	"initiativeform.region":"Region",
	"initiativeform.ciudad":"City",
	"initiativeform.descripcion":"Description",
	"initiativeform.fechaInicio":"Start date",
	"initiativeform.fechaFin":"End date",
	"initiativeform.areaActuacionPrimaria":"Primary subject focus",
	"initiativeform.areaActuacionSecundaria":"Secondary subject focus",
	"initiativeform.entidadesContribuyenIniciativa":"Entities that contribute to the initiative",
	"initiativeform.entidades":"Entities",
	"initiativeform.motivacion":"Motivation",
	"initiativeform.gradoImplicacion":"Degree of involvement",
	"initiativeform.borrarColaborador":"Delete collaborator",
	"initiativeform.addColaborador":"Save",
	"initiativeform.cumpleLBG":"Complies with LBG/ONLBG Principles",
	"initiativeform.razones":"Reasons",
	"initiativeform.comentarios":"Comments",
	"initiativeform.odsPrimario":"Primary Sustainable Development Goal (SDG)",
	"initiativeform.metas":"Goals",
	"initiativeform.odsSecundario":"Secondary Sustainable Development Goal (SDG)",
	"initiativeform.presupuestoEntidades":"Budget",
	"initiativeform.entidad":"Entity",
	"initiativeform.presupuesto":"Budget",
	"initiativeform.divisa":"Currency",
	"initiativeform.paisOrigenFondos":"Source of funds",
	"initiativeform.addPresupuesto":"Save",
	"initiativeform.borrarPresupuesto":"Delete budget",
	"initiativeform.asignarUsuarios":"Assign users",
	"initiativeform.guardar":"Save",
	"initiativeform.cancelar":"Cancel",
	"initiativeform.volver":"Go back to the list of initiatives",
	"initiativeform.colaboradorPuntual":"One-off collaboration",
	"initiativeform.socioColaborador":"Partner",
	"initiativeform.Si":"Yes",
	"initiativeform.No":"No",
	"initiativeform.agrupacionIniciativaInterna":"Initiative Internal classification",
	"initiativeform.odstitle":"Sustainable Development Goals",
	"initiativeform.placeholder.ods":"SDG...",
	"initiativeform.placeholder.meta":"Target...",
	"initiativeform.ods":"SDG",
	"initiativeform.meta":"Target",
	"initiativeform.porcentaje":"Percentage",
	"initiativeform.borrarOds":"Remove SDG",
	"initiativeform.addOds":"Save",
	"initiativeresume.resumen":"Summary",
	"initiativeresume.numIniciativas":"Nº of initiatives",
	"initiativeresume.contribucion":"CONTRIBUTION",
	"initiativeresume.currentYear":"Current year",
	"initiativeresume.previousYear":"Previous years",
	"initiativeresume.total":"Total",
	"initiativeresume.dinero":"Cash",
	"initiativeresume.especie":"In kind",
	"initiativeresume.tiempo":"Time",
	"initiativeresume.probono":"Pro bono",
	"initiativeresume.costesGestion":"Management costs",
	"initiativeresume.contribucionTotal":"TOTAL CONTRIBUTION",
	"initiativeresume.otros":"OTHERS",
	"initiativeresume.otrosTotal":"OTHER TOTAL",
	"initiativeresume.efectoMultiplicador":"Leverage",
	"initiativeresume.totalMay":"TOTAL",
	"initiativeresume.presupuesto":"Budget",
	"initiativeresume.cumplimiento":"Fulfilment",
	"initiativeresume.pendiente":"Pending",
	"initiativeresume.volver":"Go back",
	"initiativeassign.finalizarAsignacion":"Finish assignment",
	"initiativeassign.marcarTodos":"Select all",
	"initiativeassign.desmarcarTodos":"Deselect all",
	"initiativeassign.contribuciones":"Contributions",
	"initiativeassign.logros":"Outputs",
	"initiativeassign.impactos":"Impacts",
	"initiativeassign.todos":"All",
	"initiativeassign.codigo":"Code",
	"initiativeassign.nombre":"Name",
	"initiativeassign.apellidos":"Surname",
	"initiativeassign.email":"Email",
	"initiativeassign.rol":"Role",
	"initiativeassign.entidadRepresentada":"Represented entity",
	"initiative.new":"New Initiative",
	"initiative.contribucionTotal":"TOTAL Contribution",
	"initiative.contribucion":"Contribution",
	"initiative.contribuciones":"Contributions",
	"initiative.logros":"Outputs",
	"initiative.impactos":"Impacts",
	"initiative.editar":"Edit",
	"initiative.adjuntarArchivo":"Attach file",
	"initiative.duplicar":"Duplicate",
	"initiative.asignarUsuario":"Assign Users",
	"initiative.borrar":"Delete",
	"initiative.codigo":"Code",
	"initiative.nombre":"Name",
	"initiative.fecha":"Date",
	"initiative.fecha_actualizacion":"Update",
	"initiative.pais":"Country",
	"initiative.area":"Subject focus",
	"initiative.motivacion":"Motivation",
	"initiative.lbg":"LBG",
	"initiative.total":"TOTAL",
	"initiative.mediciones":"Evaluation",
	"initiative.si":"Yes",
	"initiative.no":"No",
	"initiative.borrarProyecto":"Delete Project",
	"initiative.estasSeguro":"Are you sure?",
	"initiative.cancelar":"Cancel",
	"initiative.start_date":"Start Date",
	"initiative.update_date":"Update Date",
	"login.hasOlvidadoPass":"Forgot your password?",
	"login.iniciarSesion":"SIGN IN",
	"login.userMail":"Username / Email",
	"login.pass":"Password",
	"manage.usuarios":"USERS",
	"manage.entidades":"ENTITIES",
	"manage.planesUso":"USER PLANS",
	"manage.tiposCambio":"EXCHANGE RATES",
	"manage.colaboradores":"COLLABORATORS",
	"manage.nuevaEntidad":"New entity",
	"manage.entidad":"Entities",
	"manage.nuevoUsuario":"New user",
	"manage.usuario":"Users",
	"manage.nuevoPlan":"New plan",
	"manage.planUso":"User plan",
	"manage.nuevoTipoCambio":"New exchange rate",
	"manage.tipoCambio":"Exchange rates",
	"manage.nuevoColaborador":"New collaborator",
	"manage.colaborador":"Collaborators",
	"mepass.cambiarPass":"Change password",
	"mepass.passCambiado":"Password changed correctly",
	"mepass.oldPass":"Old password",
	"mepass.newPass":"New password",
	"mepass.newPass2":"Repeat new password",
	"mepass.guardar":"Save",
	"mepass.cancelar":"Cancel",
	"mepass.oldPassFail":"Old password incorrect",
	"mepass.oldPassValidate":"The new password must be at least 8 characters long, one lower case capital letter and one digit",
	"mepass.passNotEqual":"Passwords do not match",
	"mepass.todosCamposObligatorios":"All fields are required",
	"achievements.achievement":"Output",
	"achievements.adjuntarArchivo":"Upload file",
	"achievements.areSure":"Are you sure you want to validate the output?",
	"achievements.beneficiariosDirectos":"Direct beneficiaries",
	"achievements.beneficiariosIndirectos":"Indirect beneficiaries",
	"achievements.cancel":"Cancel",
	"achievements.codigo":"Code",
	"achievements.delete":"Delete",
	"achievements.deleteMessage":"Delete message",
	"achievements.descripcion":"Description",
	"achievements.devalidate":"Remove validation",
	"achievements.duplicar":"Duplicate",
	"achievements.editar":"Edit",
	"achievements.fecha":"Date",
	"achievements.logosAmbientales":"Environmental outputs",
	"achievements.organizacionesBeneficiarias":"Beneficiary organisations",
	"achievements.validate":"Validate",
	"achievements.validateAchievement":"Validate output",
	"achievements.ver":"See",
	"achievementsform.numeroBeneficiarias":"Number of beneficiary organizations",
	"achievementsform.addGrInteres":"Save",
	"achievementsform.LogrosEntidadesParticipantes":"Participating entities: Outputs",
	"achievementsform.addAlianza":"Save",
	"achievementsform.addCumplimiento":"Save",
	"achievementsform.addMedioComunicacion":"Save",
	"achievementsform.addNuevaOrganizacion":"Save",
	"achievementsform.alianzasComentarios":"Comments",
	"achievementsform.alianzasEntidad":"Entity",
	"achievementsform.alianzasGenerando":"Generated alliances",
	"achievementsform.alianzasNum":"Nº",
	"achievementsform.alineacion":"Alignment with the mission",
	"achievementsform.benefiariosDirectos":"Direct beneficiaries",
	"achievementsform.beneficiariaPregunta":"Beneficiary?",
	"achievementsform.beneficiariosIndirectos":"Indirect beneficiaries",
	"achievementsform.conclusionesAlcanzadas":"Conclusions",
	"achievementsform.cumplimiento":"Fulfilment of initiative goals",
	"achievementsform.cumplimientoAlineacion":"Fulfilment",
	"achievementsform.cumplimientoEntidad":"Entity",
	"achievementsform.cumplimientoObservaciones":"Comments",
	"achievementsform.deleteAlianza":"Delete alliance",
	"achievementsform.deleteCumplimiento":"Delete fulfilment of goals",
	"achievementsform.deleteMediosComunicacion":"Delete media",
	"achievementsform.deleteOrganizacion":"Delete organisation",
	"achievementsform.descripcion":"Description",
	"achievementsform.descripcionSimple":"Description",
	"achievementsform.edit":"Edit",
	"achievementsform.eficacia":"Effectiveness",
	"achievementsform.eficiencia":"Efficiency",
	"achievementsform.entidadGrupoIntereses":"Entity",
	"achievementsform.fechaevaluacion":"Evaluation date",
	"achievementsform.gruposInteresAlcanzados":"Stakeholders reached",
	"achievementsform.logrosGrupoIntereses":"Outputs with stakeholders",
	"achievementsform.logrosInteresNumAbrev":"Nº",
	"achievementsform.logrosInteresObservaciones":"Comments",
	"achievementsform.logrosMedioambientables":"Outputs",
	"achievementsform.mediosComunicacion":"Media",
	"achievementsform.mediosComunicacionDivisa":"Currency",
	"achievementsform.mediosComunicacionEntidad":"Entity",
	"achievementsform.mediosComunicacionObservaciones":"Comments",
	"achievementsform.mediosComunicacionValor":"Media Value",
	"achievementsform.new":"New",
	"achievementsform.nombreOrganizacion":"Organisation's name",
	"achievementsform.numBeneficiarios":"Nº of beneficiaries",
	"achievementsform.numLogrosMedioambientales":"Nº of environmental outputs",
	"achievementsform.observaciones":"Description",
	"achievementsform.organizacionesBeneficiarias":"Beneficiary organisations",
	"achievementsform.situacion":"Situation",
	"achievementsform.sostenibilidad":"Economic sustainability",
	"achievementsform.tipo":"Age/Gender",
	"achievementsform.tipoSimple":"Type",
	"achievementsform.ver":"See",
	"achievementsform.guardar":"Save",
	"achievementsform.cancelar":"Cancel",
	"achievementsform.Si":"Yes",
	"achievementsform.No":"No",
	"area.nombre.arte":"Art",
	"area.nombre.autonomia":"Autonomy",
	"area.nombre.ayuda":"Aid",
	"area.nombre.ciudadania":"Citizenship",
	"area.nombre.desarrollo":"Development",
	"area.nombre.diversidad":"Diversity",
	"area.nombre.educacion":"Education",
	"area.nombre.empleo":"Employment",
	"area.nombre.institucional":"Institutional",
	"area.nombre.medio.ambiente":"Environment",
	"area.nombre.otros":"Other",
	"area.nombre.salud":"Health",
	"area.nombre.social":"Social",
	"breadcrumb.iniciativas":"INITIATIVES",
	"breadcrumb.proyectos":"PROJECTS",
	"confirmdelete.confirmarBorrado":"Confirm deletion",
	"confirmdelete.estasSeguro":"Are you sure?",
	"contributions.desvalidarTodas":"Invalidate all",
	"contributions.desvalidarTodos":"Invalidate all",
	"contributions.validarTodas":"Validate all",
	"contributions.validarTodos":"Validate all",
	"documento.size.excedido":"File size must be under 40MB",
	"error.validation":"Validation",
	"exchangerate.desdeCabecera":"The period is from",
	"exchangerate.hastaCabecera":"To",
	"grupo.interes.nombre.clientes":"Clients",
	"grupo.interes.nombre.donantes":"Sponsors",
	"grupo.interes.nombre.otros":"Other",
	"grupo.interes.nombre.proveedores":"Suppliers",
	"impact.ver":"See",
	"impactSelect.sinSeleccion":"Without selection",
	"impactsform.numEvaluados":"Evaluated",
	"impactsform.ver":"See",
	"initiative.ver":"See",
	"initiativeform.verIniciativa":"View initiative",
	"list.cargando":"Loading",
	"list.empty":"Empty",
	"login.entidad.desactivado":"Deactivated",
	"login.usuario.desactivado":"Deactivated",
	"me.apellidos":"Surname",
	"me.cambiarPass":"Change password",
	"me.dataChanged":"Data changed",
	"me.email":"Email",
	"me.guardar":"Save",
	"me.miPerfil":"My profile",
	"me.nombre":"Name",
	"me.pais":"Country",
	"measurement.contribuciones":"Contributions",
	"measurement.contribucionesMin":"Contributions",
	"measurement.impactos":"Impacts",
	"measurement.impactosMin":"Impacts",
	"measurement.logros":"Outputs",
	"measurement.logrosMin":"Outputs",
	"measurement.nuevoImpacto":"New impact",
	"measurement.nuevoLogro":"New output",
	"motivacion.nombre.alineada":"Commercial initiatives in the community",
	"motivacion.nombre.apoyo":"Support initiative",
	"motivacion.nombre.compartida":"Shared initiative",
	"motivacion.nombre.inversion":"Community investment",
	"motivacion.nombre.propia":"Own initiative",
	"motivacion.nombre.puntual":"Charitable gift",
	"plan.activar":"Activate",
	"plan.borrar":"Delete",
	"plan.borrarPlan":"Delete plan",
	"plan.cancelar":"Cancel",
	"plan.delete":"Delete",
	"plan.desactivar":"Deactivate",
	"plan.edit":"Edit",
	"plan.estado":"Status",
	"plan.estasSeguro":"Are you sure?",
	"plan.nombre":"Name",
	"plan.numUsuarios":"Nº of users",
	"plan.plan":"Plan",
	"planform.activo":"Active",
	"planform.cancelar":"Cancel",
	"planform.edit":"Edit",
	"planform.grupoLbg":"LBG Group",
	"planform.guardar":"Save",
	"planform.new":"New",
	"planform.nombre":"Name",
	"planform.numAgentes":"Nº of agents",
	"planform.numBasicos":"Nº of basics",
	"planform.numGerentes":"Nº of managers",
	"planform.numResponsable":"Nº of top managers",
	"planform.numSupIni":"Nº of initiative supervisors",
	"planform.numSupPro":"Nº of project Supervisors",
	"planform.numUsuarios":"Nº of users",
	"planform.volver":"Go back",
	"proyect.abiertos":"Ongoing",
	"proyect.abrir":"Open",
	"proyect.adjuntarArchivo":"Attach file",
	"proyect.agrupacion":"Internal classification",
	"proyect.asignarUsuarios":"Assign users",
	"proyect.borrar":"Delete",
	"proyect.borrarProyecto":"Delete project",
	"proyect.cancelar":"Cancel",
	"proyect.cerrados":"Closed",
	"proyect.cerrar":"Close",
	"proyect.codigo":"Code",
	"proyect.contribucion":"Contribution",
	"proyect.contribucionTotal":"Total contribution",
	"proyect.divisionUnidad":"Unit",
	"proyect.divisionUnidadPH":"Unit",
	"proyect.duplicar":"Duplicate",
	"proyect.editar":"Edit",
	"proyect.estasSeguro":"Are you sure?",
	"proyect.fechaInicio":"Start date",
	"proyect.fechaInicioPH":"Start date",
	"proyect.iniciativas":"Initiatives",
	"proyect.ninguno":"None",
	"proyect.nombre":"Name",
	"proyect.nuevoProyecto":"New project",
	"proyect.proyecto":"Draft",
	"proyect.proyectoCerradoNoArchivos":"You cannot attach files to a closed project",
	"proyect.proyectoCerradoNoReabre":"You cannot edit a closed project",
	"proyect.proyectos":"PROJECTS",
	"proyect.todos":"All",
	"proyect.total":"Total",
	"proyect.ver":"See",
	"proyect.abrirProyecto":"Open draft",
	"proyect.cerrarProyecto":"Close draft",
	"proyectassign.activar":"Activate",
	"proyectassign.apellidos":"Surname",
	"proyectassign.cancelar":"Cancel",
	"proyectassign.codigo":"Code",
	"proyectassign.desactivar":"Deactivate",
	"proyectassign.desmarcarTodos":"Deselect all",
	"proyectassign.email":"Email",
	"proyectassign.entidadRepresentada":"Entity represented",
	"proyectassign.estasSeguro":"Are you sure?",
	"proyectassign.finalizarAsignacion":"End assignment",
	"proyectassign.marcarTodos":"Select all",
	"proyectassign.nombre":"Name",
	"proyectassign.pais":"Country",
	"proyectassign.rol":"Role",
	"proyectassign.seleccionar":"Select",
	"proyectassign.usuario":"User",
	"proyectclosed.alert":"Alert",
	"proyectform.agrupacionInterna":"Internal classification",
	"proyectform.asignarUsuarios":"Assign users",
	"proyectform.cancelar":"Cancel",
	"proyectform.descripcion":"Description",
	"proyectform.desde":"Start date",
	"proyectform.divisa":"Currency",
	"proyectform.divisionUnidad":"Unit",
	"proyectform.edit":"Edit",
	"proyectform.guardar":"Save",
	"proyectform.hasta":"End date",
	"proyectform.mensajeError":"Message error",
	"proyectform.new":"NEW",
	"proyectform.nombre":"Name",
	"proyectform.presupuesto":"Budget",
	"proyectform.presupuestoTotal":"Total budget",
	"proyectform.ver":"See",
	"proyectform.volver":"Go back",
	"proyectresume.contribucion":"CONTRIBUTION",
	"proyectresume.contribucionTotal":"TOTAL CONTRIBUTION",
	"proyectresume.costesGestion":"Management costs",
	"proyectresume.cumplimiento":"Fulfilment",
	"proyectresume.currentYear":"Current year",
	"proyectresume.dinero":"Cash",
	"proyectresume.efectoMultiplicador":"Leverage",
	"proyectresume.especie":"In kind",
	"proyectresume.numIniciativas":"Nº of initiatives",
	"proyectresume.otros":"OTHERS",
	"proyectresume.otrosTotal":"Other total",
	"proyectresume.pendiente":"Pending",
	"proyectresume.presupuesto":"Budget",
	"proyectresume.previousYear":"Previous year",
	"proyectresume.probono":"Pro bono",
	"proyectresume.resumen":"Summary",
	"proyectresume.tiempo":"Time",
	"proyectresume.total":"Total",
	"proyectresume.totalMayus":"TOTAL",
	"proyectresume.volver":"Go back",
	"rating.agrupacionInterna":"Internal classification",
	"rating.nombre":"Name",
	"rating.nuevaAgrupacion":"New classification",
	"rating.nuevaDivision":"New unit",
	"rating.unidadAdministrativa":"Department/Unit",
	"razon.nombre.dirigidas.deporte":"Related to sports",
	"razon.nombre.dirigidas.empleados":"Related to employees",
	"razon.nombre.obligacion.contractual":"Contractual obligation",
	"razon.nombre.obligacion.legal":"Legal obligation",
	"razon.nombrer.otros":"Other",
	"salarytables.adjuntarArchivo":"Attach file",
	"salarytables.aplicationYear":"Years",
	"salarytables.borrar":"Delete",
	"salarytables.borrarTablaSalarial":"Delete Contribution table",
	"salarytables.cancelar":"Cancel",
	"salarytables.codigo":"Code",
	"salarytables.costeHora":"Cost / hour",
	"salarytables.descripcionInterna":"Internal description",
	"salarytables.divisa":"Currency",
	"salarytables.editar":"Edit",
	"salarytables.estasSeguro":"Are you sure?",
	"salarytables.horas":"Hours",
	"salarytables.nombre":"Name",
	"salarytables.personas":"People",
	"salarytables.totalCoste":"Total cost",
	"salarytablesform.applicationYear":"Application year",
	"salarytablesform.campoRequerido":"Required field",
	"salarytablesform.cancelar":"Cancel",
	"salarytablesform.costeHora":"Hour cost",
	"salarytablesform.costeTotal":"Total cost",
	"salarytablesform.descripcionInterna":"Internal description",
	"salarytablesform.divisa":"Currency",
	"salarytablesform.edit":"Edit table of contributions",
	"salarytablesform.guardar":"Save table of contributions",
	"salarytablesform.horas":"Hours",
	"salarytablesform.new":"New",
	"salarytablesform.nombre":"Name",
	"salarytablesform.numPersonas":"Nº of people",
	"salarytablesform.volver":"Go back",
	"sectorlbg.nombre.construction":"Construction",
	"sectorlbg.nombre.consumer":"Consumer",
	"sectorlbg.nombre.engieering":"Engineering",
	"sectorlbg.nombre.financial":"Financial",
	"sectorlbg.nombre.food":"Food",
	"sectorlbg.nombre.legal":"Legal",
	"sectorlbg.nombre.medias":"Media",
	"sectorlbg.nombre.natural":"Natural",
	"sectorlbg.nombre.pharmaceiutical":"Pharmaceutical",
	"sectorlbg.nombre.professional":"Professional",
	"sectorlbg.nombre.retail":"Retail",
	"sectorlbg.nombre.technology":"Technology",
	"sectorlbg.nombre.travel":"Travel",
	"sectorlbg.nombre.utilites":"Utilities",
	"situacion.nombre.abusos":"Abuse",
	"situacion.nombre.analfabetos":"Illiterate",
	"situacion.nombre.beneficiario":"Beneficiary",
	"situacion.nombre.desastre.natural":"Natural disaster",
	"situacion.nombre.desempleo":"Unemployment",
	"situacion.nombre.desempleo.larga":"Long term unemployment",
	"situacion.nombre.dificultad.aprendizaje":"Learning difficulties",
	"situacion.nombre.ingresos.bajos":"Low income",
	"situacion.nombre.inmigrantes":"Migrants",
	"situacion.nombre.minorias":"Minorities",
	"situacion.nombre.otros":"Other",
	"situacion.nombre.pais.desarrollo":"Developing country",
	"situacion.nombre.presidiario":"Inmate",
	"situacion.nombre.refugiados":"Refugees",
	"situacion.nombre.sin.hogar":"Homeless",
	"situacion.nombre.vulnerabilidad":"Vulnerable",
	"tipo.cambio.requerido.excel.valores":"Values",
	"tipo.cambio.valor.excel.caracter.invalido":"Invalid character",
	"tipo.empresa.empresa":"Business",
	"tipo.empresa.fundacion":"Foundation",
	"tipo.empresa.ong":"NGO",
	"tipo.empresa.onl":"NGO",
	"tipo.empresa.otras":"Other",
	"tipo.logro.hombre.mayor.18":"18",
	"tipo.logro.hombre.mayor.45":"45",
	"tipo.logro.hombre.mayor.65":"65",
	"tipo.logro.hombre.mujer.mayor.18":"Over",
	"tipo.logro.hombres.todos":"All",
	"tipo.logro.joven.chica":"Female",
	"tipo.logro.joven.chico":"Male",
	"tipo.logro.jovenes.todos":"All",
	"tipo.logro.mujer.hombre.mayor.45":"Over",
	"tipo.logro.mujer.hombre.mayor.65":"Over",
	"tipo.logro.mujer.mayor.45":"45",
	"tipo.logro.mujer.mayor.65":"65",
	"tipo.logro.mujere.mayor.18":"18",
	"tipo.logro.mujeres.hombres.todos":"All",
	"tipo.logro.mujeres.todas":"All",
	"tipo.logro.niña":"Girl",
	"tipo.logro.niño":"Boy",
	"tipo.logro.niño.niñas.todos":"All",
	"toolbartop.benchmarking":"Benchmarking",
	"toolbartop.cerrarSesion":"Sign out",
	"toolbartop.contacto":"Contact",
	"toolbartop.gestion":"Management",
	"toolbartop.home":"Home",
	"toolbartop.idioma":"Language",
	"toolbartop.moneda":"Currency",
	"toolbartop.perfil":"Profile",
	"toolbartop.proyectos":"Projects",
	"uploadform.archivosSubidos":"Uploaded files",
	"uploadform.borrar":"Delete",
	"uploadform.borrarArchivo":"Delete file",
	"uploadform.cancelar":"Cancel",
	"uploadform.estasSeguro":"Are you sure?",
	"uploadform.subirArchivo":"Upload file",
	"uploadform.volver":"Go back",
	"user.activar":"Activate",
	"user.agentes":"Agents",
	"user.apellidos":"Surname",
	"user.borrar":"Delete",
	"user.borrarUsuario":"Delete user",
	"user.cancelar":"Cancel",
	"user.codigo":"Code",
	"user.desactivar":"Deactivate",
	"user.disponiblesGerentes":"Available managers",
	"user.editar":"Edit",
	"user.email":"Email",
	"user.entidadRepresentada":"Represented entity",
	"user.entidadResponsable":"Responsible entity",
	"user.estado":"Access",
	"user.estasSeguro":"Are you sure?",
	"user.nombre":"Name",
	"user.pais":"Country",
	"user.rol":"Role",
	"user.supIni":"Initiative supervisor",
	"user.supervisoresProyecto":"Project supervisor",
	"user.usuario":"User",
	"userform.activo":"Active",
	"userform.apellidos":"Surname",
	"userform.cancelar":"Cancel",
	"userform.editarUsuario":"Edit user",
	"userform.email":"Email",
	"userform.emailSolicitudInfo":"Email info",
	"userform.entidadRepresentada":"Represented entity",
	"userform.entidadResponsableAlta":"Responsible entity",
	"userform.grupoLbgCoordinador":"LBG group Coordinator",
	"userform.guardar":"Save",
	"userform.nombre":"Name",
	"userform.nuevoUsuario":"New user",
	"userform.pais":"Country",
	"userform.rol":"Role",
	"userform.volver":"Go back",
	"area.nombre.educacion":"Education",
	"area.nombre.salud":"Health",
	"area.nombre.desarrollo":"Socio‐economic development",
	"area.nombre.medio.ambiente":"Environment and improvement of the habitat",
	"area.nombre.arte":"Arts & culture",
	"area.nombre.social":"Social welfare",
	"area.nombre.ayuda":"Emergency relief",
	"area.nombre.autonomia":"Personal autonomy and independence for people with difficulties",
	"area.nombre.empleo":"Employment and enterprise",
	"area.nombre.institucional":"Institutional strengthening",
	"area.nombre.ciudadania":"Strengthening of active citizenship",
	"area.nombre.diversidad":"Diversity and strengthening of the family",
	"area.nombre.otros":"Other",
	"grupo.interes.nombre.clientes":"Customers / consumers",
	"grupo.interes.nombre.proveedores":"Suppliers / distributors",
	"grupo.interes.nombre.donantes":"Donors / sponsors",
	"grupo.interes.nombre.otros":"Other stakeholders",
	"meta.ods.1.nombre.1":"1.1 By 2030, eradicate extreme poverty for all people everywhere, currently measured as people living on less than $1.25 a day",
	"meta.ods.1.nombre.2":"1.2 By 2030, reduce at least by half the proportion of men, women and children of all ages living in poverty in all its dimensions according to national definitions",
	"meta.ods.1.nombre.4":"1.4 By 2030, ensure that all men and women, in particular the poor and the vulnerable, have equal rights to economic resources, as well as access to basic services, ownership and control over land and other forms of property, inheritance, natural resources, appropriate new technology and financial services, including microfinance",
	"meta.ods.1.nombre.5":"1.5 By 2030, build the resilience of the poor and those in vulnerable situations and reduce their exposure and vulnerability to climate-related extreme events and other economic, social and environmental shocks and disasters",
	"meta.ods.1.nombre.3":"1.3 Implement nationally appropriate social protection systems and measures for all, including floors, and by 2030 achieve substantial coverage of the poor and the vulnerable",
	"meta.ods.1.nombre.a":"1.A Ensure significant mobilization of resources from a variety of sources, including through enhanced development cooperation, in order to provide adequate and predictable means for developing countries, in particular least developed countries, to implement programmes and policies to end poverty in all its dimensions",
	"meta.ods.1.nombre.b":"1.B Create sound policy frameworks at the national, regional and international levels, based on pro-poor and gender-sensitive development strategies, to support accelerated investment in poverty eradication actions",
	"meta.ods.2.nombre.1":"2.1 By 2030, end hunger and ensure access by all people, in particular the poor and people in vulnerable situations, including infants, to safe, nutritious and sufficient food all year round.",
	"meta.ods.2.nombre.2":"2.2 By 2030, end all forms of malnutrition, including achieving, by 2025, the internationally agreed targets on stunting and wasting in children under 5 years of age, and address the nutritional needs of adolescent girls, pregnant and lactating women and older persons.",
	"meta.ods.2.nombre.3":"2.3 By 2030, double the agricultural productivity and incomes of small-scale food producers, in particular women, indigenous peoples, family farmers, pastoralists and fishers, including through secure and equal access to land, other productive resources and inputs, knowledge, financial services, markets and opportunities for value addition and non-farm employment.",
	"meta.ods.2.nombre.4":"2.4 By 2030, ensure sustainable food production systems and implement resilient agricultural practices that increase productivity and production, that help maintain ecosystems, that strengthen capacity for adaptation to climate change, extreme weather, drought, flooding and other disasters and that progressively improve land and soil quality.",
	"meta.ods.2.nombre.5":"2.5 By 2020, maintain the genetic diversity of seeds, cultivated plants and farmed and domesticated animals and their related wild species, including through soundly managed and diversified seed and plant banks at the national, regional and international levels, and promote access to and fair and equitable sharing of benefits arising from the utilization of genetic resources and associated traditional knowledge, as internationally agreed.",
	"meta.ods.2.nombre.a":"2.A Increase investment, including through enhanced international cooperation, in rural infrastructure, agricultural research and extension services, technology development and plant and livestock gene banks in order to enhance agricultural productive capacity in developing countries, in particular least developed countries.",
	"meta.ods.2.nombre.c":"2.C Adopt measures to ensure the proper functioning of food commodity markets and their derivatives and facilitate timely access to market information, including on food reserves, in order to help limit extreme food price volatility.",
	"meta.ods.2.nombre.b":"2.B Correct and prevent trade restrictions and distortions in world agricultural markets, including through the parallel elimination of all forms of agricultural export subsidies and all export measures with equivalent effect, in accordance with the mandate of the Doha Development Round.",
	"meta.ods.4.nombre.4":"4.4 By 2030, substantially increase the number of youth and adults who have relevant skills, including technical and vocational skills, for employment, decent jobs and entrepreneurship",
	"meta.ods.4.nombre.5":"4.5 By 2030, eliminate gender disparities in education and ensure equal access to all levels of education and vocational training for the vulnerable, including persons with disabilities, indigenous peoples and children in vulnerable situations",
	"meta.ods.4.nombre.7":"4.7 By 2030, ensure that all learners acquire the knowledge and skills needed to promote sustainable development, including, among others, through education for sustainable development and sustainable lifestyles, human rights, gender equality, promotion of a culture of peace and non-violence, global citizenship and appreciation of cultural diversity and of culture’s contribution to sustainable development",
	"meta.ods.4.nombre.a":"4.A Build and upgrade education facilities that are child, disability and gender sensitive and provide safe, nonviolent, inclusive and effective learning environments for all",
	"meta.ods.4.nombre.b":"4.B By 2020, substantially expand globally the number of scholarships available to developing countries, in particular least developed countries, small island developing States and African countries, for enrolment in higher education, including vocational training and information and communications technology, technical, engineering and scientific programmes, in developed countries and other developing countries",
	"meta.ods.4.nombre.c":"4.C By 2030, substantially increase the supply of qualified teachers, including through international cooperation for teacher training in developing countries, especially least developed countries and small island developing states",
	"meta.ods.4.nombre.1":"4.1 By 2030, ensure that all girls and boys complete free, equitable and quality primary and secondary education leading to relevant and Goal-4 effective learning outcomes",
	"meta.ods.4.nombre.2":"4.2 By 2030, ensure that all girls and boys have access to quality early childhood development, care and preprimary education so that they are ready for primary education",
	"meta.ods.4.nombre.6":"4.6 By 2030, ensure that all youth and a substantial proportion of adults, both men and women, achieve literacy and numeracy",
	"meta.ods.4.nombre.3":"4.3 By 2030, ensure equal access for all women and men to affordable and quality technical, vocational and tertiary education, including university",
	"meta.ods.3.nombre.1":"3.1 By 2030, reduce the global maternal mortality ratio to less than 70 per 100,000 live births.",
	"meta.ods.3.nombre.2":"3.2 By 2030, end preventable deaths of newborns and children under 5 years of age, with all countries aiming to reduce neonatal mortality to at least as low as 12 per 1,000 live births and under-5 mortality to at least as low as 25 per 1,000 live births.",
	"meta.ods.3.nombre.3":"3.3 By 2030, end the epidemics of AIDS, tuberculosis, malaria and neglected tropical diseases and combat hepatitis, water-borne diseases and other communicable diseases.",
	"meta.ods.3.nombre.4":"3.4 By 2030, reduce by one third premature mortality from non-communicable diseases through prevention and treatment and promote mental health and well-being.",
	"meta.ods.3.nombre.5":"3.5 Strengthen the prevention and treatment of substance abuse, including narcotic drug abuse and harmful use of alcohol.",
	"meta.ods.3.nombre.6":"3.6 By 2020, halve the number of global deaths and injuries from road traffic accidents.",
	"meta.ods.3.nombre.7":"3.7 By 2030, ensure universal access to sexual and reproductive health-care services, including for family planning, information and education, and the integration of reproductive health into national strategies and programmes.",
	"meta.ods.3.nombre.8":"3.8 Achieve universal health coverage, including financial risk protection, access to quality essential health-care services and access to safe, effective, quality and affordable essential medicines and vaccines for all.",
	"meta.ods.3.nombre.b":"3.B Support the research and development of vaccines and medicines for the communicable and noncommunicable diseases that primarily affect developing countries, provide access to affordable essential medicines and vaccines, in accordance with the Doha Declaration on the TRIPS Agreement and Public Health, which affirms the right of developing countries to use to the full the provisions in the Agreement on Trade Related Aspects of Intellectual Property Rights regarding flexibilities to protect public health, and, in particular, provide access to medicines for all.",
	"meta.ods.3.nombre.9":"3.9 By 2030, substantially reduce the number of deaths and illnesses from hazardous chemicals and air, water and soil pollution and contamination.",
	"meta.ods.3.nombre.a":"3.A Strengthen the implementation of the World Health Organization Framework Convention on Tobacco Control in all countries, as appropriate.",
	"meta.ods.3.nombre.c":"3.C Substantially increase health financing and the recruitment, development, training and retention of the health workforce in developing countries, especially in least developed countries and small island developing States.",
	"meta.ods.3.nombre.d":"3.D Strengthen the capacity of all countries, in particular developing countries, for early warning, risk reduction and management of national and global health risks.",
	"meta.ods.5.nombre.1":"5.1 End all forms of discrimination against all women and girls everywhere",
	"meta.ods.5.nombre.2":"5.2 Eliminate all forms of violence against all women and girls in the public and private spheres, including trafficking and sexual and other types of exploitation",
	"meta.ods.5.nombre.4":"5.4 Recognize and value unpaid care and domestic work through the provision of public services, infrastructure and social protection policies and the promotion of shared responsibility within the household and the family as nationally appropriate",
	"meta.ods.5.nombre.5":"5.5 Ensure women’s full and effective participation and equal opportunities for leadership at all levels of decision making in political, economic and public life",
	"meta.ods.5.nombre.b":"5.B Enhance the use of enabling technology, in particular information and communications technology, to promote the empowerment of women",
	"meta.ods.5.nombre.3":"5.3 Eliminate all harmful practices, such as child, early and forced marriage and female genital mutilation",
	"meta.ods.5.nombre.6":"5.6 Ensure universal access to sexual and reproductive health and reproductive rights as agreed in accordance with the Programme of Action of the International Conference on Population and Development and the Beijing Platform for Action and the outcome documents of their review conferences",
	"meta.ods.5.nombre.a":"5.A Undertake reforms to give women equal rights to economic resources, as well as access to ownership and control over land and other forms of property, financial services, inheritance and natural resources, in accordance with national laws",
	"meta.ods.5.nombre.c":"5.C Adopt and strengthen sound policies and enforceable legislation for the promotion of gender equality and the empowerment of all women and girls at all levels",
	"meta.ods.6.nombre.1":"6.1 By 2030, achieve universal and equitable access to safe and affordable drinking water for all",
	"meta.ods.6.nombre.2":"6.2 By 2030, achieve access to adequate and equitable sanitation and hygiene for all and end open defecation, paying special attention to the needs of women and girls and those in vulnerable situations",
	"meta.ods.6.nombre.3":"6.3 By 2030, improve water quality by reducing pollution, eliminating dumping and minimizing release of hazardous chemicals and materials, halving the proportion of untreated wastewater and substantially increasing recycling and safe reuse globally",
	"meta.ods.6.nombre.4":"6.4 By 2030, substantially increase water-use efficiency across all sectors and ensure sustainable withdrawals and supply of freshwater to address water scarcity and substantially reduce the number of people suffering from water scarcity",
	"meta.ods.6.nombre.6":"6.6 By 2020, protect and restore water-related ecosystems, including mountains, forests, wetlands, rivers, aquifers and lakes",
	"meta.ods.6.nombre.b":"6.B Support and strengthen the participation of local communities in improving water and sanitation management",
	"meta.ods.6.nombre.5":"6.5 By 2030, implement integrated water resources management at all levels, including through transboundary cooperation as appropriate",
	"meta.ods.6.nombre.a":"6.A By 2030, expand international cooperation and capacity-building support to developing countries in water- and sanitation-related activities and programmes, including water harvesting, desalination, water efficiency, wastewater treatment, recycling and reuse technologies",
	"meta.ods.7.nombre.1":"7.1 By 2030, ensure universal access to affordable, reliable and modern energy services",
	"meta.ods.7.nombre.2":"7.2 By 2030, increase substantially the share of renewable energy in the global energy mix",
	"meta.ods.7.nombre.3":"7.3 By 2030, double the global rate of improvement in energy efficiency",
	"meta.ods.7.nombre.b":"5.5 Ensure women’s full and effective participation and equal opportunities for leadership at all levels of decision making in political, economic and public life",
	"meta.ods.7.nombre.a":"7.A By 2030, enhance international cooperation to facilitate access to clean energy research and technology, including renewable energy, energy efficiency and advanced and cleaner fossil-fuel technology, and promote investment in energy infrastructure and clean energy technology",
	"meta.ods.8.nombre.3":"8.3 Promote development-oriented policies that support productive activities, decent job creation, entrepreneurship, creativity and innovation, and encourage the formalization and growth of micro-, small- and medium-sized enterprises, including through access to financial services",
	"meta.ods.8.nombre.5":"8.5 By 2030, achieve full and productive employment and decent work for all women and men, including for young people and persons with disabilities, and equal pay for work of equal value",
	"meta.ods.8.nombre.6":"8.6 By 2020, substantially reduce the proportion of youth not in employment, education or training",
	"meta.ods.8.nombre.7":"8.7 Take immediate and effective measures to eradicate forced labour, end modern slavery and human trafficking and secure the prohibition and elimination of the worst forms of child labour, including recruitment and use of child soldiers, and by 2025 end child labour in all its forms",
	"meta.ods.8.nombre.8":"8.8 Protect labour rights and promote safe and secure working environments for all workers, including migrant workers, in particular women migrants, and those in precarious employment",
	"meta.ods.8.nombre.9":"8.9 By 2030, devise and implement policies to promote sustainable tourism that creates jobs and promotes local culture and products",
	"meta.ods.8.nombre.1":"8.1 Sustain per capita economic growth in accordance with national circumstances and, in particular, at least 7 per cent gross domestic product growth per annum in the least developed countries",
	"meta.ods.8.nombre.2":"8.2 Achieve higher levels of economic productivity through diversification, technological upgrading and innovation, including through a focus on high-value added and labour-intensive sectors",
	"meta.ods.8.nombre.4":"8.4 Improve progressively, through 2030, global resource efficiency in consumption and production and endeavour to decouple economic growth from environmental degradation, in accordance with the 10-year framework of programmes on sustainable consumption and production, with developed countries taking the lead",
	"meta.ods.8.nombre.10":"8.10 Strengthen the capacity of domestic financial institutions to encourage and expand access to banking, insurance and financial services for all",
	"meta.ods.8.nombre.a":"8.A Increase Aid for Trade support for developing countries, in particular least developed countries, including through the Enhanced Integrated Framework for Trade-Related Technical Assistance to Least Developed Countries",
	"meta.ods.8.nombre.b":"8.B By 2020, develop and operationalize a global strategy for youth employment and implement the Global Jobs Pact of the International Labour Organization",
	"meta.ods.9.nombre.1":"9.1 Develop quality, reliable, sustainable and resilient infrastructure, including regional and transborder infrastructure, to support economic development and human well-being, with a focus on affordable and equitable access for all",
	"meta.ods.9.nombre.3":"9.3 Increase the access of small-scale industrial and other enterprises, in particular in developing countries, to financial services, including affordable credit, and their integration into value chains and markets",
	"meta.ods.9.nombre.4":"9.4 By 2030, upgrade infrastructure and retrofit industries to make them sustainable, with increased resource-use efficiency and greater adoption of clean and environmentally sound technologies and industrial processes, with all countries taking action in accordance with their respective capabilities",
	"meta.ods.9.nombre.5":"9.5 Enhance scientific research, upgrade the technological capabilities of industrial sectors in all countries, in particular developing countries, including, by 2030, encouraging innovation and substantially increasing the number of research and development workers per 1 million people and public and private research and development spending",
	"meta.ods.9.nombre.a":"9.A Facilitate sustainable and resilient infrastructure development in developing countries through enhanced financial, technological and technical support to African countries, least developed countries, landlocked developing countries and small island developing States",
	"meta.ods.9.nombre.c":"9.C Significantly increase access to information and communications technology and strive to provide universal and affordable access to the Internet in least developed countries by 2020",
	"meta.ods.9.nombre.2":"9.2 Promote inclusive and sustainable industrialization and, by 2030, significantly raise industry’s share of employment and gross domestic product, in line with national circumstances, and double its share in least developed countries",
	"meta.ods.9.nombre.b":"9.B Support domestic technology development, research and innovation in developing countries, including by ensuring a conducive policy environment for, inter alia, industrial diversification and value addition to commodities",
	"meta.ods.10.nombre.2":"10.2 By 2030, empower and promote the social, economic and political inclusion of all, irrespective of age, sex, disability, race, ethnicity, origin, religion or economic or other status",
	"meta.ods.10.nombre.3":"10.3 Ensure equal opportunity and reduce inequalities of outcome and promoting appropriate legislation, policies and action in this regard",
	"meta.ods.10.nombre.1":"10.1 By 2030, progressively achieve and sustain income growth of the bottom 40 per cent of the population at a rate higher than the national average",
	"meta.ods.10.nombre.4":"10.4 Adopt policies, especially fiscal, wage and social protection policies, and progressively achieve greater equality",
	"meta.ods.10.nombre.5":"10.5 Improve the regulation and monitoring of global financial markets and institutions and strengthen the implementation of such regulations",
	"meta.ods.10.nombre.6":"10.6 Ensure enhanced representation and voice for developing countries in decision-making in global international economic and financial institutions in order to deliver more effective, credible, accountable and legitimate institutions",
	"meta.ods.10.nombre.7":"10.7 Facilitate orderly, safe, regular and responsible migration and mobility of people, including through the implementation of planned and well-managed migration policies",
	"meta.ods.10.nombre.a":"10.A Implement the principle of special and differential treatment for developing countries, in particular least developed countries, in accordance with World Trade Organization agreements",
	"meta.ods.10.nombre.b":"10.B Encourage official development assistance and financial flows, including foreign direct investment, to States where the need is greatest, in particular least developed countries, African countries, small island developing States and landlocked developing countries, in accordance with their national plans and programmes",
	"meta.ods.10.nombre.c":"10.C By 2030, reduce to less than 3 per cent the transaction costs of migrant remittances and eliminate remittance corridors with costs higher than 5 per cent",
	"meta.ods.11.nombre.1":"11.1 By 2030, ensure access for all to adequate, safe and affordable housing and basic services and upgrade slums",
	"meta.ods.11.nombre.2":"11.2 By 2030, provide access to safe, affordable, accessible and sustainable transport systems for all, improving road safety, notably by expanding public transport, with special attention to the needs of those in vulnerable situations, women, children, persons with disabilities and older persons",
	"meta.ods.11.nombre.3":"11.3 By 2030, enhance inclusive and sustainable urbanization and capacity for participatory, integrated and sustainable human settlement planning and management in all countries",
	"meta.ods.11.nombre.4":"11.4 Strengthen efforts to protect and safeguard the world’s cultural and natural heritage",
	"meta.ods.11.nombre.5":"11.5 By 2030, significantly reduce the number of deaths and the number of people affected and substantially decrease the direct economic losses relative to global gross domestic product caused by disasters, including water-related disasters, with a focus on protecting the poor and people in vulnerable situations",
	"meta.ods.11.nombre.6":"11.6 By 2030, reduce the adverse per capita environmental impact of cities, including by paying special attention to air quality and municipal and other waste management",
	"meta.ods.11.nombre.a":"11.A Support positive economic, social and environmental links between urban, peri-urban and rural areas by strengthening national and regional development planning",
	"meta.ods.11.nombre.b":"11.B By 2020, substantially increase the number of cities and human settlements adopting and implementing integrated policies and plans towards inclusion, resource efficiency, mitigation and adaptation to climate change, resilience to disasters, and develop and implement, in line with the Sendai Framework for Disaster Risk Reduction 2015-2030, holistic disaster risk management at all levels",
	"meta.ods.11.nombre.c":"11.C Support least developed countries, including through financial and technical assistance, in building sustainable and resilient buildings utilizing local materials",
	"meta.ods.11.nombre.7":"11.7 By 2030, provide universal access to safe, inclusive and accessible, green and public spaces, in particular for women and children, older persons and persons with disabilities",
	"meta.ods.12.nombre.2":"12.2 By 2030, achieve the sustainable management and efficient use of natural resources",
	"meta.ods.12.nombre.3":"12.3 By 2030, halve per capita global food waste at the retail and consumer levels and reduce food losses along production and supply chains, including post-harvest losses",
	"meta.ods.12.nombre.4":"12.4 By 2020, achieve the environmentally sound management of chemicals and all wastes throughout their life cycle, in accordance with agreed international frameworks, and significantly reduce their release to air, water and soil in order to minimize their adverse impacts on human health and the environment",
	"meta.ods.12.nombre.5":"12.5 By 2030, substantially reduce waste generation through prevention, reduction, recycling and reuse",
	"meta.ods.12.nombre.8":"12.8 By 2030, ensure that people everywhere have the relevant information and awareness for sustainable development and lifestyles in harmony with nature",
	"meta.ods.12.nombre.a":"12.A Support developing countries to strengthen their scientific and technological capacity to move towards more sustainable patterns of consumption and production",
	"meta.ods.12.nombre.6":"12.6 Encourage companies, especially large and transnational companies, to adopt sustainable practices and to integrate sustainability information into their reporting cycle",
	"meta.ods.12.nombre.b":"12.B Develop and implement tools to monitor sustainable development impacts for sustainable tourism that creates jobs and promotes local culture and products",
	"meta.ods.12.nombre.1":"12.1 Implement the 10-year framework of programmes on sustainable consumption and production, all countries taking action, with developed countries taking the lead, taking into account the development and capabilities of developing countries",
	"meta.ods.12.nombre.7":"12.7 Promote public procurement practices that are sustainable, in accordance with national policies and priorities",
	"meta.ods.12.nombre.c":"12.C Rationalize inefficient fossil-fuel subsidies that encourage wasteful consumption by removing market distortions, in accordance with national circumstances, including by restructuring taxation and phasing out those harmful subsidies, where they exist, to reflect their environmental impacts, taking fully into account the specific needs and conditions of developing countries and minimizing the possible adverse impacts on their development in a manner that protects the poor and the affected communities",
	"meta.ods.13.nombre.1":"13.1 Strengthen resilience and adaptive capacity to climate-related hazards and natural disasters in all countries",
	"meta.ods.13.nombre.3":"13.3 Improve education, awareness-raising and human and institutional capacity on climate change mitigation, adaptation, impact reduction and early warning",
	"meta.ods.13.nombre.b":"13.B Promote mechanisms for raising capacity for effective climate change-related planning and management in least developed countries and small island developing States, including focusing on women, youth and local and marginalized communities",
	"meta.ods.13.nombre.2":"13.2 Integrate climate change measures into national policies, strategies and planning",
	"meta.ods.13.nombre.a":"13.A Implement the commitment undertaken by developed-country parties to the United Nations Framework Convention on Climate Change to a goal of mobilizing jointly $100 billion annually by 2020 from all sources to address the needs of developing countries in the context of meaningful mitigation actions and transparency on implementation and fully operationalize the Green Climate Fund through its capitalization as soon as possible",
	"meta.ods.14.nombre.1":"14.1 By 2025, prevent and significantly reduce marine pollution of all kinds, in particular from land-based activities, including marine debris and nutrient pollution",
	"meta.ods.14.nombre.2":"14.2 By 2020, sustainably manage and protect marine and coastal ecosystems to avoid significant adverse impacts, including by strengthening their resilience, and take action for their restoration in order to achieve healthy and productive oceans",
	"meta.ods.14.nombre.a":"14.A Increase scientific knowledge, develop research capacity and transfer marine technology, taking into account the Intergovernmental Oceanographic Commission Criteria and Guidelines on the Transfer of Marine Technology, in order to improve ocean health and to enhance the contribution of marine biodiversity to the development of developing countries, in particular small island developing States and least developed countries",
	"meta.ods.14.nombre.c":"14.C Enhance the conservation and sustainable use of oceans and their resources by implementing international law as reflected in UNCLOS, which provides the legal framework for the conservation and sustainable use of oceans and their resources, as recalled in paragraph 158 of The Future We Want",
	"meta.ods.14.nombre.b":"14.B Provide access for small-scale artisanal fishers to marine resources and markets",
	"meta.ods.14.nombre.3":"14.3 Minimize and address the impacts of ocean acidification, including through enhanced scientific cooperation at all levels",
	"meta.ods.14.nombre.4":"14.4 By 2020, effectively regulate harvesting and end overfishing, illegal, unreported and unregulated fishing and destructive fishing practices and implement science-based management plans, in order to restore fish stocks in the shortest time feasible, at least to levels that can produce maximum sustainable yield as determined by their biological characteristics",
	"meta.ods.14.nombre.5":"14.5 By 2020, conserve at least 10 per cent of coastal and marine areas, consistent with national and international law and based on the best available scientific information",
	"meta.ods.14.nombre.6":"14.6 By 2020, prohibit certain forms of fisheries subsidies which contribute to overcapacity and overfishing, eliminate subsidies that contribute to illegal, unreported and unregulated fishing and refrain from introducing new such subsidies, recognizing that appropriate and effective special and differential treatment for developing and least developed countries should be an integral part of the World Trade Organization fisheries subsidies negotiation",
	"meta.ods.14.nombre.7":"14.7 By 2030, increase the economic benefits to Small Island developing States and least developed countries from the sustainable use of marine resources, including through sustainable management of fisheries, aquaculture and tourism",
	"meta.ods.15.nombre.1":"15.1 By 2020, ensure the conservation, restoration and sustainable use of terrestrial and inland freshwater ecosystems and their services, in particular forests, wetlands, mountains and drylands, in line with obligations under international agreements",
	"meta.ods.15.nombre.2":"15.2 By 2020, promote the implementation of sustainable management of all types of forests, halt deforestation, restore degraded forests and substantially increase afforestation and reforestation globally",
	"meta.ods.15.nombre.3":"15.3 By 2030, combat desertification, restore degraded land and soil, including land affected by desertification, drought and floods, and strive to achieve a land degradation-neutral world",
	"meta.ods.15.nombre.4":"15.4 By 2030, ensure the conservation of mountain ecosystems, including their biodiversity, in order to enhance their capacity to provide benefits that are essential for sustainable development",
	"meta.ods.15.nombre.5":"15.5 Take urgent and significant action to reduce the degradation of natural habitats, halt the loss of biodiversity and, by 2020, protect and prevent the extinction of threatened species",
	"meta.ods.15.nombre.8":"15.8 By 2020, introduce measures to prevent the introduction and significantly reduce the impact of invasive alien species on land and water ecosystems and control or eradicate the priority species",
	"meta.ods.15.nombre.a":"15.A Mobilize and significantly increase financial resources from all sources to conserve and sustainably use biodiversity and ecosystems",
	"meta.ods.15.nombre.6":"15.6 Promote fair and equitable sharing of the benefits arising from the utilization of genetic resources and promote appropriate access to such resources, as internationally agreed",
	"meta.ods.15.nombre.7":"15.7 Take urgent action to end poaching and trafficking of protected species of flora and fauna and address both demand and supply of illegal wildlife products",
	"meta.ods.15.nombre.9":"15.9 By 2020, integrate ecosystem and biodiversity values into national and local planning, development processes, poverty reduction strategies and accounts",
	"meta.ods.15.nombre.b":"15.B Mobilize significant resources from all sources and at all levels to finance sustainable forest management and provide adequate incentives to developing countries to advance such management, including for conservation and reforestation",
	"meta.ods.15.nombre.c":"15.C Enhance global support for efforts to combat poaching and trafficking of protected species, including by increasing the capacity of local communities to pursue sustainable livelihood opportunities",
	"meta.ods.16.nombre.1":"16.1 Significantly reduce all forms of violence and related death rates everywhere",
	"meta.ods.16.nombre.2":"16.2 End abuse, exploitation, trafficking and all forms of violence against and torture of children",
	"meta.ods.16.nombre.4":"16.4 By 2030, significantly reduce illicit financial and arms flows, strengthen the recovery and return of stolen assets and combat all forms of organized crime",
	"meta.ods.16.nombre.5":"16.5 Substantially reduce corruption and bribery in all their forms",
	"meta.ods.16.nombre.6":"16.6 Develop effective, accountable and transparent institutions at all levels",
	"meta.ods.16.nombre.7":"16.7 Ensure responsive, inclusive, participatory and representative decision-making at all levels",
	"meta.ods.16.nombre.10":"16.10 Ensure public access to information and protect fundamental freedoms, in accordance with national legislation and international agreements",
	"meta.ods.16.nombre.3":"16.3 Promote the rule of law at the national and international levels and ensure equal access to justice for all",
	"meta.ods.16.nombre.8":"16.8 Broaden and strengthen the participation of developing countries in the institutions of global governance",
	"meta.ods.16.nombre.9":"16.9 By 2030, provide legal identity for all, including birth registration",
	"meta.ods.16.nombre.a":"16.A Strengthen relevant national institutions, including through international cooperation, for building capacity at all levels, in particular in developing countries, to prevent violence and combat terrorism and crime",
	"meta.ods.16.nombre.b":"16.B Promote and enforce non-discriminatory laws and policies for sustainable development",
	"meta.ods.17.nombre.3":"17.3 Mobilize additional financial resources for developing countries from multiple sources",
	"meta.ods.17.nombre.7":"17.7 Promote the development, transfer, dissemination and diffusion of environmentally sound technologies to developing countries on favourable terms, including on concessional and preferential terms, as mutually agreed",
	"meta.ods.17.nombre.17":"17.17 Encourage and promote effective public, public-private and civil society partnerships, building on the experience and resourcing strategies of partnerships Data, monitoring and accountability",
	"meta.ods.17.nombre.19":"17.19 By 2030, build on existing initiatives to develop measurements of progress on sustainable development that complement gross domestic product, and support statistical capacity-building in developing countries",
	"meta.ods.17.nombre.1":"17.1 Strengthen domestic resource mobilization, including through international support to developing countries, to improve domestic capacity for tax and other revenue collection",
	"meta.ods.17.nombre.9":"17.9 Enhance international support for implementing effective and targeted capacity-building in developing countries to support national plans to implement all the sustainable development goals, including through North-South, South-South and triangular cooperation",
	"meta.ods.17.nombre.10":"17.10 Promote a universal, rules-based, open, non-discriminatory and equitable multilateral trading system under the World Trade Organization, including through the conclusion of negotiations under its Doha Development Agenda",
	"meta.ods.17.nombre.12":"17.12 Realize timely implementation of duty-free and quota-free market access on a lasting basis for all least developed countries, consistent with World Trade Organization decisions, including by ensuring that preferential rules of origin applicable to imports from least developed countries are transparent and simple, and contribute to facilitating market access",
	"meta.ods.17.nombre.15":"17.15 Respect each country’s policy space and leadership to establish and implement policies for poverty eradication and sustainable development",
	"meta.ods.17.nombre.2":"17.2 Developed countries to implement fully their official development assistance commitments, including the commitment by many developed countries to achieve the target of 0.7 per cent of ODA/GNI to developing countries and 0.15 to 0.20 per cent of ODA/GNI to least developed countries ODA providers are encouraged to consider setting a target to provide at least 0.20 per cent of ODA/GNI to least developed countries",
	"meta.ods.17.nombre.4":"17.4 Assist developing countries in attaining long-term debt sustainability through coordinated policies aimed at fostering debt financing, debt relief and debt restructuring, as appropriate, and address the external debt of highly indebted poor countries to reduce debt distress",
	"meta.ods.17.nombre.5":"17.5 Adopt and implement investment promotion regimes for least developed countries",
	"meta.ods.17.nombre.6":"17.6 Enhance North-South, South-South and triangular regional and international cooperation on and access to science, technology and innovation and enhance knowledge sharing on mutually agreed terms, including through improved coordination among existing mechanisms, in particular at the United Nations level, and through a global technology facilitation mechanism",
	"meta.ods.17.nombre.8":"17.8 Fully operationalize the technology bank and science, technology and innovation capacity-building mechanism for least developed countries by 2017 and enhance the use of enabling technology, in particular information and communications technology",
	"meta.ods.17.nombre.11":"17.11 Significantly increase the exports of developing countries, in particular with a view to doubling the least developed countries’ share of global exports by 2020",
	"meta.ods.17.nombre.13":"17.13 Enhance global macroeconomic stability, including through policy coordination and policy coherence",
	"meta.ods.17.nombre.14":"17.14 Enhance policy coherence for sustainable development",
	"meta.ods.17.nombre.16":"17.16 Enhance the global partnership for sustainable development, complemented by multi-stakeholder partnerships that mobilize and share knowledge, expertise, technology and financial resources, to support the achievement of the sustainable development goals in all countries, in particular developing countries",
	"meta.ods.17.nombre.18":"17.18 By 2020, enhance capacity-building support to developing countries, including for least developed countries and small island developing States, to increase significantly the availability of high-quality, timely and reliable data disaggregated by income, gender, age, race, ethnicity, migratory status, disability, geographic location and other characteristics relevant in national contexts",
	"motivacion.nombre.puntual":"Charitable gift",
	"motivacion.nombre.inversion":"Community investment",
	"motivacion.nombre.alineada":"Commercial initiatives in the community",
	"motivacion.nombre.propia":"Own initiative",
	"motivacion.nombre.compartida":"Shared initiative",
	"motivacion.nombre.apoyo":"Support initiative",
	"ods.nombre.pobreza":"1. No poverty",
	"ods.nombre.hambre":"2. Zero hunger",
	"ods.nombre.salud":"3. Good Health and well-being",
	"ods.nombre.educacion":"4. Quality education",
	"ods.nombre.genero":"5. Gender equality",
	"ods.nombre.saneamiento":"6. Clean water and sanitation",
	"ods.nombre.energia":"7. Affordable and clean energy",
	"ods.nombre.trabajo":"8. Decent work and economic growth",
	"ods.nombre.industria":"9. Industry, innovation and infrastructure",
	"ods.nombre.desigualdad":"10. Reduced inequality",
	"ods.nombre.sostenibilidad":"11. Sustainable cities and communities",
	"ods.nombre.consumo":"12. Responsible consumption and production",
	"ods.nombre.clima":"13. Climate action",
	"ods.nombre.vida.submarina":"14. Life below water",
	"ods.nombre.vida.terrestre":"15. Life on land",
	"ods.nombre.paz":"16. Peace, justice and strong institutions",
	"ods.nombre.alianza":"17. Collaborators for the goals",
	"razon.nombre.obligacion.legal":"Legal or regulatory obligation",
	"razon.nombrer.otros":"Others",
	"razon.nombre.obligacion.contractual":"Contractual obligation",
	"razon.nombre.dirigidas.empleados":"Contributions specifically targeted to employees",
	"razon.nombre.dirigidas.deporte":"Contributions to professional sports",
	"situacion.nombre.desempleo":"Unemployed",
	"situacion.nombre.desempleo.larga":"In a situation of long-term unemployment",
	"situacion.nombre.dificultad.aprendizaje":"With learning difficulties",
	"situacion.nombre.analfabetos":"No academic background or illiterate",
	"situacion.nombre.sin.hogar":"Homeless",
	"situacion.nombre.inmigrantes":"Immigrants",
	"situacion.nombre.refugiados":"Refugees",
	"situacion.nombre.presidiario":"Convicts or ex convicts",
	"situacion.nombre.ingresos.bajos":"Low income",
	"situacion.nombre.minorias":"Ethnic minorities",
	"situacion.nombre.pais.desarrollo":"Living in developing countries",
	"situacion.nombre.vulnerabilidad":"In a vulnerable situation",
	"situacion.nombre.desastre.natural":"Victims of a catastrophe or natural disaster",
	"situacion.nombre.enfermedad":"People who are ill or have a medical condition",
	"situacion.nombre.discapacidad":"People with disabilities",
	"situacion.nombre.dependencia":"People with a dependency (drug addiction, alcoholism, other addictions)",
	"situacion.nombre.abusos":"Suffered abuse (sexual or other)",
	"situacion.nombre.beneficiario":"Beneficiaries of the project",
	"situacion.nombre.otros":"Other profiles",
	"tipo.empresa.empresa":"Company",
	"tipo.empresa.onl":"Non-profit",
	"tipo.empresa.fundacion":"Corporate foundation",
	"tipo.empresa.otras":".",
	"tipo.logro.mujeres.hombres.todos":"Women and men of all ages",
	"tipo.logro.hombres.todos":"Men (of all ages)",
	"tipo.logro.mujeres.todas":"Women (of all ages)",
	"tipo.logro.niño.niñas.todos":"Boys and girls (0-16 years old)",
	"tipo.logro.niño":"Boys (0-16 years old)",
	"tipo.logro.niña":"Girls (0-16 years old)",
	"tipo.logro.jovenes.todos":"Young men and women (16-18 years old)",
	"tipo.logro.joven.chico":"Young men (16-18 years old)",
	"tipo.logro.joven.chica":"Young women (16-18 years old)",
	"tipo.logro.hombre.mujer.mayor.18":"Men and women (> 18 years)",
	"tipo.logro.hombre.mayor.18":"Men (> 18 years)",
	"tipo.logro.mujere.mayor.18":"Women (> 18 years)",
	"tipo.logro.mujer.hombre.mayor.45":"Men and women (> 45 years)",
	"tipo.logro.hombre.mayor.45":"Men (> 45 years)",
	"tipo.logro.mujer.mayor.45":"Women (> 45 years)",
	"tipo.logro.mujer.hombre.mayor.65":"Men and women (> 65 years)",
	"tipo.logro.hombre.mayor.65":"Men (> 65 years)",
	"tipo.logro.mujer.mayor.65":"Women (> 65 years)",
	"export.excel.option.yes":"Yes",
	"export.excel.option.no":"No",
	"export.excel.sheet.impactos":"Impacts",
	"export.excel.sheet.logros":"Outputs",
	"export.excel.sheet.contribuciones":"Contributions",
	"export.excel.headers.contribuciones":"CONTRIBUTIONS",
	"export.excel.headers.validacion":"Validation",
	"export.excel.headers.texto.presupuesto":"Budget",
	"export.excel.headers.codigo":"Code",
	"export.excel.headers.estado":"State",
	"export.excel.headers.nombre.proyecto":"Project name",
	"export.excel.headers.descripcion.proyecto":"Project description",
	"export.excel.headers.unidad":"Unit",
	"export.excel.headers.agrupacion.interna":"Internal classification",
	"export.excel.headers.fecha.inicio.proyecto":"Start date of the project",
	"export.excel.headers.fecha.fin.proyecto":"End date of the project",
	"export.excel.headers.divisa":"Currency",
	"export.excel.headers.presupuesto.total":"Total budget",
	"export.excel.headers.supervisores.proyecto":"Project supervisors",
	"export.excel.headers.numero.iniciativas":"Nº of initiatives",
	"export.excel.headers.codigo.iniciativa":"Code of the initiative",
	"export.excel.headers.nombre.iniciativa":"Name of initiative",
	"export.excel.headers.descripcion.iniciativa":"Description of the initiative",
	"export.excel.headers.fecha.inicio.iniciativa":"Start date of the initiative",
	"export.excel.headers.fecha.fin.iniciativa":"End date of the initiative",
	"export.excel.headers.pais":"Country",
	"export.excel.headers.region":"Region",
	"export.excel.headers.ciudad":"City",
	"export.excel.headers.area.actuacion.primaria":"Primary subject focus",
	"export.excel.headers.porcentaje.area.actuacion.primaria":"% of primary subject focus",
	"export.excel.headers.area.actuacion.secundaria":"Secondary subject focus",
	"export.excel.headers.porcentaje.area.actuacion.secundaria":"% of secondary subject focus",
	"export.excel.headers.entidades.participan":"Entities participating in the initiative",
	"export.excel.headers.motivacion":"Motivation",
	"export.excel.headers.grado.implicacion":"Degree of involvement",
	"export.excel.headers.cumple.requistos":"Meets LGB / ONLGB principles",
	"export.excel.headers.razones":"Reasons",
	"export.excel.headers.comentarios":"Comments",
	"export.excel.headers.ods.primario":"Primary Development Goal (SDG)",
	"export.excel.headers.meta.ods.primario":"Goal (SDG1º)",
	"export.excel.headers.porcentaje.ods.primario":"% SDG1º",
	"export.excel.headers.ods.secundario":"Secondary Development Goal (SDG)",
	"export.excel.headers.meta.ods.secundario":"Goal (SDG2º)",
	"export.excel.headers.porcentaje.ods.secundario":"% SDG2º",
	"export.excel.headers.entidad.presupuesto":"Entity (Budget)",
	"export.excel.headers.presupuesto":"Budget",
	"export.excel.headers.pais.origen.fondos":"Country source of funds",
	"export.excel.headers.descripcion":"Description",
	"export.excel.headers.supervisores.iniciativa":"Initiative supervisors",
	"export.excel.headers.agentes":"Agents",
	"export.excel.headers.numero.contribuciones":"Nº of contributions",
	"export.excel.headers.codigo.contribucion":"Contribution code",
	"export.excel.headers.tipo":"Type",
	"export.excel.headers.contribuyente":"Contributor",
	"export.excel.headers.fecha.contribucion":"Date of contribution",
	"export.excel.headers.importe.contribucion":"Amount of contribution",
	"export.excel.headers.divisa.contribucion":"Contribution currency",
	"export.excel.headers.descripcion.contribucion":"Description of contribution",
	"export.excel.headers.atribucion.contribucion":"Contribution attribution",
	"export.excel.headers.tabla.salarial":"Salary table",
	"export.excel.headers.numero.personas":"Nº of people",
	"export.excel.headers.horas.totales":"Total hours",
	"export.excel.headers.contribucion.divisa":"Contribution in currency selected for the report applying exchange rate",
	"export.excel.headers.validado":"Validated",
	"export.excel.headers.logros":"Outputs",
	"export.excel.headers.beneficiarios.directos":"Direct beneficiaries",
	"export.excel.headers.beneficiarios.indirectos":"Indirect beneficiaries",
	"export.excel.headers.logros.medioambientales":"Environmental outputs",
	"export.excel.headers.organizaciones.beneficiarias":"Beneficiary organisations",
	"export.excel.headers.logros.grupos.interes":"Outputs with stakeholders",
	"export.excel.headers.alianzas.alcanzadas":"Alliances and collaborations reached",
	"export.excel.headers.medios.comunicacion":"Media",
	"export.excel.headers.cumplimiento.objetivos":"Fulfilment of initiative goals",
	"export.excel.headers.codigo.logro":"Code of output",
	"export.excel.headers.fecha.evaluacion.logro":"Output evaluation date",
	"export.excel.headers.descripcion.logro":"Description of output",
	"export.excel.headers.numero.beneficiarios.directos":"Nº of direct beneficiaries",
	"export.excel.headers.tipo.beneficiarios.directos":"Type of direct beneficiaries",
	"export.excel.headers.situacion.beneficiarios.directos":"Situation of direct beneficiaries",
	"export.excel.headers.observaciones.beneficiarios.directos":"Observations: direct beneficiaries",
	"export.excel.headers.numero.beneficiarios.indirectos":"Nº of indirect beneficiaries",
	"export.excel.headers.tipo.beneficiarios.indirectos":"Type of indirect beneficiary",
	"export.excel.headers.situacion.beneficiarios.indirectos":"Situation of indirect beneficiaries",
	"export.excel.headers.observaciones.beneficiarios.indirectos":"Observations: indirect beneficiaries",
	"export.excel.headers.numero.logros.medioambientales":"Nº of environmental outputs",
	"export.excel.headers.descripcion.logros.medioambientales":"Description of environmental outputs",
	"export.excel.headers.tipo.organizacion.beneficiaria":"Type of beneficiary organisation",
	"export.excel.headers.nombre.organizacion.beneficiaria":"Name of beneficiary organisation",
	"export.excel.headers.entidad.logro":"Entity (output with stakeholders)",
	"export.excel.headers.grupos.interes.alcanzado":"Stakeholders reached",
	"export.excel.headers.numero.ggii.alcanzados":"Nº of stakeholders reached",
	"export.excel.headers.observaciones.logros":"Observations: outputs with stakeholders",
	"export.excel.headers.entidad.alianzas":"Entity (Alliances and collaborations achieved)",
	"export.excel.headers.generado.alianzas":"Generated alliances",
	"export.excel.headers.numero.alianzas":"Nº of alliances generated",
	"export.excel.headers.observaciones.alianzas":"Observations: alliances and collaborations generated",
	"export.excel.headers.entidad.medios":"Entity (Media output)",
	"export.excel.headers.valor.generado.medios":"Value generated in media",
	"export.excel.headers.divisa.valor.generado.medios":"Currency value generated in media",
	"export.excel.headers.observaciones.medios":"Observations: media",
	"export.excel.headers.entidad.objetivos":"Entity (Fulfilment of initiative goals)",
	"export.excel.headers.alineacion.mision":"Alignment with the mission",
	"export.excel.headers.observaciones.alineacion.mision":"Observations: alignment with the mission",
	"export.excel.headers.ejecucion.eficiencia":"Execution of the project in efficiency",
	"export.excel.headers.ejecucion.eficacia":"Execution of the project in effectiveness",
	"export.excel.headers.sostenibilidad.economica":"Economic sustainability",
	"export.excel.headers.alineacion.resultados":"Alignment of results with the goals of the initiative",
	"export.excel.headers.conclusiones.alcanzadas":"Conclusions reached (Fulfilment of goals and analysis of initiatives)",
	"export.excel.headers.impactos":"Impacts",
	"export.excel.headers.impacto.beneficiarios":"Impacts on beneficiaries",
	"export.excel.headers.medio.ambiente":"Environment",
	"export.excel.headers.impacto.empleados":"Impact on employees / volunteers",
	"export.excel.headers.impacto.entidades":"Impact on participating entities",
	"export.excel.headers.codigo.impacto":"Impact code",
	"export.excel.headers.fecha.evalucion.impacto":"Impact evaluation date",
	"export.excel.headers.descripcion.evaluacion.impacto":"Description of impact evaluation",
	"export.excel.headers.numero.beneficiarios.directos.impacto":"Nº of direct beneficiaries",
	"export.excel.headers.numero.beneficiarios.directos.evaluados":"Nº of direct beneficiaries where results were measured",
	"export.excel.headers.descripcion.beneficiarios.evaluados":"Description of beneficiaries where results were measured",
	"export.excel.headers.sensibilizados":"Connected",
	"export.excel.headers.observaciones.sensibilizados":"Observations (Made a connection)",
	"export.excel.headers.conseguido.mejora":"Improved",
	"export.excel.headers.observaciones.mejora":"Observations (Made an improvement)",
	"export.excel.headers.transformados":"Transformed",
	"export.excel.headers.observaciones.transformados":"Observations (Transformed)",
	"export.excel.headers.beneficiarios.cambio.positivo":"Beneficiaries who have experienced positive change in behaviour or attitude",
	"export.excel.headers.observaciones.cambio.positivo":"Observations (Positive change in behaviour)",
	"export.excel.headers.beneficiarios.nuevas.habilidades":"Beneficiaries who have developed new skills or an increase in their personal effectiveness",
	"export.excel.headers.observaciones.nuevas.habilidades":"Observations (acquired new skills)",
	"export.excel.headers.beneficiarios.impacto.positivo":"Beneficiaries who have experienced a direct positive impact on their quality of life",
	"export.excel.headers.observaciones.impacto.positivo":"Observations (positive impact on quality of life)",
	"export.excel.headers.grado.impactos.medioambientales":"Did the project deliver environmental benefits?",
	"export.excel.headers.grado.comportamiento.ambiental":"Did the activity change people's environmental behaviour?",
	"export.excel.headers.grupo.participantes.evaluados":"Group of participants where results were measured",
	"export.excel.headers.numero.participantes":"Nº of participants",
	"export.excel.headers.numero.participantes.evaluados":"Nº of participants where results were measured",
	"export.excel.headers.numero.participantes.habilidades":"Nº of participants who have developed new skills",
	"export.excel.headers.numero.participantes.habilidades.sin.cambios":"Nº of participants who have developed new skills - No difference",
	"export.excel.headers.numero.participantes.habilidades.poco":"Nº of participants who have developed skills - A little",
	"export.excel.headers.numero.participantes.habilidaades.algo":"Nº of participants who have developed new skills - Some difference",
	"export.excel.headers.numero.participantes.habilidaades.bastante":"Nº of participants who have developed new skills - A lot",
	"export.excel.headers.numero.participantes.cambio.desarrollo":"Nº of participants who have increased their personal well-being (e.g. self-confidence, job satisfaction)",
	"export.excel.headers.numero.participantes.cambio.desarrollo.sin.cambios":"Nº of participants who have increased their personal well-being (e.g. self-confidence, job satisfaction) - No difference",
	"export.excel.headers.numero.participantes.cambio.desarrollo.poco":"Nº of participants who have increased their personal well-being (e.g. self-confidence, job satisfaction) - A little",
	"export.excel.headers.numero.participantes.cambio.desarrollo.algo":"Nº of participants who have increased their personal well-being (e.g. self-confidence, job satisfaction) - Some difference",
	"export.excel.headers.numero.participantes.cambio.desarrollo.bastante":"Nº of participants who have increased their personal well-being (e.g. self-confidence, job satisfaction) - A lot",
	"export.excel.headers.numero.participantes.cambio.comportamiento":"Nº of participants who have experienced positive change in behaviour or attitude",
	"export.excel.headers.numero.participantes.cambio.comportamiento.sin.cambios":"Nº of participants who have experienced positive change in behaviour or attitude - No difference",
	"export.excel.headers.numero.participantes.cambio.comportamiento.poco":"Nº of participants who have experienced positive change in behaviour or attitude - A little",
	"export.excel.headers.numero.participantes.cambio.comportamiento.algo":"Nº of participants who have experienced positive change in behaviour or attitude - Some difference",
	"export.excel.headers.numero.participantes.cambio.comportamiento.bastante":"Nº of participants who have experienced positive change in behaviour or attitude - A lot",
	"export.excel.headers.entidades.evaluar":"Entities to be evaluated",
	"export.excel.headers.entidades.participantes":"Participating entities",
	"export.excel.headers.nombre.entidad":"Name of evaluated entity",
	"export.excel.headers.grado.mejora.relaciones":"Improvement in the relationship with its stakeholders",
	"export.excel.headers.grado.mejora.gestion":"Improvement in its management systems or other operational improvements  (e.g. IT, HR, finance)",
	"export.excel.headers.grado.capacidad.empleo":"Increase of the entity's ability to employ more staff / take on more volunteers",
	"export.excel.headers.grado.mejora.servicios":"Improvement in existing services / products or increase in the ability to provide new services / products",
	"export.excel.headers.grado.generado.ingresos":"Income generated",
	"export.excel.headers.grado.generado.alianzas":"Generated collaborations and partnerships",
	"export.excel.headers.grado.mejora.credibilidad":"Improvement in the brand's credibility",
	"export.excel.headers.grado.mejora.visibilidad":"Increase of its visibility",
	"export.excel.headers.otros.impactos":"Other impacts achieved",
	"export.excel.headers.iniciativa":"INITIATIVE",
	"export.excel.headers.proyecto":"PROJECT",
	"salarytables.descripcion":"Description",
	"salarytables.descripcionPublica":"Table description",
	"salarytables.descripcionPrivada":"Information for internal management",
	"pais.nombre.españa":"Spain",
	"pais.nombre.francia":"France",
	"pais.nombre.reini.unido":"United Kingdom",
	"pais.nombre.alemania":"Germany",
	"pais.nombre.estados.unidos":"United States",
	"pais.nombre.mexico":"Mexico",
	"pais.nombre.abjasia":"Abkhazia",
	"pais.nombre.afganistan":"Afghanistan",
	"pais.nombre.albania":"Albania",
	"pais.nombre.andorra":"Andorra",
	"pais.nombre.angola":"Angola",
	"pais.nombre.anguila":"Anguila",
	"pais.nombre.antigua":"Antigua and Barbuda",
	"pais.nombre.arabia":"Saudi Arabia",
	"pais.nombre.argelia":"Algeria",
	"pais.nombre.argentina":"Argentina",
	"pais.nombre.armenia":"Armenia",
	"pais.nombre.aruba":"Aruba",
	"pais.nombre.australia":"Australia",
	"pais.nombre.austria":"Austria",
	"pais.nombre.azerbaiyan":"Azerbaijan",
	"pais.nombre.bahamas":"Bahamas",
	"pais.nombre.bangladesh":"Bangladesh",
	"pais.nombre.barbados":"Barbados",
	"pais.nombre.barein":"Bahrain",
	"pais.nombre.belgica":"Belgium",
	"pais.nombre.belice":"Belize",
	"pais.nombre.benin":"Benin",
	"pais.nombre.bermudas":"Bermuda",
	"pais.nombre.bielorrusia":"Belarus",
	"pais.nombre.birmania":"Myanmar, Burma",
	"pais.nombre.bolivia":"Bolivia",
	"pais.nombre.bosnia":"Bosnia y Herzegovina",
	"pais.nombre.botsuana":"Botswana",
	"pais.nombre.brasil":"Brazil",
	"pais.nombre.brunei":"Brunei Darussalam",
	"pais.nombre.bulgaria":"Bulgaria",
	"pais.nombre.burkina":"Burkina Faso",
	"pais.nombre.burundi":"Burundi",
	"pais.nombre.butan":"Bhutan",
	"pais.nombre.cabo":"Cape Verde",
	"pais.nombre.camboya":"Cambodia",
	"pais.nombre.camerun":"Cameroon",
	"pais.nombre.canada":"Canada",
	"pais.nombre.caribe":"Dutch Caribbean",
	"pais.nombre.chad":"Chad",
	"pais.nombre.chile":"Chile",
	"pais.nombre.china":"China",
	"pais.nombre.chipre":"Cyprus",
	"pais.nombre.chipre.septentrional":"Northern Cyprus",
	"pais.nombre.ciudad.vaticano":"Vatican City State",
	"pais.nombre.colombia":"Colombia",
	"pais.nombre.comoras":"Comoros",
	"pais.nombre.corea.norte":"North Korea",
	"pais.nombre.corea.sur":"South Korea",
	"pais.nombre.costa.marfil":"Ivory Coast",
	"pais.nombre.costa.rica":"Costa Rica",
	"pais.nombre.croacia":"Croatia",
	"pais.nombre.cuba":"Cuba",
	"pais.nombre.curazao":"Curazao",
	"pais.nombre.dinamarca":"Denmark",
	"pais.nombre.dominica":"Dominica",
	"pais.nombre.ecuador":"Ecuador",
	"pais.nombre.egipto":"Egypt",
	"pais.nombre.elsalvador":"El Salvador",
	"pais.nombre.emiratos":"United Arab Emirates",
	"pais.nombre.eritrea":"Eritrea",
	"pais.nombre.eslovaquia":"Slovakia",
	"pais.nombre.eslovenia":"Slovenia",
	"pais.nombre.estados.federados.micronesia":"Federated States of Micronesia",
	"pais.nombre.estonia":"Estonia",
	"pais.nombre.etiopia":"Ethiopia",
	"pais.nombre.filipinas":"Philippines",
	"pais.nombre.finlandia":"Finland",
	"pais.nombre.fiyi":"Fiji",
	"pais.nombre.gabon":"Gabon",
	"pais.nombre.gambia":"Gambia",
	"pais.nombre.georgia":"Georgia",
	"pais.nombre.ghana":"Ghana",
	"pais.nombre.gibraltar":"Gibraltar",
	"pais.nombre.granada":"Grenada",
	"pais.nombre.grecia":"Greece",
	"pais.nombre.groenlandia":"Greenland",
	"pais.nombre.guadalupe":"Guadeloupe",
	"pais.nombre.guam":"Guam",
	"pais.nombre.guatemala":"Guatemala",
	"pais.nombre.guayana":"French Guayana",
	"pais.nombre.guernsey":"Guernsey",
	"pais.nombre.guinea":"Guinea",
	"pais.nombre.guinea.ecuatorial":"Equatorial Guinea",
	"pais.nombre.guinea.bissau":"Guinea-Bissau",
	"pais.nombre.guyana":"Guyana",
	"pais.nombre.haiti":"Haiti",
	"pais.nombre.honduras":"Honduras",
	"pais.nombre.hong.kong":"Hong Kong",
	"pais.nombre.hungría":"Hungary",
	"pais.nombre.india":"India",
	"pais.nombre.indonesia":"Indonesia",
	"pais.nombre.irak":"Iraq",
	"pais.nombre.irán":"Iran",
	"pais.nombre.irlanda":"Ireland",
	"pais.nombre.isla.ascension":"Georgetown",
	"pais.nombre.isla.navidad":"Territory of Christmas Island",
	"pais.nombre.islandia":"Iceland",
	"pais.nombre.islas.caimán":"Cayman Islands",
	"pais.nombre.islas.marshall":"Marshall Islands",
	"pais.nombre.islas.salomón":"Solomon Islands",
	"pais.nombre.islas.turcas y Caicos":"Turks and Caicos Islands",
	"pais.nombre.islas.virgenes.británicas":"Virgin Islands (British)",
	"pais.nombre.islas.virgenes.estados.unidos":"Virgin Islands (U.S.)",
	"pais.nombre.israel":"Israel",
	"pais.nombre.italia":"Italy",
	"pais.nombre.jamaica":"Jamaica",
	"pais.nombre.japon":"Japan",
	"pais.nombre.jordania":"Jordan",
	"pais.nombre.kazajistan":"Kazakhstan",
	"pais.nombre.kenia":"Kenya",
	"pais.nombre.kirguistan":"Kyrgyzstan",
	"pais.nombre.kiribati":"Kiribati",
	"pais.nombre.kosovo":"Kosovo",
	"pais.nombre.kuwait":"Kuwait",
	"pais.nombre.laos":"Lao, People's Democratic Republic",
	"pais.nombre.lesoto":"Lesotho",
	"pais.nombre.letonia":"Latvia",
	"pais.nombre.libano":"Lebanon",
	"pais.nombre.liberia":"Liberia",
	"pais.nombre.libia":"Libya",
	"pais.nombre.liechtenstein":"Liechtenstein",
	"pais.nombre.lituania":"Lithuania",
	"pais.nombre.luxemburgo":"Luxemburgo",
	"pais.nombre.madagascar":"Madagascar",
	"pais.nombre.malasia":"Malaysia",
	"pais.nombre.malaui":"Malawi",
	"pais.nombre.maldivas":"Maldives",
	"pais.nombre.mali":"Mali",
	"pais.nombre.malta":"Malta",
	"pais.nombre.marruecos":"Morocco",
	"pais.nombre.martinica":"Martinique",
	"pais.nombre.mauricio":"Mauritius",
	"pais.nombre.mauritania":"Mauritania",
	"pais.nombre.mayotte":"Mayotte",
	"pais.nombre.moldavia":"Moldova, Republic of",
	"pais.nombre.mongolia":"Mongolia",
	"pais.nombre.montserrat":"Montserrat",
	"pais.nombre.mozambique":"Mozambique",
	"pais.nombre.namibia":"Namibia",
	"pais.nombre.nauru":"Nauru",
	"pais.nombre.nepal":"Nepal",
	"pais.nombre.nicaragua":"Nicaragua",
	"pais.nombre.niger":"Niger",
	"pais.nombre.nigeria":"Nigeria",
	"pais.nombre.niue":"Niue",
	"pais.nombre.noruega":"Norway",
	"pais.nombre.nueva.zelanda":"New Zealand",
	"pais.nombre.oman":"Oman",
	"pais.nombre.paeses.bajos":"Netherlands",
	"pais.nombre.pakistan":"Pakistan",
	"pais.nombre.palaos":"Palau",
	"pais.nombre.panama":"Panama",
	"pais.nombre.papua":"Papua New Guinea",
	"pais.nombre.paraguay":"Paraguay",
	"pais.nombre.peru":"Peru",
	"pais.nombre.polonia":"Poland",
	"pais.nombre.portugal":"Portugal",
	"pais.nombre.puerto.rico":"Puerto Rico",
	"pais.nombre.qatar":"Qatar",
	"pais.nombre.republica.centroafricana":"Central African Republic",
	"pais.nombre.republica.checa":"Czech Republic",
	"pais.nombre.republica.macedonia":"Macedonia, Rep. of",
	"pais.nombre.republica.congo":"Congo, Republic of (Brazzaville)",
	"pais.nombre.republica.democratica.congo":"Democratic Republic of the Congo (Kinshasa)",
	"pais.nombre.republica.dominicana":"Dominican Republic",
	"pais.nombre.reunion":"Reunion Island",
	"pais.nombre.ruanda":"Rwanda",
	"pais.nombre.rumania":"Romania",
	"pais.nombre.rusia":"Russian Federation",
	"pais.nombre.sahara.occidental":"Western Sahara",
	"pais.nombre.samoa":"Samoa",
	"pais.nombre.san.bartolome":"San Bartolomé",
	"pais.nombre.san.cristobal":"Saint Kitts and Nevis",
	"pais.nombre.san.marino":"Saint Marino",
	"pais.nombre.san.martin":"Saint Martin",
	"pais.nombre.san.pedro":"San Pedro and Miquelon",
	"pais.nombre.san.vicente":"Saint Vincent and the Grenadines",
	"pais.nombre.santa.helena":"Saint Helena",
	"pais.nombre.santa.lucia":"Saint Lucia",
	"pais.nombre.santo.tome":"Sao Tome and Principe",
	"pais.nombre.senegal":"Senegal",
	"pais.nombre.serbia":"Serbia",
	"pais.nombre.seychelles":"Seychelles",
	"pais.nombre.sierra Leona":"Sierra Leone",
	"pais.nombre.singapur":"Singapore",
	"pais.nombre.siria":"Syria",
	"pais.nombre.somalia":"Somalía",
	"pais.nombre.somaliland":"Somaliland",
	"pais.nombre.sri Lanka":"Sri Lanka",
	"pais.nombre.suazilandia":"Swaziland",
	"pais.nombre.sudafrica":"South Africa",
	"pais.nombre.sudan":"Sudan",
	"pais.nombre.sudan.sur":"South Sudan",
	"pais.nombre.suecia":"Sweden",
	"pais.nombre.suiza":"Switzerland",
	"pais.nombre.surinam":"Suriname",
	"pais.nombre.tailandia":"Thailand",
	"pais.nombre.tanzania":"Tanzania",
	"pais.nombre.tayikistan":"Tajikistan",
	"pais.nombre.togo":"Togo",
	"pais.nombre.tonga":"Tonga",
	"pais.nombre.trinidad y Tobago":"Trinidad and Tobago",
	"pais.nombre.trista.cunha":"Tristan da Cunha",
	"pais.nombre.tunez":"Tunisia",
	"pais.nombre.turkmenistan":"Turkmenistan",
	"pais.nombre.turquia":"Turkey",
	"pais.nombre.tuvalu":"Tuvalu",
	"pais.nombre.ucrania":"Ukraine",
	"pais.nombre.uganda":"Uganda",
	"pais.nombre.uruguay":"Uruguay",
	"pais.nombre.uzbekistan":"Uzbekistan",
	"pais.nombre.vanuau":"Vanuatu",
	"pais.nombre.venezuela":"Venezuela",
	"pais.nombre.vietnam":"Vietnam",
	"pais.nombre.wallis":"Wallis and Futuna Islands",
	"pais.nombre.yemen":"Yemen",
	"pais.nombre.yibuti":"Djibouti",
	"pais.nombre.zambia":"Zambia",
	"pais.nombre.zimbabue":"Zimbabwe",
	"contribuyente.colaborador":"Collaborator",
	"contribuyente.payroll":"Payroll",
	"contribuyente.otras.aportacion.empleado":"Employee",
	"contribuyente.otras.aportacion.cliente":"Client",
	"contribuyente.otros":"Others",
	"collaborator.form.descripcion":"Description",
	"toolbartop.filterReport":"Reports",
	"initiativeform.colaborador":"Collaborator",
	"initiativeform.guardarYSeguir":"Save and continue with contributions",
	"achievementsform.alianzaColaboHeader":"Alliances and collaborations reached",
	"user.list.filter.active":"Active",
	"user.list.filter.inactive":"Inactive",
	"placeholder.impact.select.write.here":"Write here to filter",
	"atribution.label.all":"All",
	"atribution.label.e":"E",
	"atribution.label.ef":"L",
	"atribution.label.o":"O",
	"contributions.num.persons":"Nº of people",
	"contributions.salary.table":"Contribution table",
	"contributions.atribution.entity":"Entity",
	"contributions.atribution.leverage":"Leverage",
	"contributions.atribution.others":"Others",
	"contributions.atribution.entity.abbreviation":"E",
	"contributions.atribution.others.abbreviation":"O",
	"contributions.atribution.leverage.abbreviation":"L",
	"contributions.atribution.all":"All",
	"contributions.atribution.all.abbreviation":"All",
	"filterReport.byProjects":"By projects",
	"filterReport.informes":"Reports",
	"filterReport.tipoInformes":"Type of report",
	"filterReport.marcarTodos":"Select all",
	"filterReport.desmarcarTodos":"Deselect all",
	"filterReport.divisa":"Currency",
	"filterReport.seleccionaDivisa":"You must select a currency",
	"filterReport.proyectos":"Projects",
	"filterReport.proyectState":"Project status",
	"filterReport.divUdNegocio":"Business unit",
	"filterReport.agrInterna":"Internal classification",
	"filterReport.extractProyecto":"Extract data about budgets",
	"filterReport.proyectDate":"Projects with a start date between",
	"filterReport.desde":"From",
	"filterReport.hasta":"To",
	"filterReport.y":"And",
	"filterReport.proyectDateEnd":"Projects with an end date between",
	"filterReport.iniciativa":"Initiative",
	"filterReport.extraerLBG":"Extract initiatives that comply with LBG/ONLBG principles",
	"filterReport.pais":"Country",
	"filterReport.region":"Region",
	"filterReport.area":"Subject focus",
	"filterReport.motivacion":"Motivation",
	"filterReport.extractODS":"Extract data about SDG",
	"filterReport.ods":"Primary SDG",
	"filterReport.odsError":"You must select a Primary SDG if \"Extract information on SDG\" is set to \"Yes\"",
	"filterReport.extractInitiative":"Extract data about budgets",
	"filterReport.initiativeDate":"Initiatives with a start date between",
	"filterReport.initiativeDateEnd":"Projects with an end date between",
	"filterReport.contributions":"Contributions",
	"filterReport.validados":"Validated",
	"filterReport.atribucion":"Attribution",
	"filterReport.logros":"Outputs",
	"filterReport.impactos":"Impacts",
	"filterReport.generate":"Generate Report",
	"filterReport.generateCharts":"Generate Charts",
	"filterReport.downloadCharts":"Download Charts",
	"filterReport.divisaDelInforme":"Select report currency",
	"filterReport.entre":"Between",
	"filterReport.tipo.contribución":"Contribution type",
	"filterReport.unidad.negocio":"Business unit",
	"filterReport.ambito.geografico":"Country",
	"filterReport.pais.españa":"Spain",
	"filterReport.ODS":"SDG",
	"filterReport.empleados.y.voluntarios":"Employees and volunteers",
	"filterReport.efecto.multiplicador":"Leverage",
	"filterReport.resultados.comunidad":"Community outputs",
	"filterReport.resultados.entidad":"Entity outputs",
	"filterReport.contributiontype":"Type of contribution",
	"filterReport.contributionLey112018":"Contributions to a Foundation/NGO",
	"filterReport.dinero":"Cash",
	"filterReport.especie":"In kind",
	"filterReport.tiempo":"Time",
	"filterReport.probono":"Pro bono",
	"filterReport.coste.gestion":"Management costs",
	"filterReport.todos":"All",
	"filterReport.abiertos":"Ongoing",
	"filterReport.cerrados":"Closed",
	"filterReport.si":"Yes",
	"filterReport.no":"No",
	"filterReport.entidad":"Entity",
	"filterReport.otros":"Others",
	"filterReport.no.validados":"Invalidate",
	"filterReport.todas":"All",
	"filterReport.si.cumplen":"Yes",
	"filterReport.no.cumplen":"No",
	"filterReport.descarga":"Data Download",
	"filterReport.graficos":"Reports on screen",
	"EUR - Euro":"EUR - Euro",
	"USD - Dólar":"USD - Dollar",
	"GBP - Libra":"GBP - Pound",
	"AED - Dirham EAU":"AED - UAE Dirham",
	"ARS - Peso Argentino":"ARS - Argentine Peso",
	"AUD - Dólar australiano":"AUD - Australian Dollar",
	"AWG - Florín Aruba":"AWG - Aruba Florin",
	"BAM - Marco Bosnio":"BAM - Marka",
	"BGN - Lev búlgaro":"BGN - Bulgarian Lev",
	"BHD - Dinar Bareini":"BHD - Bahrain Dinar",
	"BOB - BOB":"BOB - Boliviano",
	"BRL - Real":"BRL - Real",
	"CAD - Dólar canadiense":"CAD - Canadian Dollar",
	"CHF - Franco suizo":"CHF - Swiss Franc",
	"CLP - UF":"CLP - UF",
	"CNY - Yuan chino":"CNY - Yuan",
	"COP - Peso colombiano":"COP - Colombian Peso",
	"CRC - Colón":"CRC - Colón",
	"CZK - Corona Checa":"CZK - Czech Koruna",
	"DKK - Corona Danesa":"DKK - Danish Krone",
	"DOP - Peso dominicano":"DOP - Dominican Peso",
	"DZD - Dinar argelino":"DZD - Algerian Dinar",
	"EGP - Libra egipcia":"EGP - Egypt Pound",
	"GTQ - Quetzal":"GTQ - Quetzal",
	"HKD - Dólar":"HKD - Hong Kong Dollar",
	"HNL - Lempira":"HNL - Lempira",
	"HRK - Kuna Croata":"HRK - Croatian Kuna",
	"HUF - Florín Hunfaro":"HUF - Hungarian Forint",
	"IDR - Rupia Indonesia":"IDR - Indonesia Rupiah",
	"ILS - Shequel israeli":"ILS - Shekel",
	"INR - Rupia india":"INR - Indian Rupiah",
	"JOD - Dinar jordano":"JOD - Jordan Dinar",
	"JPY - Yen japonés":"JPY - Japan Yen",
	"KWD - Dinar kuwaiti":"KWD - Kuwait Dinar",
	"MAD - Dirham Marroqui":"MAD - Moroccan Dirham",
	"MDL - Leu moldavo":"MDL - Moldova Leu",
	"MOP - Pataca":"MOP - Pataca",
	"MRO - Ouguiyas":"MRO - Ouguiya",
	"MXN - Peso mejicano":"MXN - Mexican peso",
	"MYR - Ringgit malayo":"MYR - Ringgit",
	"NOK - Corona noruega":"NOK - Norwegian Krone",
	"OMR - Real omaní":"NZD - Oman Rial",
	"PAB - Balboa Panameño":"OMR - Balboa",
	"PEN - Nuevo sol":"PAB - New Sol",
	"PHP - Peso Filipino":"PEN - Philippines Peso",
	"PLN - Zloty":"PLN - Zloty",
	"PYG - Guaraní Paraguay":"PYG -  Guarani",
	"QAR - Riyal":"QAR- Riyal",
	"RON - Leu Rumano":"RON - Romanian Leu",
	"RUB - Rublo":"RUB - Ruble",
	"SAR - Real Saudí":"SAR - Saudi Riyal",
	"SEK - Corona Sueca":"SEK - Sweden Krona",
	"SGD - Dólar de Singapur":"SGD - Singapore Dollar",
	"TND - Dinar tunecino":"TND - Tunisia Dinar",
	"TRY - Nueva Lira turca":"TRY - Turkish Lira",
	"UDI - Udi":"UDI - Udi",
	"UYU - Peso uruguayo":"UYU - Uruguayan Peso",
	"VEF - Bolivar Fuerte":"VEF - Bolivar Fuerte",
	"VND - Dong Viernamita":"VND - Dong",
	"XAF - Franco CFA":"XAF - CFA Franc",
	"manual-altaUsuarios":"https://manual.masimpact.com/en/3/navigation_menu/2/management/2/new_user",
	"manual-altaColaboradores":"https://manual.masimpact.com/en/3/navigation_menu/2/management/3/new_collaborator",
	"manual-divisionYAgrupacion":"https://manual.masimpact.com/en/3/navigation_menu/3/projects/2/departmentunit_and_inner_grouping",
	"manual-presupuesto":"https://manual.masimpact.com/en/3/navigation_menu/3/projects/3/planned_budget",
	"manual-asignacionUsuarios":"https://manual.masimpact.com/en/3/navigation_menu/3/projects/4/assignment_of_users",
	"manual-nuevaIniciativa":"https://manual.masimpact.com/en/3/navigation_menu/4/initiatives/1/new_initiative",
	"manual-areaActuacion":"https://manual.masimpact.com/en/3/navigation_menu/4/initiatives/2/issue",
	"manual-motivacion":"https://manual.masimpact.com/en/3/navigation_menu/4/initiatives/3/motivation_of_the_entity",
	"manual-entidadesQueContribuyen":"https://manual.masimpact.com/en/3/navigation_menu/4/initiatives/4/collaborators_in_the_initiative",
	"manual-principiosLBG":"https://manual.masimpact.com/en/3/navigation_menu/4/initiatives/5/does_it_comply_with_the_lbg__onlbg_principles",
	"manual-objetivosDesarrollo":"https://manual.masimpact.com/en/3/navigation_menu/4/initiatives/6/sustainable_development_goals",
	"manual-presupuestosEntidades":"https://manual.masimpact.com/en/3/navigation_menu/4/initiatives/7/planned_budget_of_entities",
	"manual-contribuciones":"https://manual.masimpact.com/en/3/navigation_menu/5/contributions",
	"manual-beneficiarios":"https://manual.masimpact.com/en/3/navigation_menu/6/outputs/2/beneficiaries",
	"manual-logrosMedioAmbiente":"https://manual.masimpact.com/en/3/navigation_menu/6/outputs/3/environmental_outputs",
	"manual-organizacionesBeneficiarias":"https://manual.masimpact.com/en/3/navigation_menu/6/outputs/4/beneficiary_organizations",
	"manual-logrosParaEntidades":"https://manual.masimpact.com/en/3/navigation_menu/6/outputs/5/outputs_for_the_entities",
	"manual-cumplimientoObjetivos":"https://manual.masimpact.com/en/3/navigation_menu/6/outputs/6/compliance_with_objectives",
	"manual-impactosSobreBeneficiarios":"https://manual.masimpact.com/en/3/navigation_menu/7/impacts/2/beneficiaries_impact",
	"manual-impactosSobreMedioAmbiente":"https://manual.masimpact.com/en/3/navigation_menu/7/impacts/3/environmental_impact",
	"manual-impactosSobreVoluntarios":"https://manual.masimpact.com/en/3/navigation_menu/7/impacts/4/impact_on_employees_or_volunteers",
	"manual-impactosSobreEntidades":"https://manual.masimpact.com/en/3/navigation_menu/7/impacts/5/impact_on_entities",
	"reportChart.activeProyects":"Active projects",
	"reportChart.activeInitiatives":"Active initiatives",
	"reportChart.contributionProyects":"Projects with contributions",
	"reportChart.contributionInitiatives":"Initiatives with contributions",
	"reportChart.entityContribution":"{{ITV}} contributions",
	"reportChart.total":"Total",
	"reportChart.header.contribType":"Type of contribution",
	"reportChart.header.udNegocio":"Business unit",
	"reportChart.header.agrInterna":"Internal classification",
	"reportChart.header.motivation":"Motivation",
	"reportChart.header.area":"Subject focus",
	"reportChart.header.ambitoGeografico":"Country",
	"reportChart.header.ES":"Spanish regions",
	"reportChart.header.ods":"SDG",
	"reportChart.contrib.dinero":"Cash",
	"reportChart.contrib.tiempo":"Time",
	"reportChart.contrib.especie":"In kind",
	"reportChart.contrib.probono":"Pro bono",
	"reportChart.contrib.costeGestion":"Management costs",
	"reportChart.header.pais":"Spain",
	"reportChart.proyectos":"Projects",
	"reportChart.iniciativas":"Initiatives",
	"reportChart.proyectosConContribucion":"Projects with contributions",
	"reportChart.areaActuacion":"Subject focus",
	"reportChart.contribucionTotal":"Total contributions",
	"reportChart.contribucionItv":"{{ITV}} contributions",
	"reportChart.porcentajeContribucionItv":"{{ITV}} contributions %",
	"reportChart.contribucionEntidad":"Entity contributions",
	"reportChart.porcentajeContribucionEntidad":"{{ITV}} contributions %",
	"reportChart.areaPrimaria":"Primary subject focus",
	"reportChart.areaSecundaria":"Secondary subject focus",
	"reportChart.odsPrimario":"As primary SDG",
	"reportChart.odsSecundario":"As secondary SDG",
	"reportChart.odsTerciarios":"As third SDG",
	"reportChart.contributions":"Contributions",
	"reportChart.efectoMultiplicador":"{{ITV}} Leverage",
	"reportChart.efectoMultiplicadorOtros":"Other collaborators leverage",
	"reportChart.Participacion1":"Participants (employees) on behalf of the entity: Outputs",
	"reportChart.Participacion2":"Participants (non-employees) on behalf of the entity: Outputs",
	"reportChart.Participacion3":"Impacts on employees from the entity",
	"reportChart.Participacion4":"Participants (employees) from other entities: Outputs",
	"reportChart.Participacion5":"Participants (non-employees) from other entities: Outputs",
	"reportChart.Participacion6":"Impact on employees from other entities",
	"reportChart.proyectosParticipacion":"Projects with contributions",
	"reportChart.iniciativasParticipacion":"Initiatives with contributions",
	"reportChart.numPersonas":"People",
	"reportChart.numHoras":"Hours",
	"reportChart.porcentaje":"%",
	"reportChart.costeHora":"Cost",
	"reportChart.emplTotal":"Total employees",
	"reportChart.emplEval":"Employees evaluated",
	"reportChart.impactoAlto":"High impact",
	"reportChart.impactoMed":"Medium impact",
	"reportChart.impactoBajo":"Low impact",
	"reportChart.impactoSinCamb":"No impact",
	"reportChart.nombreComunidad":"Regions",
	"reportChart.numProyectosEfectoMult":"Projects with leverage",
	"reportChart.numIniciativasEfectoMult":"Initiatives with leverage",
	"reportChart.cantidadEfectoMult":"Leverage",
	"reportChart.cantidadEfectoMult":"Leverage",
	"reportChart.proyectosConLogros":"Projects evaluated",
	"reportChart.iniciativasConLogros":"Initiatives evaluated",
	"reportChart.totalBeneficiarios":"Nº",
	"reportChart.beneficariosTotales":"Total beneficiaries",
	"reportChart.beneficariosEval":"Beneficiaries evaluated",
	"reportChart.totalPorGrado":"Nº",
	"reportChart.totalPorTipo":"Nº",
	"reportChart.proyectosEval":"Projects evaluated",
	"reportChart.iniciativasEval":"Initiatives evaluated",
	"reportChart.totalOrganizaciones":"Nº of organisations",
	"reportChart.organizBenefTotal":"Total nº of organisations",
	"reportChart.organizBenefEval":"Organisations evaluated",
	"reportChart.numLogros":"Nº",
	"reportChart.iniciativasSobreMA":"Environmental impacts",
	"reportChart.logros":"Outputs",
	"reportChart.impactosGrado":"Impacts - degree",
	"reportChart.impactosTipo":"Impacts - type",
	"reportChart.logrosOrganizaciones":"Outputs for beneficiary organisations",
	"reportChart.impactos":"Impacts",
	"reportChart.beneficiarios":"Beneficiaries",
	"reportChart.organizacionesBeneficiarios":"Beneficiary organisations",
	"reportChart.medioAmbiente":"Environment",
	"reportChart.logrosEntidad":"{{ITV}} Outputs",
	"reportChart.empleadosEntidad.tiempoRemunerado":"Employees - during working hours (Paid)",
	"reportChart.empleadosEntidad.tiempoNoRemunerado":"Employees - during working hours (Unpaid)",
	"reportChart.empleadosEntidad.probono":"Employees - pro bono",
	"reportChart.empleadosEntidad.costesGestion":"Employees - management costs",
	"reportChart.empleadosEntidad.participacionTotal":"Total",
	"reportChart.empleadosOtros.tiempoRemunerado":"Employees from others - during working hours (Paid)",
	"reportChart.empleadosOtros.tiempoNoRemunerado":"Employees from others - during working hours (Unpaid)",
	"reportChart.empleadosOtros.probono":"Employees from others - pro bono",
	"reportChart.empleadosOtros.costesGestion":"Employees from others - management costs",
	"reportChart.empleadosOtros.participacionTotal":"Total from other organisations",
	"reportChart.noEmpleadosEntidad.familiares":"Other volunteers (not employees) - Family, suppliers, clients, other volunteers",
	"reportChart.noEmpleadosEntidad.voluntariadoEstructural":"Other volunteers (not employees) - Structural volunteering",
	"reportChart.noEmpleadosEntidad.participacionTotal":"Total (not employees)",
	"reportChart.noEmpleadosOtros.familiares":"Other volunteers (not employees) - Family, suppliers, clients, other volunteers",
	"reportChart.noEmpleadosOtros.voluntariadoEstructural":"Other volunteers (not employees) - Structural volunteering",
	"reportChart.noEmpleadosOtros.participacionTotal":"Total (not employees)",
	"reportChart.impactoEmpleados.habilidades":"Developed new skills",
	"reportChart.impactoEmpleados.desarrollo":"Increased their personal well-being",
	"reportChart.impactoEmpleados.comportamiento":"Positive change in behaviour or attitude",
	"reportChart.efectoMultiplicador.entidad.empleadosPayrollGiving":"Employees' payroll giving",
	"reportChart.efectoMultiplicador.entidad.empleadosOtrasDonaciones":"Other cash contributions from employees",
	"reportChart.efectoMultiplicador.entidad.clientesConsumidoresOtrosCash":"Cash contributions from clients, consumers and other sources",
	"reportChart.efectoMultiplicador.entidad.empleadosEspecie":"Other in-kind contributions from employees",
	"reportChart.efectoMultiplicador.entidad.clientesConsumidoresOtrosEspecie":"In-kind contributions from clients, consumers and other sources",
	"reportChart.efectoMultiplicador.entidad.Otros":"Others (entity)",
	"reportChart.efectoMultiplicador.entidad.Total":"Total (entity)",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosPayrollGiving":"Employees from other organisations payroll giving",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosOtrasDonaciones":"Other cash contributions from employees from other organisations",
	"reportChart.efectoMultiplicador.otrosColaboradores.clientesConsumidoresOtrosCash":"Cash contributions from clients, consumers and other sources from other organisations",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosEspecie":"Other in-kind contributions from employees from other organisations",
	"reportChart.efectoMultiplicador.entidad.empleadosValorTrabajoVoluntariado":"Employees - volunteer work value",
	"reportChart.efectoMultiplicador.entidad.noEmpleadosVoluntariadoEstructural":"Non-employees - structural volunteering",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosValorTrabajoVoluntariado":"Employees other collaborators - volunteer work value",
	"reportChart.efectoMultiplicador.otrosColaboradores.noEmpleadosVoluntariadoEstructural":"Non-employees - structural volunteering",
	"reportChart.efectoMultiplicador.otrosColaboradores.clientesConsumidoresOtrosEspecie":"In-kind contributions from clients, consumers and other sources from other organisations",
	"reportChart.efectoMultiplicador.otrosColaboradores.Otros":"Others (Other organisations)",
	"reportChart.efectoMultiplicador.otrosColaboradores.Total":"Total (Other organisations)",
	"reportChart.logros.grado.sinCambios":"No changes",
	"reportChart.logros.grado.sensibilizados":"Connected as a result of an initiative",
	"reportChart.logros.grado.mejorados":"Improved as a result of an initiative",
	"reportChart.logros.grado.transformados":"Transformed as a result of an initiative",
	"reportChart.logros.tipo.comportamiento":"Experienced positive change in behaviour or attitude",
	"reportChart.logros.tipo.habilidades":"Developed new skills or an increase in their personal effectiveness",
	"reportChart.logros.tipo.calidadDeVida":"Experienced a direct positive impact on their quality of life",
	"reportChart.medioambiente.directos":"Nº of initiatives that delivered any environmental benefits",
	"reportChart.medioambiente.indirectos":"Nº of initiatives that changed people's environmental behaviour",
	"activity-history.log-tipo.create":"Create",
	"activity-history.log-tipo.read":"Read",
	"activity-history.log-tipo.update":"Update",
	"activity-history.log-tipo.delete":"Delete",
	"activity-history.log-tipo.duplicate":"Duplicate",
	"activity-history.seleccion-tipo":"Type...",
	"activity-history.seleccion-autor":"Author...",
	"activity-history.seleccion-acciones":"Actions...",
	"activity-history.seleccion-acciones-detalle":"Detailed action...",
	"activity-history.seleccion-tipo-explicacion":"Filter by type",
	"activity-history.log-item.entidad":"Entity",
	"activity-history.log-item.usuario":"User",
	"activity-history.log-item.proyecto":"Project",
	"activity-history.log-item.proyecto_adjuntos":"Project attachments",
	"activity-history.log-item.proyecto_usuarios":"Project users",
	"activity-history.log-item.iniciativa":"Initiative",
	"activity-history.log-item.iniciativa_adjuntos":"Initiative attachments",
	"activity-history.log-item.iniciativa_usuarios":"Initiative users",
	"activity-history.log-item.contribucion":"Contribution",
	"activity-history.log-item.contribucion_adjuntos":"Contribution attachments",
	"activity-history.log-item.contribucion_permisos":"Contribution users",
	"activity-history.log-item.logro":"Output",
	"activity-history.log-item.logro_adjuntos":"Output attachments",
	"activity-history.log-item.logro_permisos":"Output users",
	"activity-history.log-item.impacto":"Impact",
	"activity-history.log-item.impacto_adjuntos":"Impact attachments",
	"activity-history.log-item.impacto_permisos":"Impact users",
	"activity-history.log-item.colaborador":"Collaborator",
	"activity-history.log-item.documento":"Attachment",
	"activity-history.log-item.tipocambio":"Exchange rates",
	"activity-history.log-item.tablasalarial":"Contribution tables",
	"activity-history.dialog.title":"Activity History",
	"descripcion.demasiado.larga":"Description its too long. Max. characters are 16383",
	"rating.agrupacionIniciativaInterna":"Iniciative Internal classification",
	"reportChart.header.agrIniciativaInterna":"Initiative Internal classification",
	"filterReport.agrIniciativaInterna":"Initiative Internal classification",
	"filter.proyecto.agrupacionInternaIniciativa":"Initiative Internal classification",

	"session.autoExpire":"The session will automatically expire in",
	"session.autoExpireSeconds":"seconds.",
	"session.autoExpireContinue":"Continue session",
	"contributions.saveContribucion":"Save contribution",
	"contributions.saveContribucions":"Save contributions",
	"contributions.confirmationMessage":"You have unsaved edits. Do you wish to leave?",
	"contributions.mensajerevisar":"Review and complete the unfilled fields.",
	"rating.showAllLanguages":"Show all languages",
	"user.usuariosDisponibles":"Available Users:",
	"plan.uso.requerido.usuarios":"You must indicate the number of users",
	
	"reportChart.mapReportEfectoMul.entidad.Otros":"Others {{ITV}}",
	"reportChart.mapReportEfectoMul.entidad.Total":"Total {{ITV}}",
	
	"reportChart.mapReportEmpVolSubtitle1":"Participants (employees) on behalf of the entity",
	"reportChart.mapReportEmpVolSubtitle2":"Participants (Non-employees) on behalf of the entity",
	"reportChart.mapReportEmpVolSubtitle3":"Impacts on the entity's employees/volunteers",
	"reportChart.mapReportEmpVolSubtitle4":"Participants from other collaborators",
	"reportChart.mapReportEmpVolSubtitle5":"External participants from other collaborators",
	"reportChart.mapReportEmpVolSubtitle6":"Impacts on employees/volunteers of other collaborators",
	"reportChart.mapReportEfectoMul1":"Leverage generated by the entity",
	"reportChart.mapReportEfectoMul2":"Leverage generated by other collaborators",
	"reportChart.mapReportrescom1":"Outputs: Beneficiaries",
	"reportChart.mapReportrescom2":"Impacts - Degree: Beneficiaries",
	"reportChart.mapReportrescom3":"Impacts - Type: Beneficiaries",
	"reportChart.mapReportrescom4":"Outputs: beneficiary Organisations",
	"reportChart.mapReportrescom5":"Outputs of the beneficiary Organisations",
	"reportChart.mapReportrescom6":"Impacts: beneficiary Organisations",
	"reportChart.mapReportrescom7":"Outputs: Environmental",
	"reportChart.mapReportrescom8":"Impacts: Environmental",
	"reportChart.mapReportresent1":"Outputs: On the entity",
	"reportChart.mapReportresent2":"Impacts: On the entity",
	"reportChart.header.logeimp":"Results: Employees and Volunteers",
	"reportChart.header.efecto":"Leverage",
	"reportChart.header.rescom":"Results in the Community: Outputs and Impacts",
	"reportChart.header.resent":"Entity Results: Outputs and Impacts",
	"reportChart.header.sensibilizados":"Connected",
	"reportChart.header.mejorados":"Improved",
	"reportChart.header.transformados":"Transformed",
	"manual-descargaDatos":"https://manual.masimpact.com/en/3/navigation_menu/8/reports/1/download_data_in_excel",
	"manual-informesPantalla":"https://manual.masimpact.com/en/3/navigation_menu/8/reports/2/on-screen_reports",
	"pais.nombre.global":"Global",
	"filterReport.byInitiatives":"By initiatives",
	"pdf.download":"Download PDF",
	"initiativeform.changeproyect":"Moving project initiative",
	"contribucions.formajuridica":"Legal form"
  })
