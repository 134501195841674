angular
  .module("app")
  .constant("translations_ES", {
    "yes":"Sí",
	"no":"No",
	"logout.cerrarSesion":"Cerrar Sesión",
	"logout.preguntaCerrarSesion":"¿Desea cerrar sesión?",
	"location":"ODS_icons",
	"error.internalServerError":"Se ha producido un error. Por favor póngase en contacto con el administrador para obtener más información.",
	"err.genericError":"Se ha producido un error. Por favor póngase en contacto con el administrador para obtener más información.",
	"Bad credentials":"Error de autentificación: Usuario o contraseña incorrectos",
	"error.accessDenied":"No tienes permisos para realizar esta acción",
	"error.requiredFieldEmpty":"No han sido rellenados todos los campos requeridos",
	"error.notFoundEntity":"El elemento al que intentas acceder no existe",
	"error.noPossibleEditEntity":"No se puede editar el elemento, porque está siendo usado",
	"error.duplicateEntity":"No se puede crear el elemento, porque ya existe",
	"err.excelRequired":"No se puede guardar si no se adjunta un archivo excel",
	"err.grupoLBGHasAResposible":"El grupo LBG seleccionado ya tiene un responsable",
	"err.entidadHasAResposible":"La entidad seleccionada ya tiene un responsable",
	"err.planUsoMaxUserExceeded":"Su plan no dispone de más usuarios con el rol seleccionado",
	"err.budgetOverflow":"El presupuesto de la entidad no debe ser mayor que el presupuesto total del proyecto",
	"err.notTypeChange":"No hay tipo de cambio para aplicar a este proyecto",
	"err.notValueTypeChange":"No hay valor para tipo de cambio para este proyecto",
	"err.deleteDocument":"Error al intentar borrar un documento",
	"err.DateOutRange":"La fecha introducida está fuera del rango",
	"err.percentajeArea":"La suma de los porcentajes de área superan el 100%",
	"err.percentajeODS":"La suma de los porcentajes de ODS superan el 100%",
	"err.requiredFieldColaboration":"Los campos requeridos de la lista de colaboradores están vacíos",
	"err.FailsBeneficiaryRequirement":"El impacto no cumple los requisitos de tope de beneficiarios evaluados",
	"err.EmpEvaluatedGreaterParticipant":"Los empleados evaluados no pueden ser más que los participantes",
	"err.EntityEvaluatedGreaterParticipant":"Las entidades evaluadas no pueden ser más que las participantes",
	"err.incompleteArea":"Información del área incompleta",
	"err.incompleteODS":"Información de los Objetivos incompletos",
	"err.dateIniciativeBeforeDateProject":"La fecha de inicio de la iniciativa es anterior al inicio del proyecto",
	"err.dateIniciativeAfterDateProject":"La fecha de inicio de iniciativa es posterior a la finalización del proyecto",
	"err.NotDeleteValidContribution":"No se puede borrar una contribución que está validada",
	"err.sessionExpired":"Su sesión de usuario ha caducado, por favor vuelva a introducir sus credenciales",
	"err.NotEditValidContribution":"No se puede editar una contribución que está validada",
	"err.NotEditValidAchievement":"No se puede editar un logro que está validado",
	"err.NotEditValidImpact":"No se puede editar un impacto que está validado",
	"usuario.rol.error.coordinador":"No es posible modificar el rol de usuario. Ya existe un coordinador para ese grupo",
	"usuario.rol.error.responsable":"No es posible modificar el rol de usuario. Ya existe un responsable para esa entidad",
	"usuario.rol.error.disponibles":"No es posible modificar el rol de usuario. No hay roles disponibles en el plan de uso",
	"resumen.proyecto.no.existe":"El proyecto seleccionado no existe",
	"resumen.proyecto.usuario.no.existe":"No hay usuario en sesión",
	"agrupacion.interna.no.existe":"Agrupación interna no existe",
	"agrupacion.interna.duplicate":"Ya existe una agrupación con este nombre",
	"agrupacion.IniciativaInterna.duplicate":"Ya existe una agrupación con este nombre",
	"agrupacion.interna.requerido.nombre":"Debe indicar el nombre de la agrupación",
	"colaborador.no.existe":"El colaborador seleccionado no existe",
	"colaborador.presupuesto.no.existe":"El presupuesto del colaborador no existe",
	"colaborador.representante.no.existe":"El colaborador representante no existe",
	"colaborador.requerido.nombre":"Debe introducir el nombre del colaborador",
	"colaborador.requerido.tipo":"Debe indicar el tipo de colaborador",
	"contribucion.no.existe":"La contribución seleccionada no existe",
	"contribucion.tabla.salarial.no.existe":"La tabla de contribuciones no existe",
	"contribucion.fuera.rango":"Contribución fuera de rango",
	"contribucion.requerido.tipo":"Debe indicar el tipo de contribución",
	"contribucion.requerido.contribuyente":"Debe indicar el contribuyente",
	"contribucion.requerido.fecha":"Debe indicar la fecha de la contribución",
	"contribucion.requerido.datos.iniciativa":"No se ha indicado una iniciativa para la contribución",
	"contribucion.requerido.cantidad.y.divisa":"Debe indicar la cantidad y la divisa de la contribución",
	"contribucion.requerido.numero.persona.y.numero.horas.persona":"Debe indicar el número de personas y el total de horas de la contribución",
	"contribucion.description.255max":"máximo 255 caracteres",
	"iniciativa.no.existe":"No existe la iniciativa",
	"usuario.no.existe":"El usuario seleccionado no existe",
	"err.mailExist":"El email indicado ya existe",
	"err.usuarioExist":"Ya existe un usuario con el email indicado",
	"usuario.requerido.nombre":"Debe introducir el nombre de usuario",
	"usuario.requerido.apellidos":"Debe introducir el apellido del usuario",
	"usuario.requerido.email":"Debe introducir el email del usuario",
	"usuario.requerido.rol":"Debe seleccionar el rol del usuario",
	"usuario.requerido.entidad.alta":"Debe indicar la entidad de alta del usuario",
	"usuario.requerido.pais":"Debe indicar el país del usuario",
	"usuario.requerido.email.contacto.coordinador.grupo":"Debe indicar el email de contacto",
	"usuario.requerido.grupo.lbg":"Debe indicar el grupo LBG del coordinador",
	"proyecto.no.existe":"El proyecto seleccionado no existe",
	"entidad.no.existe":"La entidad seleccionada no existe",
	"entidad.representante.no.existe":"La entidad representante no existe",
	"entidad.representante.requerido":"Debe indicar la entidad representante",
	"entidad.alta.no.existe":"La entidad de alta no existe",
	"entidad.requerido.nombre":"Debe indicar el nombre de la entidad",
	"entidad.requerido.abreviatura":"Debe indicar la abreviatura de la entidad",
	"entidad.requerido.tipo":"Debe indicar el tipo de entidad",
	"entidad.requerido.sector":"Debe indicar el sector LBG",
	"entidad.requerido.plan.uso":"Debe indicar un plan de uso",
	"entidad.requerido.pais":"Debe indicar el país de la entidad",
	"entidad.requerido.tipo.cambio":"Debe indicar al menos un tipo de cambio",
	"entidad.requerido.grupo.lbg":"Debe indicar el grupo LBG al que pertenece la entidad",
	"impacto.no.existe":"El impacto seleccionado no existe",
	"impacto.requerido.fecha.evaluacion":"Debe indicar la fecha de evaluación",
	"impacto.requerido.datos.iniciativa":"Debe indicarse la iniciativa",
	"impacto.validacion.beneficiarios.evaluados":"El total en grado de impacto no puede superar el número de beneficiarios evaluados",
	"impacto.empleado.requerido.contribuyente":"Debe indicar un contribuyente",
	"impacto.empleado.validacion.evaluados.mayor.participantes":"Los participantes evaluados no pueden ser mayor que el número de participantes",
	"impacto.participante.requerido.entidad":"Debe indicar la entidad participante",
	"logro.no.existe":"El logro seleccionado no existe",
	"logro.requerido.fecha.evaluacion":"Debe indicar la fecha de evaluación",
	"logro.requerido.datos.iniciativa":"Debe indicar la iniciativa del logro",
	"plan.uso.no.existe":"El plan de uso seleccionado no existe",
	"plan.uso.requerido.nombre":"Debe indicar el nombre del plan de uso",
	"plan.uso.requerido.gerente":"Debe indicar el número de gerentes",
	"plan.uso.requerido.supervisor.proyecto":"Debe indicar el número de supervisores de proyecto",
	"plan.uso.requerido.supervisor.iniciativa":"Debe indicar el número de supervisores de iniciativa",
	"plan.uso.requerido.agente":"Debe indicar el número de agentes",
	"tabla.salarial.no.existe":"La tabla de contribuciones no existe",
	"tabla.salarial.requerido.nombre":"Debe introducir el nombre de la tabla de contribuciones",
	"tabla.salarial.requerido.total.coste":"Debe introducir el coste total",
	"tabla.salarial.requerido.divisa":"Debe indicar la divisa asociada al coste total",
	"tabla.salarial.requerido.agno":"Debe indicar el año",
	"tabla.salarial.requerido.agno.aplicacion":"Debe indicar el año de aplicación",
	"unidad.negocio.no.existe":"La unidad de negocio no existe",
	"unidad.negocio.requerido.nombre":"Debe introducir el nombre de la unidad de negocio",
	"ods.no.existe":"El ODS seleccionado no existe",
	"pais.no.existe":"El país seleccionado no existe",
	"pais.requerido":"Debe indicar el país",
	"divisa.no.existe":"La divisa seleccionada no existe",
	"asignacion.presupuesto.iniciativa.requerido.presupuesto":"Debe indicar un importe de presupuesto",
	"asignacion.presupuesto.iniciativa.requerido.divisa":"Debe indicar la divisa del presupuesto",
	"datos.iniciativa.requerido.nombre":"Debe indicar el nombre de la iniciativa",
	"datos.iniciativa.requerido.agrupacionIniciativaInterna":"Debe indicar la agrupación de iniciativa interna",
	"datos.iniciativa.requerido.fecha.inicio":"Debe indicar la fecha de inicio de la iniciativa",
	"datos.iniciativa.requerido.pais":"Debe indicar el país",
	"datos.proyecto.requerido.fecha.inicio":"Debe indicar la fecha de inicio del proyecto",
	"datos.proyecto.requerido.unidad":"Debe indicar la unidad de negocio para el proyecto",
	"datos.proyecto.requerido.agrupacionInterna":"Debe indicar la agrupación interna",
	"datos.proyecto.requerido.nombre":"Debe indicar el nombre del proyecto",
	"datos.proyecto.requerido.divisa.ppto.entidad":"Debe indicar la divisa del presupuesto de su entidad",
	"datos.proyecto.requerido.divisa.ppto.total":"Debe indicar la divisa del presupuesto total",
	"organizaciones.beneficiarias.requerido.nombre":"Debe indicar el nombre de la organización",
	"tipo.cambio.requerido.nombre":"Debe indicar el nombre del tipo de cambio",
	"tipo.cambio.requerido.descripcion":"Debe indicar una descripción",
	"tipo.cambio.requerido.fecha.desde":"Debe indicar la fecha desde la que entra en vigor el tipo de cambio",
	"tipo.cambio.requerido.fecha.hasta":"Debe indicar la fecha hasta la que se mantiene la vigencia del tipo de cambio",
	"tipo.cambio.requerido.grupo.lbg":"Debe indicar el grupo LBG para el que se aplicará el tipo de cambio",
	"permiso.denegado":"No tiene suficientes privilegios para ejecutar esta acción",
	"permiso.denegado.iniciativa":"No tiene suficientes privilegios para acceder a la iniciativa",
	"permiso.denegado.contribucion":"No tiene suficientes privilegios para acceder a contribuciones",
	"permiso.denegado.logro":"No tiene suficientes privilegios para acceder a logros",
	"permiso.denegado.impacto":"No tiene suficientes privilegios para acceder a impactos",
	"datos.iniciativa.validacion.fuera.rango.proyecto":"La fecha de la iniciativa debe estar comprendida entre la fecha de inicio y fin del proyecto",
	"colaboracion.iniciativa.requerido.lista.colaboradores.motivacion.propia":"Debe indicar una motivación para su entidad",
	"colaboracion.iniciativa.requerido.lista.colaboradores.grado.implicacion":"Debe indicar un el grado de implicación",
	"iniciativa.ods.requerido.porcentaje.primario":"Debe introducir el porcentaje del ODS primario",
	"iniciativa.ods.validacion.porcentaje.incompleto":"El porcentaje total de ODS debe ser del 100%",
	"iniciativa.requerido.area.primaria":"Debe indicar el área primaria",
	"iniciativa.validacion.area.porcentaje.incompleto":"El porcentaje total de áreas debe ser del 100%",
	"unidad.negocio.validacion.nombre.duplicado":"Ya existe una unidad de negocio con ese nombre",
	"tabla.salarial.requerido.divisa.coste.hora":"Debe indicar la divisa asociada al coste hora",
	"entidad.validacion.nombre.duplicado":"El nombre de la entidad ya existe",
	"contacto.email.campos.requeridos":"Todos los campos son obligatorios",
	"impacto.empleado.validacion.evaluados.mayor.participantes.actitud":"Los participantes a los que se ha evaluado la actitud no pueden ser mayor que el número de participantes",
	"impacto.empleado.validacion.evaluados.mayor.participantes.habilidad":"Los participantes a los que se ha evaluado las habilidades no pueden ser mayor que el número de participantes",
	"impacto.empleado.validacion.evaluados.mayor.participantes.desarrollo":"Los participantes a los que se ha evaluado el desarrollo personal no pueden ser mayor que el número de participantes",
	"tipo.cambio.requerido.excel.valores":"Debe adjuntar fichero excel con valores de tipo de cambio",
	"tipo.cambio.valor.excel.caracter.invalido":"El excel no se ha podido procesar por caracter invalido",
	"ADMINISTRADOR":"Administrador",
	"COORDINADOR_GRUPO":"Coordinador de grupo",
	"RESPONSABLE":"Responsable",
	"GERENTE":"Gerente",
	"SUPERVISOR_PROYECTO":"Supervisor de proyecto",
	"SUPERVISOR_INICIATIVA":"Supervisor de iniciativa",
	"AGENTE":"Agente",
	"SOLOVISTA":"Gerente sólo vista",
	"BASICO":"Básico",
	"DINERO":"Dinero",
	"ESPECIE":"Especie",
	"TIEMPO":"Tiempo",
	"PROBONO":"Probono",
	"COSTE_GESTION":"Coste de gestión",
	"ALTO":"Alto",
	"MEDIO":"Medio",
	"BAJO":"Bajo",
	"COLABORADOR":"Colaborador",
	"ORG_BENEFICIARIA":"Organización beneficiaria",
	"SI":"Sí",
	"NO":"No",
	"SIN_CAMBIOS":"Sin cambios",
	"POCO":"Poco",
	"ALGO":"Algo",
	"BASTANTE":"Bastante",
	"Nueva Entidad":"Nueva entidad",
	"achievementsform.numeroBeneficiarias":"Número de organizaciones beneficiarias",
	"achievementsform.alianzaColaboHeader":"Alianzas y colaboraciones alcanzadas",
	"achievementsform.addGrInteres":"Guardar",
	"achievementsform.deleteGrInteres":"Borrar grupo de interés",
	"achievementsform.new":"Editar Logro",
	"achievementsform.edit":"Nuevo Logro",
	"achievementsform.fechaevaluacion":"Fecha de evaluación:",
	"achievementsform.descripcion":"Descripción:",
	"achievementsform.benefiariosDirectos":"Beneficiarios directos",
	"achievementsform.numBeneficiarios":"Nº beneficiarios:",
	"achievementsform.tipo":"Tipo:",
	"achievementsform.situacion":"Situación:",
	"achievementsform.observaciones":"Observaciones:",
	"achievementsform.beneficiariosIndirectos":"Beneficiarios indirectos",
	"achievementsform.numLogrosMedioambientales":"Nº de logros medioambientales:",
	"achievementsform.organizacionesBeneficiarias":"Organizaciones beneficiarias",
	"achievementsform.tipoSimple":"Tipo",
	"achievementsform.nombreOrganizacion":"Nombre organización",
	"achievementsform.descripcionSimple":"Descripción",
	"achievementsform.beneficiariaPregunta":"¿Beneficiaria?",
	"achievementsform.addNuevaOrganizacion":"Guardar",
	"achievementsform.deleteOrganizacion":"Borrar Organización",
	"achievementsform.LogrosEntidadesParticipantes":"Logros para las entidades participantes",
	"achievementsform.logrosGrupoIntereses":"Grupos de interés alcanzados",
	"achievementsform.entidadGrupoIntereses":"Entidad",
	"achievementsform.gruposInteresAlcanzados":"Grupos de interés alcanzados",
	"achievementsform.logrosInteresNumAbrev":"Nº",
	"achievementsform.logrosInteresObservaciones":"Observaciones",
	"achievementsform.alianzasEntidad":"Entidad",
	"achievementsform.alianzasGenerando":"Generado alianzas",
	"achievementsform.alianzasNum":"Nº",
	"achievementsform.alianzasComentarios":"Observaciones",
	"achievementsform.addAlianza":"Guardar",
	"achievementsform.deleteAlianza":"Borrar Alianza",
	"achievementsform.mediosComunicacion":"Medios de comunicación",
	"achievementsform.mediosComunicacionEntidad":"Entidad",
	"achievementsform.mediosComunicacionValor":"Valor generado en medios",
	"achievementsform.mediosComunicacionDivisa":"Divisa",
	"achievementsform.mediosComunicacionObservaciones":"Observaciones",
	"achievementsform.addMedioComunicacion":"Guardar",
	"achievementsform.deleteMediosComunicacion":"Borrar medio de comunicación",
	"achievementsform.cumplimiento":"Cumplimiento de objetivos y análisis de iniciativas",
	"achievementsform.cumplimientoEntidad":"Entidad",
	"achievementsform.cumplimientoAlineacion":"Alineación con la misión",
	"achievementsform.cumplimientoObservaciones":"Observaciones",
	"achievementsform.addCumplimiento":"Guardar",
	"achievementsform.deleteCumplimiento":"Borrar Cumplimiento de objetivos",
	"achievementsform.alineacion":"Alineación de los resultados con los objetivos de la iniciativa",
	"achievementsform.eficiencia":"Ejecución del proyecto en eficiencia",
	"achievementsform.sostenibilidad":"Sostenibilidad económica",
	"achievementsform.eficacia":"Ejecución del proyecto en eficacia",
	"achievementsform.conclusionesAlcanzadas":"Conclusiones alcanzadas:",
	"achievementsform.logrosMedioambientables":"Logros Medioambientales",
	"achievementsform.ver":"Ver Logro",
	"achievementsform.guardar":"Guardar",
	"achievementsform.cancelar":"Cancelar",
	"achievementsform.Si":"Sí",
	"achievementsform.No":"No",
	"achievements.achievement":"Logro",
	"achievements.codigo":"Código",
	"achievements.fecha":"Fecha",
	"achievements.descripcion":"Descripción",
	"achievements.beneficiariosDirectos":"Beneficiarios directos",
	"achievements.beneficiariosIndirectos":"Beneficiarios indirectos",
	"achievements.organizacionesBeneficiarias":"Organizaciones beneficiarias",
	"achievements.logosAmbientales":"Logros ambientales",
	"achievements.editar":"Editar",
	"achievements.adjuntarArchivo":"Adjuntar archivo",
	"achievements.duplicar":"Duplicar",
	"achievements.devalidate":"Quitar Validación",
	"achievements.validate":"Validar",
	"achievements.validateAchievement":"Validar Logro",
	"achievements.delete":"Borrar",
	"achievements.deleteMessage":"Borrar Logro",
	"achievements.areSure":"¿Estás seguro?",
	"achievements.cancel":"Cancelar",
	"achievements.ver":"Ver",
	"breadcrumb.gestion":"GESTIÓN",
	"breadcrumb.entidades":"ENTIDADES",
	"breadcrumb.usuarios":"USUARIOS",
	"breadcrumb.planesUso":"PLANES DE USO",
	"breadcrumb.tiposCambio":"TIPOS DE CAMBIO",
	"breadcrumb.colaboradores":"COLABORADORES",
	"breadcrumb.resumen":"RESUMEN",
	"breadcrumb.contribuciones":"CONTRIBUCIONES",
	"breadcrumb.logros":"LOGROS",
	"breadcrumb.impactos":"IMPACTOS",
	"breadcrumb.iniciativas":"INICIATIVAS",
	"breadcrumb.proyectos":"PROYECTOS",
	"breadcrumb.nuevaEntidad":"Nueva Entidad",
	"breadcrumb.editarEntidad":"Editar Entidad",
	"collaboratorform.edit":"Editar Colaborador",
	"collaboratorform.new":"Nuevo Colaborador",
	"collaboratorform.nombre":"Nombre:",
	"collaboratorform.descripcion":"Descripción:",
	"collaboratorform.cif":"CIF:",
	"collaboratorform.pais":"País:",
	"collaboratorform.region":"Región:",
	"collaboratorform.tipoEntidad":"Tipo Entidad:",
	"collaboratorform.guardar":"Guardar",
	"collaboratorform.cancelar":"Cancelar",
	"collaboratorform.volver":"Volver a listado de colaboradores",
	"collaboratorform.agrupacionInternaIniciativasObligatorio":"Obligatorio rellenar el campo de Agrupación interna de iniciativas",
	"collaborator.codigo":"Código",
	"collaborator.nombre":"Nombre",
	"collaborator.descripcion":"Descripción",
	"collaborator.pais":"País",
	"collaborator.region":"Región",
	"collaborator.tipoEntidad":"Tipo de entidad",
	"collaborator.adjuntar":"Adjuntar archivo",
	"collaborator.editar":"Editar",
	"collaborator.borrar":"Borrar",
	"collaborator.errorBorrando":"Error de borrado",
	"collaborator.noPermission":"Tu usuario no tiene permiso para realizar esta acción",
	"collaborator.ok":"ok",
	"collaborator.borrarColaborador":"Borrar Colaborador",
	"collaborator.estasSeguro":"¿Estás seguro?",
	"collaborator.cancelar":"Cancelar",
	"contact.titulo":"Contacto",
	"contact.mensajeEnviadoCorrectamente":"Mensaje enviado correctamente",
	"contact.asunto":"Asunto:",
	"contact.comentario":"Comentario:",
	"contact.enviar":"Enviar",
	"contact.volver":"Volver",
	"contributions.atribucion":"Atribución",
	"contributions.codigo":"Código",
	"contributions.tipo":"Tipo",
	"contributions.contribuyente":"Contribuyente",
	"contributions.fecha":"Fecha",
	"contributions.coste":"Coste",
	"contributions.divisa":"Divisa",
	"contributions.descripcion":"Descripción",
	"contributions.aportacionesLey112018":"¿Fundación u ONL?",
	"contributions.aportacionesLey112018Tooltip":"¿Es una aportación a una Fundación/ONL?",
	"contributions.esFundacionONL":"Es una aportación a una Fundación/ONL",
	"contributions.NOesFundacionONL":"No es una aportación a una Fundación/ONL",
	"contributions.tablaSalarial":"Tabla",
	"contributions.numPersonas":"Personas",
	"contributions.horasTotales":"Horas",
	"contributions.costeHora":"Coste hora",
	"contributions.eliminar":"Eliminar",
	"contributions.archivoAdjunto":"Adjuntar archivo",
	"contributions.duplicar":"Duplicar",
	"contributions.addContribucion":"Nueva contribución",
	"contributions.sumatorio":"Sumatorio:",
	"contributions.borrarContribucion":"Borrar Contribución",
	"contributions.estasSeguro":"¿Estás seguro?",
	"contributions.estasSeguroTiempo":"Si continuas también se eliminarán los impactos asociados a esta contribución ¿Estás seguro?",
	"contributions.borrar":"Borrar",
	"contributions.cancelar":"Cancelar",
	"contributions.quitarValidacion":"Quitar validación",
	"contributions.validar":"Validar",
	"contributions.contribucion":"Contribución",
	"contributions.validarTodos":"Validar todo",
	"contributions.desvalidarTodos":"Invalidar todo",
	"contributions.validarTodas":"Validar todas",
	"contributions.desvalidarTodas":"Invalidar todas",
	"contributions.validarContribucion":"Validar Contribución",
	"contributions.invalidarContribucion":"Invalidar Contribución",
	"data.tipoEntidad":"Tipo de entidad:",
	"data.sector":"Sector:",
	"data.responsable":"Responsable:",
	"data.nombre":"Nombre:",
	"data.nombreAbreviado":"Nombre abreviado:",
	"data.plan":"PLAN",
	"data.totalUsuarios":"TOTAL usuarios:",
	"data.numResponsables":"Nº responsables:",
	"data.numGerentes":"Nº gerentes:",
	"data.numSupProy":"Nº supervisores de proyecto:",
	"data.numSupIni":"Nº supervisores de iniciativa:",
	"data.numAgentes":"Nº agentes:",
	"entityform.nombre":"Nombre:",
	"entityform.campoRequerido":"Este campo es requerido",
	"entityform.nombreAbreviado":"Nombre abreviado:",
	"entityform.cif":"CIF",
	"entityform.pais":"País:",
	"entityform.tipo":"Tipo:",
	"entityform.sectorLBG":"Sector LBG:",
	"entityform.grupoLBG":"Grupo LBG:",
	"entityform.responsable":"Responsable",
	"entityform.plan":"Plan:",
	"entityform.tipoCambio":"Tipo de cambio:",
	"entityform.activo":"Activo",
	"entityform.activoIniciativasInternas":"Activar la clasificación de iniciativas internas",
	"entityform.obligatorioAgrupacionInterna":"Agrupación interna obligatoria",
	"entityform.obligatorioAgrupacionInternaIniciativa":"Agrupación interna de iniciativas obligatoria",
	"entityform.guardar":"Guardar",
	"entityform.cancelar":"Cancelar",
	"entityform.volver":"Volver a listado de entidades",
	"entityform.isLBG":"Entidad LBG/ONLBG",
	"entityform.activityHistoryActive":"Trazabilidad (registro actividad)",
	"entityform.aportacionesLey112018":"Casilla Aportaciones Ley 11/2018",
	"entityform.activoIniciativasInternas":"Activar agrupación interna de las iniciativas",
	"entity.codigo":"Código",
	"entity.nombre":"Nombre",
	"entity.tipo":"Tipo",
	"entity.pais":"País",
	"entity.grupoLbg":"Grupo LBG",
	"entity.plan":"Plan",
	"entity.numUsuarios":"Nº usuarios",
	"entity.estado":"Estado",
	"entity.editar":"Editar",
	"entity.borrar":"Borrar",
	"entity.activar":"Activar",
	"entity.desactivar":"Desactivar",
	"entity.entidad":"Entidad",
	"entity.estasSeguro":"¿Estás seguro?",
	"entity.borrarEntidad":"Borrar Entidad",
	"entity.cancelar":"Cancelar",
	"entitytoolbar.perfil":"PERFIL",
	"entitytoolbar.datos":"DATOS",
	"entitytoolbar.clasificacion":"CLASIFICACIÓN",
	"entitytoolbar.tablasSalarial":"TABLAS DE CONTRIBUCIONES",
	"entitytoolbar.datosMin":"Datos",
	"entitytoolbar.clasificacionMin":"Clasificación",
	"entitytoolbar.tablaSalarialMin":"Tablas de contribuciones",
	"entitytoolbar.nuevaTablaSalarial":"Nueva Tabla",
	"error.mensajeNoAccion":"No puedes realizar esta accion, porque se ha producido un error en la conversion de divisas",
	"error.periodoNoCompletado":"El administrador no ha completado el periodo mínimo de cambios de divisa de USD. El periodo comprendido es desde",
	"error.hasta":"Hasta",
	"error.validation":"Error de autentificación: Usuario o contraseña incorrectos",
	"exchangerateform.edit":"Editar tipo de cambio",
	"exchangerateform.new":"Nuevo tipo de cambio",
	"exchangerateform.nombre":"Nombre:",
	"exchangerateform.descripcion":"Descripción:",
	"exchangerateform.desde":"Desde:",
	"exchangerateform.hasta":"Hasta:",
	"exchangerateform.grupoLBG":"Grupo LBG:",
	"exchangerateform.archivoExcel":"Archivo Excel:",
	"exchangerateform.guardar":"Guardar",
	"exchangerateform.cancelar":"Cancelar",
	"exchangerateform.volver":"Volver a listado de tipos de cambio",
	"exchangerate.desdeCabecera":"No se ha completado el periodo mínimo de cambios de divisa de USD. El periodo comprendido es desde 01/01/2012 hasta 31/12/2027",
	"exchangerate.hastaCabecera":"Hasta",
	"exchangerate.nombre":"Nombre",
	"exchangerate.descripcion":"Descripción",
	"exchangerate.grupoLGB":"Grupo LBG",
	"exchangerate.desde":"Desde",
	"exchangerate.hasta":"Hasta",
	"exchangerate.editar":"Editar",
	"exchangerate.borrarEntidad":"Borrar Entidad",
	"exchangerate.estasSeguro":"¿Estás seguro?",
	"exchangerate.borrar":"Borrar",
	"exchangerate.cancelar":"Cancelar",
	"forgotpassnew.recuperarPass":"Recuperar Contraseña",
	"forgotpassnew.establecerPass":"Establecer Contraseña",
	"forgotpassnew.requisitos":"Su contraseña debe tener al menos 8 caracteres y al menos una mayúscula, una minúscula y un número.",
	"forgotpassnew.newPass":"Contraseña Nueva:",
	"forgotpassnew.repetirPass":"Repetir Contraseña Nueva:",
	"forgotpassnew.guardar":"Guardar",
	"forgotpassnew.cancelar":"Cancelar",
	"forgotpassnew.passChangedOk":"Contraseña cambiada correctamente",
	"forgotpassnew.passChangedFail1":"La nueva contraseña debe tener al menos 8 caracteres una letra mayuscula otra minuscula y un digito",
	"forgotpassnew.passChangedFail2":"Las contraseñas no coinciden",
	"forgotpass.olvidoPass":"Olvidó su contraseña",
	"forgotpass.mailEnviado":"Email enviado a",
	"forgotpass.reviseBandeja":"Revise su bandeja de entrada",
	"forgotpass.email":"Email:",
	"forgotpass.guardar":"Guardar",
	"forgotpass.cancelar":"Cancelar",
	"forgotpass.mailRegistrado":"El email que has introducido no se encuentra registrado",
	"forgotpass.mailFormatoIncorrecto":"El email introducido no está en un formato correcto",
	"impactsform.edit":"Editar Impacto",
	"impactsform.new":"Nuevo Impacto",
	"impactsform.fechaEvaluacion":"Fecha de evaluación:",
	"impactsform.descripcion":"Descripción:",
	"impactsform.impactosSobreBeneficiarios":"Impactos sobre los beneficiarios",
	"impactsform.numBenefDirectos":"Nº beneficiarios directos:",
	"impactsform.numBenefDirectosEval":"Nº de beneficiarios directos evaluados:",
	"impactsform.gradoImpacto":"Grado de impacto",
	"impactsform.sensibilizados":"Sensibilizados:",
	"impactsform.num":"Nº:",
	"impactsform.observaciones":"Observaciones:",
	"impactsform.conseguidoMejora":"Conseguido una mejora:",
	"impactsform.transformados":"Transformados:",
	"impactsform.total":"Total:",
	"impactsform.tipoImpacto":"Tipo de impacto",
	"impactsform.cambioPositivo":"Cambio positivo en comportamiento:",
	"impactsform.adquiridoNuevasHabilidades":"Adquirido nuevas habilidades:",
	"impactsform.impactoPositivoCalidadVida":"Impacto positivo en calidad de vida:",
	"impactsform.medioAmbiente":"Medio ambiente",
	"impactsform.logrosMedioambientales":"Impactos medioambientales",
	"impactsform.laIniciativaHaConsegidoImpactosMedioambientales":"¿La iniciativa ha conseguido impactos medioambientales?",
	"impactsform.cambioComportamientoAmbiental":"¿Con la iniciativa cambió el comportamiento ambiental de las personas?",
	"impactsform.impactoEmpleadosVoluntarios":"Impacto sobre los empleados / voluntarios",
	"impactsform.seleccionParticipantes":"Selección de los participantes:",
	"impactsform.numParticipantes":"Nº participantes:",
	"impactsform.impactoSobreEntidadesParticipantes":"Impacto sobre las entidades participantes",
	"impactsform.entidadesAEvaluar":"Entidades a evaluar:",
	"impactsform.entidadesParticipantes":"Entidades participantes:",
	"impactsform.entidadesEvaluadas":"Entidades evaluadas:",
	"impactsform.otrosImpactosConseguidos":"Otros impactos conseguidos:",
	"impactsform.guardar":"Guardar",
	"impactsform.cancelar":"Cancelar",
	"impactsform.mejoraronHabilidadesLaborales":"¿En cuántos la actividad ha contribuido a desarrollar nuevas habilidades o mejorar las existentes, en un contexto académico, social o físico?",
	"impactsform.mejoraronDesarrolloPersonal":"¿En cuántos ha producido un cambio la iniciativa en el desarrollo personal?",
	"impactsform.tuvieronCambioPositivoComportamiento":"¿En cuántos la actividad ha contribuido a generar cambios de comportamiento que mejoren su vida? ¿La actividad les ha permitido cambiar actitudes negativas o prejuicios?",
	"impactsform.mejoradoRelacionesCGII":"¿Ha mejorado las relaciones con sus Grupos de Interés?",
	"impactsform.mejoradoSistemasGestion":"¿Ha mejorado sus sistemas de gestión? ¿Se han producido mejoras operacionales? (RRHH, Finanzas)",
	"impactsform.aumentadoCapacidadEmplearPersonal":"¿Han aumentado la capacidad para emplear más personal o el nº de voluntarios?",
	"impactsform.experimentadoMejoraServicios":"¿Han experimentado una mejora en sus servicios, productos u ofertas de nuevos?",
	"impactsform.generadoIngresos":"¿Se han generado más ingresos?",
	"impactsform.nuevasOportunidadesAlianzas":"¿Se han generado nuevas oportunidades o alianzas?",
	"impactsform.mejoraCredibilidadReputacion":"¿Ha habido una mejora en credibilidad y reputación?",
	"impactsform.mejoraVisibilidadEntidad":"¿Se ha logrado una mejora en la visibilidad de la entidad?",
	"impactsform.numEvaluados":"Nº de participantes evaluados",
	"impactsform.ver":"Ver Impacto",
	"impact.codigo":"Código",
	"impact.fecha":"Fecha",
	"impact.descripcion":"Descripción",
	"impact.beneficiariosEvaluados":"Beneficiarios evaluados",
	"impact.entidadesEvaluadas":"Entidades evaluadas",
	"impact.impactosAmbientales":"Impactos ambientales",
	"impact.editar":"Editar",
	"impact.adjuntarArchivo":"Adjuntar archivo",
	"impact.duplicar":"Duplicar",
	"impact.borrar":"Borrar",
	"impact.quitarValidacion":"Quitar Validación",
	"impact.validar":"Validar",
	"impact.impacto":"Impacto",
	"impact.estasSeguro":"¿Estás seguro?",
	"impact.cancelar":"Cancelar",
	"impact.ver":"Ver",
	"impact.validarImpacto":"Validar Impacto",
	"impact.borrarImpacto":"Borrar Impacto",
	"initiativeform.edit":"Editar Iniciativa",
	"initiativeform.new":"Nueva Iniciativa",
	"initiativeform.nombre":"Nombre:",
	"initiativeform.pais":"País:",
	"initiativeform.region":"Región:",
	"initiativeform.ciudad":"Ciudad:",
	"initiativeform.descripcion":"Descripción:",
	"initiativeform.fechaInicio":"Fecha Inicio:",
	"initiativeform.fechaFin":"Fecha Fin:",
	"initiativeform.areaActuacionPrimaria":"Área de actuación primaria:",
	"initiativeform.areaActuacionSecundaria":"Área de actuación secundaria:",
	"initiativeform.entidadesContribuyenIniciativa":"Colaboradores y entidades que contribuyen en la iniciativa",
	"initiativeform.entidades":"Entidades",
	"initiativeform.colaborador":"Colaborador",
	"initiativeform.motivacion":"Motivación",
	"initiativeform.gradoImplicacion":"Grado de implicación",
	"initiativeform.borrarColaborador":"Borrar colaborador",
	"initiativeform.addColaborador":"Guardar",
	"initiativeform.cumpleLBG":"Cumple los principios LBG / ONLBG:",
	"initiativeform.razones":"Razones:",
	"initiativeform.comentarios":"Comentarios:",
	"initiativeform.odsPrimario":"Objetivo de desarrollo (ODS) primario:",
	"initiativeform.metas":"Metas:",
	"initiativeform.odsSecundario":"Objetivo de desarrollo (ODS) secundario:",
	"initiativeform.presupuestoEntidades":"Presupuesto de entidades",
	"initiativeform.entidad":"Entidad",
	"initiativeform.presupuesto":"Presupuesto",
	"initiativeform.divisa":"Divisa",
	"initiativeform.paisOrigenFondos":"País origen de fondos",
	"initiativeform.addPresupuesto":"Guardar",
	"initiativeform.borrarPresupuesto":"Borrar Presupuesto",
	"initiativeform.asignarUsuarios":"Asignar Usuarios",
	"initiativeform.guardar":"Guardar",
	"initiativeform.guardarYSeguir":"Guardar y añadir contribuciones",
	"initiativeform.cancelar":"Cancelar",
	"initiativeform.volver":"Volver al listado de iniciativas",
	"initiativeform.verIniciativa":"Ver Iniciativa",
	"initiativeform.colaboradorPuntual":"Colaboración puntual",
	"initiativeform.socioColaborador":"Socio colaborador",
	"initiativeform.Si":"Sí",
	"initiativeform.No":"No",
	"initiativeform.agrupacionIniciativaInterna":"Agrupación interna de iniciativas",
	"initiativeform.odstitle":"Objetivos de desarrollo",
	"initiativeform.placeholder.ods":"Objetivo de desarrollo...",
	"initiativeform.placeholder.meta":"Meta...",
	"initiativeform.ods":"ODS",
	"initiativeform.meta":"Meta",
	"initiativeform.porcentaje":"Porcentaje",
	"initiativeform.borrarOds":"Borrar ODS",
	"initiativeform.addOds":"Guardar",
	"initiativeresume.resumen":"Resumen",
	"initiativeresume.numIniciativas":"Nº de iniciativas",
	"initiativeresume.contribucion":"CONTRIBUCIÓN",
	"initiativeresume.currentYear":"Año actual",
	"initiativeresume.previousYear":"Años anteriores",
	"initiativeresume.total":"Total",
	"initiativeresume.dinero":"Dinero",
	"initiativeresume.especie":"Especie",
	"initiativeresume.tiempo":"Tiempo",
	"initiativeresume.probono":"Probono",
	"initiativeresume.costesGestion":"Costes de gestión",
	"initiativeresume.contribucionTotal":"CONTRIBUCIÓN TOTAL",
	"initiativeresume.otros":"OTROS",
	"initiativeresume.otrosTotal":"OTROS TOTAL",
	"initiativeresume.efectoMultiplicador":"Efecto multiplicador",
	"initiativeresume.totalMay":"TOTAL",
	"initiativeresume.presupuesto":"Presupuesto",
	"initiativeresume.cumplimiento":"Cumplimiento",
	"initiativeresume.pendiente":"Pendiente",
	"initiativeresume.volver":"Volver",
	"initiativeassign.finalizarAsignacion":"Finalizar asignación",
	"initiativeassign.marcarTodos":"Marcar todos",
	"initiativeassign.desmarcarTodos":"Desmarcar todos",
	"initiativeassign.contribuciones":"Contribuciones",
	"initiativeassign.logros":"Logros",
	"initiativeassign.impactos":"Impactos",
	"initiativeassign.todos":"Todos",
	"initiativeassign.codigo":"Código",
	"initiativeassign.nombre":"Nombre",
	"initiativeassign.apellidos":"Apellidos",
	"initiativeassign.email":"Email",
	"initiativeassign.rol":"Rol",
	"initiativeassign.entidadRepresentada":"Entidad representada",
	"initiative.new":"Nueva Iniciativa",
	"initiative.contribucionTotal":"Contribución TOTAL",
	"initiative.contribucion":"Contribución",
	"initiative.contribuciones":"Contribuciones",
	"initiative.logros":"Logros",
	"initiative.impactos":"Impactos",
	"initiative.editar":"Editar",
	"initiative.adjuntarArchivo":"Adjuntar archivo",
	"initiative.duplicar":"Duplicar",
	"initiative.asignarUsuario":"Asignar Usuarios",
	"initiative.borrar":"Borrar",
	"initiative.codigo":"Código",
	"initiative.nombre":"Nombre",
	"initiative.fecha":"Fecha",
	"initiative.fecha_actualizacion":"Actualización",
	"initiative.pais":"País",
	"initiative.area":"Área",
	"initiative.motivacion":"Motivación",
	"initiative.lbg":"LBG",
	"initiative.total":"TOTAL",
	"initiative.mediciones":"Mediciones",
	"initiative.si":"Sí",
	"initiative.no":"No",
	"initiative.borrarProyecto":"Borrar Proyecto",
	"initiative.estasSeguro":"¿Estás seguro?",
	"initiative.cancelar":"Cancelar",
	"initiative.ver":"Ver",
	"initiative.start_date":"Fecha de inicio",
	"initiative.update_date":"Fecha de actualización",
	"login.hasOlvidadoPass":"¿Has olvidado la contraseña?",
	"login.iniciarSesion":"INICIAR SESIÓN",
	"login.userMail":"Usuario / email",
	"login.pass":"Contraseña",
	"login.usuario.desactivado":"Tu usuario actualmente se encuentra desactivado",
	"manage.usuarios":"USUARIOS",
	"manage.entidades":"ENTIDADES",
	"manage.planesUso":"PLANES DE USO",
	"manage.tiposCambio":"TIPOS DE CAMBIO",
	"manage.colaboradores":"COLABORADORES",
	"manage.nuevaEntidad":"Nueva Entidad",
	"manage.entidad":"Entidades",
	"manage.nuevoUsuario":"Nuevo Usuario",
	"manage.usuario":"Usuarios",
	"manage.nuevoPlan":"Nuevo Plan",
	"manage.planUso":"Planes de uso",
	"manage.nuevoTipoCambio":"Nuevo Tipo de Cambio",
	"manage.tipoCambio":"Tipos de cambio",
	"manage.nuevoColaborador":"Nuevo Colaborador",
	"manage.colaborador":"Colaboradores",
	"mepass.cambiarPass":"Cambiar Contraseña",
	"mepass.passCambiado":"Contraseña cambiada correctamente",
	"mepass.oldPass":"Contraseña antigua:",
	"mepass.newPass":"Contraseña Nueva:",
	"mepass.newPass2":"Repetir Contraseña Nueva:",
	"mepass.guardar":"Guardar",
	"mepass.cancelar":"Cancelar",
	"mepass.oldPassFail":"La contraseña antigua no es correcta",
	"mepass.oldPassValidate":"La nueva contraseña debe tener al menos 8 caracteres una letra mayúscula otra minuscula y un digito",
	"mepass.passNotEqual":"Las contraseñas no coinciden",
	"mepass.todosCamposObligatorios":"Todos los campos son obligatorios",
	"me.miPerfil":"Mi Perfil",
	"me.nombre":"Nombre:",
	"me.apellidos":"Apellidos:",
	"me.email":"Email:",
	"me.pais":"País:",
	"me.cambiarPass":"Cambiar Contraseña",
	"me.guardar":"Guardar",
	"me.dataChanged":"Datos cambiados correctamente",
	"measurement.contribuciones":"CONTRIBUCIONES",
	"measurement.logros":"LOGROS",
	"measurement.impactos":"IMPACTOS",
	"measurement.contribucionesMin":"Contribuciones",
	"measurement.nuevoLogro":"Nuevo Logro",
	"measurement.logrosMin":"Logros",
	"measurement.nuevoImpacto":"Nuevo Impacto",
	"measurement.impactosMin":"Impactos",
	"planform.edit":"Editar Plan",
	"planform.new":"Nuevo Plan",
	"planform.nombre":"Nombre:",
	"planform.grupoLbg":"Grupo LBG:",
	"planform.numUsuarios":"Nº de usuarios:",
	"planform.numResponsable":"Nº responsable:",
	"planform.numGerentes":"Nº gerentes:",
	"planform.numSupPro":"Nº supervisores de proyecto:",
	"planform.numSupIni":"Nº supervisores de iniciativa:",
	"planform.numAgentes":"Nº agentes:",
	"planform.numBasicos":"Nº básicos:",
	"planform.activo":"Activo",
	"planform.guardar":"Guardar",
	"planform.cancelar":"Cancelar",
	"planform.volver":"Volver a listado de planes de uso",
	"plan.edit":"Editar",
	"plan.delete":"Borrar",
	"plan.nombre":"Nombre",
	"plan.numUsuarios":"Nº usuarios",
	"plan.estado":"Estado",
	"plan.desactivar":"Desactivar",
	"plan.activar":"Activar",
	"plan.plan":"Plan",
	"plan.estasSeguro":"¿Estás seguro?",
	"plan.borrarPlan":"Borrar Plan",
	"plan.borrar":"Borrar",
	"plan.cancelar":"Cancelar",
	"proyectform.new":"Nuevo Proyecto",
	"proyectform.edit":"Editar Proyecto",
	"proyectform.nombre":"Nombre:",
	"proyectform.divisionUnidad":"División / Unidad:",
	"proyectform.agrupacionInterna":"Agrupación interna:",
	"proyectform.descripcion":"Descripción:",
	"proyectform.desde":"Desde:",
	"proyectform.hasta":"Hasta:",
	"proyectform.presupuesto":"Presupuesto",
	"proyectform.divisa":"Divisa:",
	"proyectform.presupuestoTotal":"Presupuesto total",
	"proyectform.asignarUsuarios":"Asignar Usuarios",
	"proyectform.guardar":"Guardar",
	"proyectform.cancelar":"Cancelar",
	"proyectform.volver":"Volver al listado de proyectos",
	"proyectform.mensajeError":"El presupuesto total es inferior al presupuesto de la entidad",
	"proyectform.ver":"Ver Proyecto",
	"proyectassign.finalizarAsignacion":"Finalizar la asignación",
	"proyectassign.marcarTodos":"Marcar todos",
	"proyectassign.desmarcarTodos":"Desmarcar todos",
	"proyectassign.codigo":"Código",
	"proyectassign.nombre":"Nombre",
	"proyectassign.apellidos":"Apellidos",
	"proyectassign.email":"Email",
	"proyectassign.rol":"Rol",
	"proyectassign.entidadRepresentada":"Entidad representada",
	"proyectassign.pais":"País",
	"proyectassign.seleccionar":"Seleccionar",
	"proyectassign.desactivar":"Desactivar",
	"proyectassign.activar":"Activar",
	"proyectassign.usuario":"Usuario",
	"proyectassign.estasSeguro":"¿Estás seguro?",
	"proyectassign.cancelar":"Cancelar",
	"proyect.proyectos":"PROYECTOS",
	"proyect.nuevoProyecto":"Nuevo Proyecto",
	"proyect.abiertos":"ABIERTOS",
	"proyect.cerrados":"CERRADOS",
	"proyect.todos":"TODOS",
	"proyect.fechaInicio":"Fecha de inicio",
	"proyect.divisionUnidad":"División / unidad",
	"proyect.ninguno":"Ninguno",
	"proyect.contribucionTotal":"Contribución TOTAL",
	"proyect.contribucion":"Contribución",
	"proyect.iniciativas":"Iniciativas",
	"proyect.editar":"Editar",
	"proyect.adjuntarArchivo":"Adjuntar archivo",
	"proyect.duplicar":"Duplicar",
	"proyect.abrir":"Abrir",
	"proyect.cerrar":"Cerrar",
	"proyect.asignarUsuarios":"Asignar Usuarios",
	"proyect.borrar":"Borrar",
	"proyect.codigo":"Código",
	"proyect.nombre":"Nombre",
	"proyect.fechaInicioPH":"Fecha de inicio",
	"proyect.divisionUnidadPH":"Unidad",
	"proyect.agrupacion":"Agrupación",
	"proyect.total":"TOTAL",
	"proyect.proyecto":"Proyecto",
	"proyect.estasSeguro":"¿Estás seguro?",
	"proyect.cancelar":"Cancelar",
	"proyect.proyectoCerradoNoReabre":"Un proyecto cerrado no se puede editar",
	"proyect.borrarProyecto":"Borrar Proyecto",
	"proyect.proyectoCerradoNoArchivos":"A un proyecto cerrado no se le pueden adjuntar archivos",
	"proyect.ver":"Ver",
	"proyect.abrirProyecto":"Abrir Proyecto",
	"proyect.cerrarProyecto":"Cerrar Proyecto",
	"rating.unidadAdministrativa":"Unidad administrativa o de negocio",
	"rating.nombre":"Nombre",
	"rating.agrupacionInterna":"Agrupación interna",
	"rating.agrupacionIniciativaInterna":"Agrupación interna de iniciativas",
	"rating.nuevaDivision":"Nueva división administrativa",
	"rating.nuevaAgrupacion":"Nueva agrupación interna",
	"rating.nuevaAgrupacionIniciativa":"Nueva agrupación interna de iniciativas",
	"proyectresume.resumen":"Resumen",
	"proyectresume.numIniciativas":"Nº de iniciativas",
	"proyectresume.contribucion":"CONTRIBUCIÓN",
	"proyectresume.currentYear":"Año actual",
	"proyectresume.previousYear":"Años anteriores",
	"proyectresume.total":"Total",
	"proyectresume.dinero":"Dinero",
	"proyectresume.especie":"Especie",
	"proyectresume.tiempo":"Tiempo",
	"proyectresume.probono":"Probono",
	"proyectresume.costesGestion":"Costes de gestión",
	"proyectresume.contribucionTotal":"CONTRIBUCIÓN TOTAL",
	"proyectresume.otros":"OTROS",
	"proyectresume.otrosTotal":"OTROS TOTAL",
	"proyectresume.efectoMultiplicador":"Efecto multiplicador",
	"proyectresume.totalMayus":"TOTAL",
	"proyectresume.presupuesto":"Presupuesto",
	"proyectresume.cumplimiento":"Cumplimiento",
	"proyectresume.pendiente":"Pendiente",
	"proyectresume.volver":"Volver",
	"salarytablesform.edit":"Editar tabla de contribuciones",
	"salarytablesform.new":"Nueva tabla",
	"salarytablesform.nombre":"Nombre:",
	"salarytablesform.campoRequerido":"Este campo es requerido",
	"salarytablesform.costeTotal":"Coste total",
	"salarytablesform.numPersonas":"Nº de personas",
	"salarytablesform.horas":"Horas",
	"salarytablesform.costeHora":"Coste por hora",
	"salarytablesform.applicationYear":"Año de aplicación",
	"salarytablesform.guardar":"Guardar",
	"salarytablesform.cancelar":"Cancelar",
	"salarytablesform.volver":"Volver a listado de tablas de contribuciones",
	"salarytablesform.divisa":"Divisa",
	"salarytables.descripcionPublica":"Descripción de la tabla",
	"salarytables.descripcionPrivada":"Información para gestión interna",
	"salarytables.aplicationYear":"Año aplicación",
	"salarytables.adjuntarArchivo":"Adjuntar archivo",
	"salarytables.editar":"Editar",
	"salarytables.borrar":"Borrar",
	"salarytables.codigo":"Código",
	"salarytables.nombre":"Nombre",
	"salarytables.totalCoste":"Total Coste",
	"salarytables.divisa":"Divisa",
	"salarytables.personas":"Personas",
	"salarytables.horas":"Horas",
	"salarytables.costeHora":"Coste / hora",
	"salarytables.borrarTablaSalarial":"Borrar Tabla de contribuciones",
	"salarytables.estasSeguro":"¿Estás seguro?",
	"salarytables.cancelar":"Cancelar",
	"toolbartop.home":"Home",
	"toolbartop.perfil":"Perfil",
	"toolbartop.gestion":"Gestión",
	"toolbartop.proyectos":"Proyectos",
	"toolbartop.benchmarking":"Benchmarking",
	"toolbartop.idioma":"Idioma",
	"toolbartop.moneda":"Moneda",
	"toolbartop.cerrarSesion":"Cerrar sesión",
	"toolbartop.contacto":"Contacto",
	"toolbartop.filterReport":"Informes",
	"toolbartop.reportChart":"Estadísticas",
	"uploadform.subirArchivo":"Subir archivo",
	"uploadform.archivosSubidos":"Archivos subidos:",
	"uploadform.cancelar":"Cancelar",
	"uploadform.volver":"Volver",
	"uploadform.borrarArchivo":"Borrar archivo",
	"uploadform.estasSeguro":"¿Estás seguro?",
	"uploadform.borrar":"Borrar",
	"userform.editarUsuario":"Editar Usuario",
	"userform.nuevoUsuario":"Nuevo Usuario",
	"userform.nombre":"Nombre:",
	"userform.apellidos":"Apellidos:",
	"userform.email":"Email:",
	"userform.pais":"País:",
	"userform.rol":"Rol:",
	"userform.entidadResponsableAlta":"Entidad responsable del alta:",
	"userform.entidadRepresentada":"Entidad a la que representa:",
	"userform.emailSolicitudInfo":"Email solicitud información:",
	"userform.grupoLbgCoordinador":"Grupo LBG coordinador:",
	"userform.activo":"Activo",
	"userform.guardar":"Guardar",
	"userform.cancelar":"Cancelar",
	"userform.volver":"Volver a listado de usuarios",
	"user.usuariosDisponibles":"Usuarios Disponibles:",
	"user.disponiblesGerentes":"Disponibles: Gerentes",
	"user.supervisoresProyecto":"Supervisores de Proyecto",
	"user.supIni":"Supervisores de Iniciativa",
	"user.agentes":"Agentes",
	"user.editar":"Editar",
	"user.borrar":"Borrar",
	"user.codigo":"Código",
	"user.nombre":"Nombre",
	"user.apellidos":"Apellidos",
	"user.email":"Email",
	"user.rol":"Rol",
	"user.entidadResponsable":"Entidad responsable",
	"user.entidadRepresentada":"Entidad representada",
	"user.pais":"País",
	"user.estado":"Estado",
	"user.desactivar":"Desactivar",
	"user.activar":"Activar",
	"user.usuario":"Usuario",
	"user.estasSeguro":"¿Estás seguro?",
	"user.cancelar":"Cancelar",
	"user.borrarUsuario":"Borrar Usuario",
	"confirmdelete.confirmarBorrado":"Confirmar Borrado",
	"confirmdelete.estasSeguro":"¿Está seguro?",
	"documento.size.excedido":"El tamaño del archivo es mayor al tamaño máximo: 40MB",
	"proyectclosed.alert":"No se pueden realizar acciones sobre un proyecto cerrado ni sobre sobre su contenido",
	"list.empty":"No hay elementos para mostrar",
	"list.cargando":"Cargando ...",
	"impactSelect.sinSeleccion":"Sin selección",
	"meta.ods.1.nombre.1":"1.1 Para 2030, erradicar la pobreza extrema para todas las personas en el mundo, actualmente medida por un ingreso por persona inferior a 1,25 dólares al día.",
	"meta.ods.1.nombre.2":"1.2 Para 2030, reducir al menos a la mitad la proporción de hombres, mujeres y niños y niñas de todas las edades que viven en la pobreza en todas sus dimensiones con arreglo a las definiciones nacionales.",
	"meta.ods.1.nombre.3":"1.3 Poner en práctica a nivel nacional sistemas y medidas apropiadas de protección social para todos y, para 2030, lograr una amplia cobertura de los pobres y los más vulnerables.",
	"meta.ods.1.nombre.4":"1.4 Para 2030, garantizar que todos los hombres y mujeres, en particular los pobres y los más vulnerables, tengan los mismos derechos a los recursos económicos, así como acceso a los servicios básicos, la propiedad y el control de las tierras y otros bienes, la herencia, los recursos naturales, las nuevas tecnologías y los servicios económicos, incluida la microfinanciación.",
	"meta.ods.1.nombre.5":"1.5 Para 2030, fomentar la resiliencia de los pobres y las personas que se encuentran en situaciones vulnerables y reducir su exposición y vulnerabilidad a los fenómenos extremos relacionados con el clima y a otros desastres económicos, sociales y ambientales.",
	"meta.ods.1.nombre.a":"1.a Garantizar una movilización importante de recursos procedentes de diversas fuentes, incluso mediante la mejora de la cooperación para el desarrollo, a fin de proporcionar medios suficientes y previsibles para los países en desarrollo, en particular los países menos adelantados, para poner en práctica programas y políticas encaminados a poner fin a la pobreza en todas sus dimensiones.",
	"meta.ods.1.nombre.b":"1.b Crear marcos normativos sólidos en los planos nacional, regional e internacional, sobre la base de estrategias de desarrollo en favor de los pobres que tengan en cuenta las cuestiones de género, a fin de apoyar la inversión acelerada en medidas para erradicar la pobreza",
	"meta.ods.2.nombre.1":"2.1 Para 2030, poner fin al hambre y asegurar el acceso de todas las personas, en particular los pobres y las personas en situaciones vulnerables, incluidos los lactantes, a una alimentación sana, nutritiva y suficiente durante todo el año",
	"meta.ods.2.nombre.2":"2.2 Para 2030, poner fin a todas las formas de malnutrición, incluso logrando, a más tardar en 2025, las metas convenidas internacionalmente sobre el retraso del crecimiento y la emaciación de los niños menores de 5 años, y abordar las necesidades de nutrición de las adolescentes, las mujeres embarazadas y lactantes y las personas de edad",
	"meta.ods.2.nombre.3":"2.3 Para 2030, duplicar la productividad agrícola y los ingresos de los productores de alimentos en pequeña escala, en particular las mujeres, los pueblos indígenas, los agricultores familiares, los pastores y los pescadores, entre otras cosas mediante un acceso seguro y equitativo a las tierras, a otros recursos de producción e insumos, conocimientos, servicios financieros, mercados y oportunidades para la generación de valor añadido y empleos no agrícolas",
	"meta.ods.2.nombre.4":"2.4 Para 2030, asegurar la sostenibilidad de los sistemas de producción de alimentos y aplicar prácticas agrícolas resilientes que aumenten la productividad y la producción, contribuyan al mantenimiento de los ecosistemas, fortalezcan la capacidad de adaptación al cambio climático, los fenómenos meteorológicos extremos, las sequías, las inundaciones y otros desastres, y mejoren progresivamente la calidad del suelo y la tierra",
	"meta.ods.2.nombre.5":"2.5 Para 2020, mantener la diversidad genética de las semillas, las plantas cultivadas y los animales de granja y domesticados y sus especies silvestres conexas, entre otras cosas mediante una buena gestión y diversificación de los bancos de semillas y plantas a nivel nacional, regional e internacional, y promover el acceso a los beneficios que se deriven de la utilización de los recursos genéticos y los conocimientos tradicionales y su distribución justa y equitativa, como se ha convenido internacionalmente",
	"meta.ods.2.nombre.a":"2.a Aumentar las inversiones, incluso mediante una mayor cooperación internacional, en la infraestructura rural, la investigación agrícola y los servicios de extensión, el desarrollo tecnológico y los bancos de genes de plantas y ganado a fin de mejorar la capacidad de producción agrícola en los países en desarrollo, en particular en los países menos adelantados",
	"meta.ods.2.nombre.b":"2.b Corregir y prevenir las restricciones y distorsiones comerciales en los mercados agropecuarios mundiales, entre otras cosas mediante la eliminación paralela de todas las formas de subvenciones a las exportaciones agrícolas y todas las medidas de exportación con efectos equivalentes, de conformidad con el mandato de la Ronda de Doha para el Desarrollo",
	"meta.ods.2.nombre.c":"2.c Adoptar medidas para asegurar el buen funcionamiento de los mercados de productos básicos alimentarios y sus derivados y facilitar el acceso oportuno a información sobre los mercados, en particular sobre las reservas de alimentos, a fin de ayudar a limitar la extrema volatilidad de los precios de los alimentos",
	"meta.ods.3.nombre.1":"3.1 Para 2030, reducir la tasa mundial de mortalidad materna a menos de 70 por cada 100.000 nacidos vivos",
	"meta.ods.3.nombre.2":"3.2 Para 2030, poner fin a las muertes evitables de recién nacidos y de niños menores de 5 años, logrando que todos los países intenten reducir la mortalidad neonatal al menos hasta 12 por cada 1.000 nacidos vivos, y la mortalidad de niños menores de 5 años al menos hasta 25 por cada 1.000 nacidos vivos",
	"meta.ods.3.nombre.3":"3.3 Para 2030, poner fin a las epidemias del SIDA, la tuberculosis, la malaria y las enfermedades tropicales desatendidas y combatir la hepatitis, las enfermedades transmitidas por el agua y otras enfermedades transmisibles",
	"meta.ods.3.nombre.4":"3.4 Para 2030, reducir en un tercio la mortalidad prematura por enfermedades no transmisibles mediante la prevención y el tratamiento y promover la salud mental y el bienestar",
	"meta.ods.3.nombre.5":"3.5 Fortalecer la prevención y el tratamiento del abuso de sustancias adictivas, incluido el uso indebido de estupefacientes y el consumo nocivo de alcohol",
	"meta.ods.3.nombre.6":"3.6 Para 2020, reducir a la mitad el número de muertes y lesiones causadas por accidentes de tráfico en el mundo",
	"meta.ods.3.nombre.7":"3.7 Para 2030, garantizar el acceso universal a los servicios de salud sexual y reproductiva, incluidos los de planificación de la familia, información y educación, y la integración de la salud reproductiva en las estrategias y los programas nacionales",
	"meta.ods.3.nombre.8":"3.8 Lograr la cobertura sanitaria universal, en particular la protección contra los riesgos financieros, el acceso a servicios de salud esenciales de calidad y el acceso a medicamentos y vacunas seguros, eficaces, asequibles y de calidad para todos",
	"meta.ods.3.nombre.9":"3.9 Para 2030, reducir sustancialmente el número de muertes y enfermedades producidas por productos químicos peligrosos y la contaminación del aire, el agua y el suelo",
	"meta.ods.3.nombre.a":"3.a Fortalecer la aplicación del Convenio Marco de la Organización Mundial de la Salud para el Control del Tabaco en todos los países",
	"meta.ods.3.nombre.b":"3.b Apoyar las actividades de investigación y desarrollo de vacunas y medicamentos para las enfermedades transmisibles y no transmisibles que afectan primordialmente a los países en desarrollo y facilitar el acceso a medicamentos y vacunas esenciales asequibles de conformidad con la Declaración de Doha relativa al Acuerdo sobre los ADPIC y la Salud Pública, en la que se afirma el derecho de los países en desarrollo a utilizar al máximo las disposiciones del Acuerdo sobre los Aspectos de los Derechos de Propiedad Intelectual Relacionados con el Comercio en lo relativo a la flexibilidad para proteger la salud pública y, en particular, proporcionar acceso a los medicamentos para todos",
	"meta.ods.3.nombre.c":"3.c Aumentar sustancialmente la financiación de la salud y la contratación, el desarrollo, la capacitación y la retención del personal sanitario en los países en desarrollo, especialmente en los países menos adelantados y los pequeños Estados insulares en desarrollo",
	"meta.ods.3.nombre.d":"3.d Reforzar la capacidad de todos los países, en particular los países en desarrollo, en materia de alerta temprana, reducción de riesgos y gestión de los riesgos para la salud nacional y mundial",
	"meta.ods.4.nombre.1":"4.1 De aquí a 2030, asegurar que todas las niñas y todos los niños terminen la enseñanza primaria y secundaria, que ha de ser gratuita, equitativa y de calidad y producir resultados de aprendizaje pertinentes y efectivos",
	"meta.ods.4.nombre.2":"4.2 De aquí a 2030, asegurar que todas las niñas y todos los niños tengan acceso a servicios de atención y desarrollo en la primera infancia y educación preescolar de calidad, a fin de que estén preparados para la enseñanza primaria",
	"meta.ods.4.nombre.3":"4.3 De aquí a 2030, asegurar el acceso igualitario de todos los hombres y las mujeres a una formación técnica, profesional y superior de calidad, incluida la enseñanza universitaria",
	"meta.ods.4.nombre.4":"4.4 De aquí a 2030, aumentar considerablemente el número de jóvenes y adultos que tienen las competencias necesarias, en particular técnicas y profesionales, para acceder al empleo, el trabajo decente y el emprendimiento",
	"meta.ods.4.nombre.5":"4.5 De aquí a 2030, eliminar las disparidades de género en la educación y asegurar el acceso igualitario a todos los niveles de la enseñanza y la formación profesional para las personas vulnerables, incluidas las personas con discapacidad, los pueblos indígenas y los niños en situaciones de vulnerabilidad",
	"meta.ods.4.nombre.6":"4.6 De aquí a 2030, asegurar que todos los jóvenes y una proporción considerable de los adultos, tanto hombres como mujeres, estén alfabetizados y tengan nociones elementales de aritmética",
	"meta.ods.4.nombre.7":"4.7 De aquí a 2030, asegurar que todos los alumnos adquieran los conocimientos teóricos y prácticos necesarios para promover el desarrollo sostenible, entre otras cosas mediante la educación para el desarrollo sostenible y los estilos de vida sostenibles, los derechos humanos, la igualdad de género, la promoción de una cultura de paz y no violencia, la ciudadanía mundial y la valoración de la diversidad cultural y la contribución de la cultura al desarrollo sostenible",
	"meta.ods.4.nombre.a":"4.a Construir y adecuar instalaciones escolares que respondan a las necesidades de los niños y las personas discapacitadas y tengan en cuenta las cuestiones de género, y que ofrezcan entornos de aprendizaje seguros, no violentos, inclusivos y eficaces para todos",
	"meta.ods.4.nombre.b":"4.b De aquí a 2020, aumentar considerablemente a nivel mundial el número de becas disponibles para los países en desarrollo, en particular los países menos adelantados, los pequeños Estados insulares en desarrollo y los países africanos, a fin de que sus estudiantes puedan matricularse en programas de enseñanza superior, incluidos programas de formación profesional y programas técnicos, científicos, de ingeniería y de tecnología de la información y las comunicaciones, de países desarrollados y otros países en desarrollo",
	"meta.ods.4.nombre.c":"4.c De aquí a 2030, aumentar considerablemente la oferta de docentes calificados, incluso mediante la cooperación internacional para la formación de docentes en los países en desarrollo, especialmente los países menos adelantados y los pequeños Estados insulares en desarrollo",
	"meta.ods.5.nombre.1":"5.1 Poner fin a todas las formas de discriminación contra todas las mujeres y las niñas en todo el mundo",
	"meta.ods.5.nombre.2":"5.2 Eliminar todas las formas de violencia contra todas las mujeres y las niñas en los ámbitos público y privado",
	"meta.ods.5.nombre.3":"5.3 Eliminar todas las prácticas nocivas, como el matrimonio infantil, precoz y forzado y la mutilación genital femenina",
	"meta.ods.5.nombre.4":"5.4 Reconocer y valorar los cuidados y el trabajo doméstico no remunerados mediante servicios públicos, infraestructuras y políticas de protección social, y promoviendo la responsabilidad compartida en el hogar y la familia, según proceda en cada país",
	"meta.ods.5.nombre.5":"5.5 Velar por la participación plena y efectiva de las mujeres y la igualdad de oportunidades de liderazgo a todos los niveles de la adopción de decisiones en la vida política, económica y pública",
	"meta.ods.5.nombre.6":"5.6 Asegurar el acceso universal a la salud sexual y reproductiva y los derechos reproductivos según lo acordado de conformidad con el Programa de Acción de la Conferencia Internacional sobre la Población y el Desarrollo, la Plataforma de Acción de Beijing y los documentos finales de sus conferencias de examen",
	"meta.ods.5.nombre.a":"5.a Emprender reformas que otorguen a las mujeres igualdad de derechos a los recursos económicos, así como acceso a la propiedad y al control de la tierra y otros tipos de bienes, los servicios financieros, la herencia y los recursos naturales, de conformidad con las leyes nacionales",
	"meta.ods.5.nombre.b":"5.b Mejorar el uso de la tecnología instrumental, en particular la tecnología de la información y las comunicaciones, para promover el empoderamiento de la mujer",
	"meta.ods.5.nombre.c":"5.c Aprobar y fortalecer políticas acertadas y leyes aplicables para promover la igualdad entre los géneros y el empoderamiento de las mujeres y las niñas a todos los niveles",
	"meta.ods.6.nombre.1":"6.1 De aquí a 2030, lograr el acceso universal y equitativo al agua potable a un precio asequible para todos",
	"meta.ods.6.nombre.2":"6.2 De aquí a 2030, lograr el acceso a servicios de saneamiento e higiene adecuados y equitativos para todos y poner fin a la defecación al aire libre, prestando especial atención a las necesidades de las mujeres y las niñas y las personas en situaciones de vulnerabilidad",
	"meta.ods.6.nombre.3":"6.3 De aquí a 2030, mejorar la calidad del agua reduciendo la contaminación, eliminando el vertimiento y minimizando la emisión de productos químicos y materiales peligrosos, reduciendo a la mitad el porcentaje de aguas residuales sin tratar y aumentando considerablemente el reciclado y la reutilización sin riesgos a nivel mundial",
	"meta.ods.6.nombre.4":"6.4 De aquí a 2030, aumentar considerablemente el uso eficiente de los recursos hídricos en todos los sectores y asegurar la sostenibilidad de la extracción y el abastecimiento de agua dulce para hacer frente a la escasez de agua y reducir considerablemente el número de personas que sufren falta de agua",
	"meta.ods.6.nombre.5":"6.5 De aquí a 2030, implementar la gestión integrada de los recursos hídricos a todos los niveles, incluso mediante la cooperación transfronteriza, según proceda",
	"meta.ods.6.nombre.6":"6.6 De aquí a 2020, proteger y restablecer los ecosistemas relacionados con el agua, incluidos los bosques, las montañas, los humedales, los ríos, los acuíferos y los lagos",
	"meta.ods.6.nombre.a":"6.a Ampliar la cooperación internacional y el apoyo prestado a los países en desarrollo para la creación de capacidad en actividades y programas relativos al agua y el saneamiento",
	"meta.ods.6.nombre.b":"6.b Apoyar y fortalecer la participación de las comunidades locales en la mejora de la gestión del agua y el saneamiento",
	"meta.ods.7.nombre.1":"7.1 De aquí a 2030, garantizar el acceso universal a servicios energéticos asequibles, fiables y modernos",
	"meta.ods.7.nombre.2":"7.2 De aquí a 2030, aumentar considerablemente la proporción de energía renovable en el conjunto de fuentes energéticas",
	"meta.ods.7.nombre.3":"7.3 De aquí a 2030, duplicar la tasa mundial de mejora de la eficiencia energética",
	"meta.ods.7.nombre.a":"7.a De aquí a 2030, aumentar la cooperación internacional para facilitar el acceso a la investigación y la tecnología relativas a la energía limpia, incluidas las fuentes renovables, la eficiencia energética y las tecnologías avanzadas y menos contaminantes de combustibles fósiles, y promover la inversión en infraestructura energética y tecnologías limpias",
	"meta.ods.7.nombre.b":"7.b De aquí a 2030, ampliar la infraestructura y mejorar la tecnología para prestar servicios energéticos modernos y sostenibles para todos en los países en desarrollo, en particular los países menos adelantados, los pequeños Estados insulares en desarrollo y los países en desarrollo sin litoral, en consonancia con sus respectivos programas de apoyo",
	"meta.ods.8.nombre.1":"8.1 Mantener el crecimiento económico per capita de conformidad con las circunstancias nacionales",
	"meta.ods.8.nombre.2":"8.2 Lograr niveles más elevados de productividad económica mediante la diversificación, la modernización tecnológica y la innovación",
	"meta.ods.8.nombre.3":"8.3 Promover políticas orientadas al desarrollo que apoyen las actividades productivas, la creación de empleo decente, el emprendimiento, la creatividad y la innovación, y alentar la oficialización y el crecimiento de las microempresas y las pequeñas y medianas empresas, entre otras cosas mediante el acceso a servicios financieros",
	"meta.ods.8.nombre.4":"8.4 Mejorar progresivamente, de aquí a 2030, la producción y el consumo eficientes de los recursos mundiales y procurar desvincular el crecimiento económico de la degradación del medio ambiente, conforme al Marco Decenal de Programas sobre modalidades de Consumo y Producción Sostenibles, empezando por los países desarrollados",
	"meta.ods.8.nombre.5":"8.5 De aquí a 2030, lograr el empleo pleno y productivo y el trabajo decente para todas las mujeres y los hombres, incluidos los jóvenes y las personas con discapacidad, así como la igualdad de remuneración por trabajo de igual valor",
	"meta.ods.8.nombre.6":"8.6 De aquí a 2020, reducir considerablemente la proporción de jóvenes que no están empleados y no cursan estudios ni reciben capacitación",
	"meta.ods.8.nombre.7":"8.7 Adoptar medidas inmediatas y eficaces para erradicar el trabajo forzoso, poner fin a las formas modernas de esclavitud y la trata de seres humanos y asegurar la prohibición y eliminación de las peores formas de trabajo infantil, incluidos el reclutamiento y la utilización de niños soldados",
	"meta.ods.8.nombre.8":"8.8 Proteger los derechos laborales y promover un entorno de trabajo seguro y protegido para todos los trabajadores, incluidos los trabajadores migrantes, en particular las mujeres migrantes y las personas con empleos precarios",
	"meta.ods.8.nombre.9":"8.9 De aquí a 2030, elaborar y poner en práctica políticas encaminadas a promover un turismo sostenible que cree puestos de trabajo y promueva la cultura y los productos locales",
	"meta.ods.8.nombre.10":"8.10 Fortalecer la capacidad de las instituciones financieras nacionales para alentar y ampliar el acceso a los servicios bancarios, financieros y de seguros para todos",
	"meta.ods.8.nombre.a":"8.a Aumentar el apoyo a la iniciativa de ayuda para el comercio en los países en desarrollo, en particular los países menos adelantados, incluso mediante el Marco Integrado Mejorado para la Asistencia Técnica a los Países Menos Adelantados en Materia de Comercio",
	"meta.ods.8.nombre.b":"8.b De aquí a 2020, desarrollar y poner en marcha una estrategia mundial para el empleo de los jóvenes y aplicar el Pacto Mundial para el Empleo de la Organización Internacional del Trabajo",
	"meta.ods.9.nombre.1":"9.1 Desarrollar infraestructuras fiables, sostenibles, resilientes y de calidad, incluidas infraestructuras regionales y transfronterizas, para apoyar el desarrollo económico y el bienestar humano, con especial hincapié en el acceso equitativo y asequible para todos",
	"meta.ods.9.nombre.2":"9.2 Promover una industrialización inclusiva y sostenible y aumentar de manera significativa la contribución de la industria al empleo y al producto interno bruto y duplicar esa contribución en los países menos adelantados",
	"meta.ods.9.nombre.3":"9.3 Aumentar el acceso de las pequeñas empresas industriales y otras empresas, en particular en los países en desarrollo, a los servicios financieros, incluido el acceso a créditos asequibles, y su integración en las cadenas de valor y los mercados",
	"meta.ods.9.nombre.4":"9.4 Mejorar la infraestructura y reajustar las industrias para que sean sostenibles, usando los recursos con mayor eficacia y promoviendo la adopción de tecnologías y procesos industriales limpios y ambientalmente racionales",
	"meta.ods.9.nombre.5":"9.5 Aumentar la investigación científica y mejorar la capacidad tecnológica de los sectores industriales de todos los países, en particular los países en desarrollo, entre otras cosas fomentando la innovación y aumentando considerablemente, de aquí a 2030, el número de personas que trabajan en investigación y desarrollo por millón de habitantes y los gastos de los sectores público y privado en investigación y desarrollo",
	"meta.ods.9.nombre.a":"9.a Facilitar el desarrollo de infraestructuras sostenibles y resilientes en los países en desarrollo con un mayor apoyo financiero, tecnológico y técnico",
	"meta.ods.9.nombre.b":"9.b Apoyar el desarrollo de tecnologías nacionales, la investigación y la innovación en los países en desarrollo",
	"meta.ods.9.nombre.c":"9.c Aumentar de forma significativa el acceso a la tecnología de la información y las comunicaciones y esforzarse por facilitar el acceso universal y asequible a Internet en los países menos adelantados",
	"meta.ods.10.nombre.1":"10.1 De aquí a 2030, lograr progresivamente y mantener el crecimiento de los ingresos del 40% más pobre de la población a una tasa superior a la media nacional",
	"meta.ods.10.nombre.2":"10.2 De aquí a 2030, potenciar y promover la inclusión social, económica y política de todas las personas, independientemente de su edad, sexo, discapacidad, raza, etnia, origen, religión o situación económica u otra condición",
	"meta.ods.10.nombre.3":"10.3 Garantizar la igualdad de oportunidades y reducir la desigualdad de los resultados, en particular mediante la eliminación de las leyes, políticas y prácticas discriminatorias y la promoción de leyes, políticas y medidas adecuadas a ese respecto",
	"meta.ods.10.nombre.4":"10.4 Adoptar políticas, en especial fiscales, salariales y de protección social, y lograr progresivamente una mayor igualdad",
	"meta.ods.10.nombre.5":"10.5 Mejorar la reglamentación y vigilancia de las instituciones y los mercados financieros mundiales y fortalecer la aplicación de esa reglamentación",
	"meta.ods.10.nombre.6":"10.6 Asegurar una mayor representación e intervención de los países en desarrollo en las decisiones adoptadas por las instituciones económicas y financieras internacionales para aumentar la eficacia, fiabilidad, rendición de cuentas y legitimidad de esas instituciones",
	"meta.ods.10.nombre.7":"10.7 Facilitar la migración y la movilidad ordenadas, seguras, regulares y responsables de las personas, entre otras cosas mediante la aplicación de políticas migratorias planificadas y bien gestionadas",
	"meta.ods.10.nombre.a":"10.a Aplicar el principio del trato especial y diferenciado para los países en desarrollo, en particular los países menos adelantados, de conformidad con los acuerdos de la Organización Mundial del Comercio",
	"meta.ods.10.nombre.b":"10.b Fomentar la asistencia oficial para el desarrollo y las corrientes financieras, incluida la inversión extranjera directa, para los Estados con mayores necesidades, en particular los países menos adelantados, los países africanos, los pequeños Estados insulares en desarrollo y los países en desarrollo sin litoral, en consonancia con sus planes y programas nacionales",
	"meta.ods.10.nombre.c":"10.c De aquí a 2030, reducir a menos del 3% los costos de transacción de las remesas de los migrantes y eliminar los corredores de remesas con un costo superior al 5%",
	"meta.ods.11.nombre.1":"11.1 De aquí a 2030, asegurar el acceso de todas las personas a viviendas y servicios básicos adecuados, seguros y asequibles y mejorar los barrios marginales",
	"meta.ods.11.nombre.2":"11.2 De aquí a 2030, proporcionar acceso a sistemas de transporte seguros, asequibles, accesibles y sostenibles para todos y mejorar la seguridad vial, en particular mediante la ampliación del transporte público, prestando especial atención a las necesidades de las personas en situación de vulnerabilidad, las mujeres, los niños, las personas con discapacidad y las personas de edad",
	"meta.ods.11.nombre.3":"11.3 De aquí a 2030, aumentar la urbanización inclusiva y sostenible y la capacidad para la planificación y la gestión participativas, integradas y sostenibles de los asentamientos humanos en todos los países",
	"meta.ods.11.nombre.4":"11.4 Redoblar los esfuerzos para proteger y salvaguardar el patrimonio cultural y natural del mundo",
	"meta.ods.11.nombre.5":"11.5 De aquí a 2030, reducir significativamente el número de muertes causadas por los desastres, incluidos los relacionados con el agua, y de personas afectadas por ellos, y reducir considerablemente las pérdidas económicas directas provocadas por los desastres en comparación con el producto interno bruto mundial, haciendo especial hincapié en la protección de los pobres y las personas en situaciones de vulnerabilidad",
	"meta.ods.11.nombre.6":"11.6 De aquí a 2030, reducir el impacto ambiental negativo per capita de las ciudades, incluso prestando especial atención a la calidad del aire y la gestión de los desechos municipales y de otro tipo",
	"meta.ods.11.nombre.7":"11.7 De aquí a 2030, proporcionar acceso universal a zonas verdes y espacios públicos seguros, inclusivos y accesibles, en particular para las mujeres y los niños, las personas de edad y las personas con discapacidad",
	"meta.ods.11.nombre.a":"11.a Apoyar los vínculos económicos, sociales y ambientales positivos entre las zonas urbanas, periurbanas y rurales mediante el fortalecimiento de la planificación del desarrollo nacional y regional",
	"meta.ods.11.nombre.b":"11.b De aquí a 2020, aumentar considerablemente el número de ciudades y asentamientos humanos que adoptan e implementan políticas y planes integrados para promover la inclusión, el uso eficiente de los recursos, la mitigación del cambio climático y la adaptación a él y la resiliencia ante los desastres, y desarrollar y poner en práctica, en consonancia con el Marco de Sendai para la Reducción del Riesgo de Desastres 2015-2030, la gestión integral de los riesgos de desastre a todos los niveles",
	"meta.ods.11.nombre.c":"11.c Proporcionar apoyo a los países menos adelantados para que puedan construir edificios sostenibles y resilientes utilizando materiales locales",
	"meta.ods.12.nombre.1":"12.1 Aplicar el Marco Decenal de Programas sobre Modalidades de Consumo y Producción Sostenibles, con la participación de todos los países y bajo el liderazgo de los países desarrollados, teniendo en cuenta el grado de desarrollo y las capacidades de los países en desarrollo",
	"meta.ods.12.nombre.2":"12.2 De aquí a 2030, lograr la gestión sostenible y el uso eficiente de los recursos naturales",
	"meta.ods.12.nombre.3":"12.3 De aquí a 2030, reducir a la mitad el desperdicio de alimentos per capita mundial en la venta al por menor y a nivel de los consumidores y reducir las pérdidas de alimentos en las cadenas de producción y suministro, incluidas las pérdidas posteriores a la cosecha",
	"meta.ods.12.nombre.4":"12.4 De aquí a 2020, lograr la gestión ecológicamente racional de los productos químicos y de todos los desechos a lo largo de su ciclo de vida, de conformidad con los marcos internacionales convenidos, y reducir significativamente su liberación a la atmósfera, el agua y el suelo a fin de minimizar sus efectos adversos en la salud humana y el medio ambiente",
	"meta.ods.12.nombre.5":"12.5 De aquí a 2030, reducir considerablemente la generación de desechos mediante actividades de prevención, reducción, reciclado y reutilización",
	"meta.ods.12.nombre.6":"12.6 Alentar a las empresas, en especial las grandes empresas y las empresas transnacionales, a que adopten prácticas sostenibles e incorporen información sobre la sostenibilidad en su ciclo de presentación de informes",
	"meta.ods.12.nombre.7":"12.7 Promover prácticas de contratación pública que sean sostenibles, de conformidad con las políticas y prioridades nacionales",
	"meta.ods.12.nombre.8":"12.8 Velar por que las personas de todo el mundo tengan información y conocimientos pertinentes para el desarrollo sostenible y los estilos de vida en armonía con la naturaleza",
	"meta.ods.12.nombre.a":"12.a Soporte países en desarrollo para fortalecer su capacidad científica y tecnológica para avanzar hacia modelos más sostenibles de consumo y producción",
	"meta.ods.12.nombre.b":"12.b Elaborar y aplicar instrumentos que permitan seguir de cerca los efectos en el desarrollo sostenible con miras a lograr un turismo sostenible que cree puestos de trabajo y promueva la cultura y los productos locales",
	"meta.ods.12.nombre.c":"12.c Racionalizar los subsidios ineficientes a los combustibles fósiles que fomentan el consumo antieconómico eliminando las distorsiones del mercado, de acuerdo con las circunstancias nacionales, incluso mediante la reestructuración de los sistemas tributarios y la eliminación gradual de los subsidios perjudiciales, cuando existan, para reflejar su impacto ambiental, teniendo plenamente en cuenta las necesidades y condiciones específicas de los países en desarrollo y minimizando los posibles efectos adversos en su desarrollo, de manera que se proteja a los pobres y a las comunidades afectadas",
	"meta.ods.13.nombre.1":"13.1 Fortalecer la resiliencia y la capacidad de adaptación a los riesgos relacionados con el clima y los desastres naturales en todos los países",
	"meta.ods.13.nombre.2":"13.2 Incorporar medidas relativas al cambio climático en las políticas, estrategias y planes nacionales",
	"meta.ods.13.nombre.3":"13.3 Mejorar la educación, la sensibilización y la capacidad humana e institucional en relación con la mitigación del cambio climático, la adaptación a él, la reducción de sus efectos y la alerta temprana",
	"meta.ods.13.nombre.a":"13.a Cumplir el compromiso de los países desarrollados que son partes en la Convención Marco de las Naciones Unidas sobre el Cambio Climático de lograr para el año 2020 el objetivo de movilizar conjuntamente 100.000 millones de dólares anuales procedentes de todas las fuentes a fin de atender las necesidades de los países en desarrollo respecto de la adopción de medidas concretas de mitigación y la transparencia de su aplicación, y poner en pleno funcionamiento el Fondo Verde para el Clima capitalizándolo lo antes posible",
	"meta.ods.13.nombre.b":"13.b Promover mecanismos para aumentar la capacidad para la planificación y gestión eficaces en relación con el cambio climático en los países menos adelantados y los pequeños Estados insulares en desarrollo, haciendo particular hincapié en las mujeres, los jóvenes y las comunidades locales y marginadas",
	"meta.ods.14.nombre.1":"14.1 De aquí a 2025, prevenir y reducir significativamente la contaminación marina de todo tipo, en particular la producida por actividades realizadas en tierra, incluidos los detritos marinos y la polución por nutrientes",
	"meta.ods.14.nombre.2":"14.2 Gestionar y proteger de manera sostenible los ecosistemas marinos y costeros con miras a evitar efectos nocivos importantes, incluso mediante el fortalecimiento de su resiliencia, y adoptar medidas para restaurarlos con objeto de restablecer la salud y la productividad de los océanos",
	"meta.ods.14.nombre.3":"14.3 Reducir al mínimo los efectos de la acidificación de los océanos y hacerles frente, incluso mediante la intensificación de la cooperación científica a todos los niveles",
	"meta.ods.14.nombre.4":"14.4 De aquí a 2020, reglamentar eficazmente la explotación pesquera y poner fin a la pesca excesiva, la pesca ilegal, no declarada y no reglamentada y las prácticas pesqueras destructivas, y aplicar planes de gestión con fundamento científico a fin de restablecer las poblaciones de peces en el plazo más breve posible, al menos alcanzando niveles que puedan producir el máximo rendimiento sostenible de acuerdo con sus características biológicas",
	"meta.ods.14.nombre.5":"14.5 De aquí a 2020, conservar al menos el 10% de las zonas costeras y marinas, de conformidad con las leyes nacionales y el derecho internacional y sobre la base de la mejor información científica disponible",
	"meta.ods.14.nombre.6":"14.6 De aquí a 2020, prohibir ciertas formas de subvenciones a la pesca que contribuyen a la sobrecapacidad y la pesca excesiva, eliminar las subvenciones que contribuyen a la pesca ilegal, no declarada y no reglamentada y abstenerse de introducir nuevas subvenciones de esa índole, reconociendo que la negociación sobre las subvenciones a la pesca en el marco de la Organización Mundial del Comercio debe incluir un trato especial y diferenciado, apropiado y efectivo para los países en desarrollo y los países menos adelantados",
	"meta.ods.14.nombre.7":"14.7 De aquí a 2030, aumentar los beneficios económicos que los pequeños Estados insulares en desarrollo y los países menos adelantados obtienen del uso sostenible de los recursos marinos, en particular mediante la gestión sostenible de la pesca, la acuicultura y el turismo",
	"meta.ods.14.nombre.a":"14.a Aumentar los conocimientos científicos, desarrollar la capacidad de investigación y transferir tecnología marina, teniendo en cuenta los Criterios y Directrices para la Transferencia de Tecnología Marina de la Comisión Oceanográfica Intergubernamental, a fin de mejorar la salud de los océanos y potenciar la contribución de la biodiversidad marina al desarrollo de los países en desarrollo, en particular los pequeños Estados insulares en desarrollo y los países menos adelantados",
	"meta.ods.14.nombre.b":"14.b Facilitar el acceso de los pescadores artesanales en pequeña escala a los recursos marinos y los mercados",
	"meta.ods.14.nombre.c":"14.c Mejorar la conservación y el uso sostenible de los océanos y sus recursos aplicando el derecho internacional reflejado en la Convención de las Naciones Unidas sobre el Derecho del Mar, que constituye el marco jurídico para la conservación y la utilización sostenible de los océanos y sus recursos, como se recuerda en el párrafo 158 del documento “El futuro que queremos”",
	"meta.ods.15.nombre.1":"15.1 Para 2020, velar por la conservación, el restablecimiento y el uso sostenible de los ecosistemas terrestres y los ecosistemas interiores de agua dulce y los servicios que proporcionan, en particular los bosques, los humedales, las montañas y las zonas áridas, en consonancia con las obligaciones contraídas en virtud de acuerdos internacionales",
	"meta.ods.15.nombre.2":"15.2 Para 2020, promover la gestión sostenible de todos los tipos de bosques, poner fin a la deforestación, recuperar los bosques degradados e incrementar la forestación y la reforestación a nivel mundial",
	"meta.ods.15.nombre.3":"15.3 Para 2030, luchar contra la desertificación, rehabilitar las tierras y los suelos degradados, incluidas las tierras afectadas por la desertificación, la sequía y las inundaciones, y procurar lograr un mundo con una degradación neutra del suelo",
	"meta.ods.15.nombre.4":"15.4 Para 2030, velar por la conservación de los ecosistemas montañosos, incluida su diversidad biológica, a fin de mejorar su capacidad de proporcionar beneficios esenciales para el desarrollo sostenible",
	"meta.ods.15.nombre.5":"15.5 Adoptar medidas urgentes y significativas para reducir la degradación de los hábitats naturales, detener la pérdida de la diversidad biológica y, para 2020, proteger las especies amenazadas y evitar su extinción",
	"meta.ods.15.nombre.6":"15.6 Promover la participación justa y equitativa en los beneficios que se deriven de la utilización de los recursos genéticos y promover el acceso adecuado a esos recursos",
	"meta.ods.15.nombre.7":"15.7 Adoptar medidas urgentes para poner fin a la caza furtiva y el tráfico de especies protegidas de flora y fauna y abordar la demanda y la oferta ilegales de productos silvestres",
	"meta.ods.15.nombre.8":"15.8 Para 2020, adoptar medidas para prevenir la introducción de especies exóticas invasoras y reducir de forma significativa sus efectos en los ecosistemas terrestres y acuáticos y controlar o erradicar las especies prioritarias",
	"meta.ods.15.nombre.9":"15.9 Para 2020, integrar los valores de los ecosistemas y la diversidad biológica en la planificación nacional y local, los procesos de desarrollo, las estrategias de reducción de la pobreza y la contabilidad",
	"meta.ods.15.nombre.a":"15.a Movilizar y aumentar de manera significativa los recursos financieros procedentes de todas las fuentes para conservar y utilizar de forma sostenible la diversidad biológica y los ecosistemas",
	"meta.ods.15.nombre.b":"15.b Movilizar un volumen apreciable de recursos procedentes de todas las fuentes y a todos los niveles para financiar la gestión forestal sostenible y proporcionar incentivos adecuados a los países en desarrollo para que promuevan dicha gestión",
	"meta.ods.15.nombre.c":"15.c Aumentar el apoyo mundial a la lucha contra la caza furtiva y el tráfico de especies protegidas, en particular aumentando la capacidad de las comunidades locales para promover oportunidades de subsistencia sostenibles",
	"meta.ods.16.nombre.1":"16.1 Reducir considerablemente todas las formas de violencia y las tasas de mortalidad conexas en todo el mundo",
	"meta.ods.16.nombre.2":"16.2 Poner fin al maltrato, la explotación, la trata, la tortura y todas las formas de violencia contra los niños",
	"meta.ods.16.nombre.3":"16.3 Promover el estado de derecho en los planos nacional e internacional y garantizar la igualdad de acceso a la justicia para todos",
	"meta.ods.16.nombre.4":"16.4 De aquí a 2030, reducir significativamente las corrientes financieras y de armas ilícitas, fortalecer la recuperación y devolución de los activos robados y luchar contra todas las formas de delincuencia organizada",
	"meta.ods.16.nombre.5":"16.5 Reducir sustancialmente la corrupción y el soborno en todas sus formas",
	"meta.ods.16.nombre.6":"16.6 Crear instituciones eficaces, responsables y transparentes a todos los niveles",
	"meta.ods.16.nombre.7":"16.7 Garantizar la adopción de decisiones inclusivas, participativas y representativas que respondan a las necesidades a todos los niveles",
	"meta.ods.16.nombre.8":"16.8 Ampliar y fortalecer la participación de los países en desarrollo en las instituciones de gobernanza mundial",
	"meta.ods.16.nombre.9":"16.9 Proporcionar acceso a una identidad jurídica para todos, en particular mediante el registro de nacimientos",
	"meta.ods.16.nombre.10":"16.10 Garantizar el acceso público a la información y proteger las libertades fundamentales, de conformidad con las leyes nacionales y los acuerdos internacionales",
	"meta.ods.16.nombre.a":"16.a Fortalecer las instituciones nacionales pertinentes, con miras a crear capacidad a todos los niveles, en particular en los países en desarrollo, para prevenir la violencia y combatir el terrorismo y la delincuencia",
	"meta.ods.16.nombre.b":"16.b Promover y aplicar leyes y políticas no discriminatorias en favor del desarrollo sostenible",
	"meta.ods.17.nombre.1":"17.1 Fortalecer la movilización de recursos internos, incluso mediante la prestación de apoyo internacional a los países en desarrollo, con el fin de mejorar la capacidad nacional para recaudar ingresos fiscales y de otra índole",
	"meta.ods.17.nombre.2":"17.2 Velar por que los países desarrollados cumplan cabalmente sus compromisos en relación con la asistencia oficial para el desarrollo",
	"meta.ods.17.nombre.3":"17.3 Movilizar recursos financieros adicionales procedentes de múltiples fuentes para los países en desarrollo",
	"meta.ods.17.nombre.4":"17.4 Ayudar a los países en desarrollo a lograr la sostenibilidad de la deuda a largo plazo con políticas coordinadas orientadas a fomentar la financiación, el alivio y la reestructuración de la deuda, según proceda, y hacer frente a la deuda externa de los países pobres muy endeudados a fin de reducir el endeudamiento excesivo",
	"meta.ods.17.nombre.5":"17.5 Adoptar y aplicar sistemas de promoción de las inversiones en favor de los países menos adelantados",
	"meta.ods.17.nombre.6":"17.6 Mejorar la cooperación regional e internacional Norte-Sur, Sur-Sur y triangular en materia de ciencia, tecnología e innovación y el acceso a estas, y aumentar el intercambio de conocimientos en condiciones mutuamente convenidas, incluso mejorando la coordinación entre los mecanismos existentes, en particular a nivel de las Naciones Unidas, y mediante un mecanismo mundial de facilitación de la tecnología",
	"meta.ods.17.nombre.7":"17.7 Promover el desarrollo de tecnologías ecológicamente racionales y su transferencia, divulgación y difusión a los países en desarrollo en condiciones favorables, incluso en condiciones concesionarias y preferenciales, por mutuo acuerdo",
	"meta.ods.17.nombre.8":"17.8 Poner en pleno funcionamiento, a más tardar en 2017, el banco de tecnología y el mecanismo de apoyo a la creación de capacidad en materia de ciencia, tecnología e innovación para los países menos adelantados y aumentar la utilización de tecnologías instrumentales, en particular la tecnología de la información y las comunicaciones",
	"meta.ods.17.nombre.9":"17.9 Aumentar el apoyo internacional para realizar actividades de creación de capacidad eficaces y específicas en los países en desarrollo a fin de respaldar los planes nacionales de implementación de todos los Objetivos de Desarrollo Sostenible, incluso mediante la cooperación Norte-Sur, Sur-Sur y triangular",
	"meta.ods.17.nombre.10":"17.10 Promover un sistema de comercio multilateral universal, basado en normas, abierto, no discriminatorio y equitativo en el marco de la Organización Mundial del Comercio, incluso mediante la conclusión de las negociaciones en el marco del Programa de Doha para el Desarrollo",
	"meta.ods.17.nombre.11":"17.11 Aumentar significativamente las exportaciones de los países en desarrollo, en particular con miras a duplicar la participación de los países menos adelantados en las exportaciones mundiales de aquí a 2020",
	"meta.ods.17.nombre.12":"17.12 Lograr la consecución oportuna del acceso a los mercados libre de derechos y contingentes de manera duradera para todos los países menos adelantados, conforme a las decisiones de la Organización Mundial del Comercio, incluso velando por que las normas de origen preferenciales aplicables a las importaciones de los países menos adelantados sean transparentes y sencillas y contribuyan a facilitar el acceso a los mercados",
	"meta.ods.17.nombre.13":"17.13 Aumentar la estabilidad macroeconómica mundial, incluso mediante la coordinación y coherencia normativas",
	"meta.ods.17.nombre.14":"17.14 Mejorar la coherencia normativa para el desarrollo sostenible",
	"meta.ods.17.nombre.15":"17.15 Respetar el liderazgo y el margen normativo de cada país para establecer y aplicar políticas orientadas a la erradicación de la pobreza y la promoción del desarrollo sostenible",
	"meta.ods.17.nombre.16":"17.16 Mejorar la Alianza Mundial para el Desarrollo Sostenible, complementada por alianzas entre múltiples interesados que movilicen e intercambien conocimientos, especialización, tecnología y recursos financieros, a fin de apoyar el logro de los Objetivos de Desarrollo Sostenible en todos los países, particularmente los países en desarrollo",
	"meta.ods.17.nombre.17":"17.17 Fomentar y promover la constitución de alianzas eficaces en las esferas pública, público-privada y de la sociedad civil, aprovechando la experiencia y las estrategias de obtención de recursos de las alianzas",
	"meta.ods.17.nombre.18":"17.18 De aquí a 2020, mejorar el apoyo a la creación de capacidad prestado a los países en desarrollo, incluidos los países menos adelantados y los pequeños Estados insulares en desarrollo, para aumentar significativamente la disponibilidad de datos oportunos, fiables y de gran calidad desglosados por ingresos, sexo, edad, raza, origen étnico, estatus migratorio, discapacidad, ubicación geográfica y otras características pertinentes en los contextos nacionales",
	"meta.ods.17.nombre.19":"17.19 De aquí a 2030, aprovechar las iniciativas existentes para elaborar indicadores que permitan medir los progresos en materia de desarrollo sostenible y complementen el producto interno bruto, y apoyar la creación de capacidad estadística en los países en desarrollo",
	"sectorlbg.nombre.consumer":"Consumer goods",
	"sectorlbg.nombre.engieering":"Engineering & Manufacturing",
	"sectorlbg.nombre.financial":"Financial Services",
	"sectorlbg.nombre.food":"Food & Drink",
	"sectorlbg.nombre.legal":"Legal",
	"sectorlbg.nombre.medias":"Media",
	"sectorlbg.nombre.natural":"Natural Resources",
	"sectorlbg.nombre.pharmaceiutical":"Pharmaceuticals",
	"sectorlbg.nombre.professional":"Professional Services",
	"sectorlbg.nombre.construction":"Property, Construction, Housing, Facilities",
	"sectorlbg.nombre.retail":"Retail",
	"sectorlbg.nombre.technology":"Technology & Telecoms",
	"sectorlbg.nombre.travel":"Travel & Hospitality",
	"sectorlbg.nombre.utilites":"Utilities & Services",
	"area.nombre.educacion":"Educación",
	"area.nombre.salud":"Salud",
	"area.nombre.desarrollo":"Desarrollo socioeconómico",
	"area.nombre.medio.ambiente":"Medio Ambiente",
	"area.nombre.arte":"Arte y Cultura",
	"area.nombre.social":"Bienestar Social",
	"area.nombre.ayuda":"Ayuda Humanitaria",
	"area.nombre.autonomia":"Autonomía personal y vida independiente",
	"area.nombre.empleo":"Empleo y emprendimiento",
	"area.nombre.institucional":"Fortalecimiento institucional",
	"area.nombre.ciudadania":"Fortalecimiento de la ciudadanía activa",
	"area.nombre.diversidad":"Diversidad y fortalecimiento de la familia",
	"area.nombre.otros":"Otros",
	"grupo.interes.nombre.clientes":"Clientes / consumidores",
	"grupo.interes.nombre.proveedores":"Proveedores / distribuidores",
	"grupo.interes.nombre.donantes":"Donantes / sponsors",
	"grupo.interes.nombre.otros":"Otros 'grupos de interés",
	"motivacion.nombre.puntual":"Aportación puntual",
	"motivacion.nombre.inversion":"Inversión social",
	"motivacion.nombre.alineada":"Iniciativa alineada con el negocio",
	"motivacion.nombre.propia":"Propia",
	"motivacion.nombre.compartida":"Compartida",
	"motivacion.nombre.apoyo":"De apoyo",
	"ods.nombre.pobreza":"1. Fin de la pobreza",
	"ods.nombre.hambre":"2. Hambre cero",
	"ods.nombre.salud":"3. Salud y bienestar",
	"ods.nombre.educacion":"4. Educación de calidad",
	"ods.nombre.genero":"5. Igualdad de género",
	"ods.nombre.saneamiento":"6. Agua limpia y saneamiento",
	"ods.nombre.energia":"7. Energía asequible y no contaminante",
	"ods.nombre.trabajo":"8. Trabajo decente y crecimiento económico",
	"ods.nombre.industria":"9. Industria, innovación e infraestructura",
	"ods.nombre.desigualdad":"10. Reducción de desigualdades",
	"ods.nombre.sostenibilidad":"11. Ciudades y comunidades sostenibles",
	"ods.nombre.consumo":"12. Producción y consumo responsable",
	"ods.nombre.clima":"13. Acción por el clima",
	"ods.nombre.vida.submarina":"14. Vida submarina",
	"ods.nombre.vida.terrestre":"15. Vida de ecosistema terrestres",
	"ods.nombre.paz":"16. Paz, justicia, e instituciones sólidas",
	"ods.nombre.alianza":"17. Alianzas para lograr los objetivos",
	"razon.nombre.obligacion.legal":"Contribuciones obligación legal",
	"razon.nombre.obligacion.contractual":"Contribuciones obligación contractual",
	"razon.nombre.dirigidas.empleados":"Contribuciones dirigidas específicamente a empleados",
	"razon.nombre.dirigidas.deporte":"Contribuciones dirigidas a deporte profesional",
	"razon.nombrer.otros":"Otros",
	"situacion.nombre.desempleo":"En situación de desempleo",
	"situacion.nombre.desempleo.larga":"En situación de desempleo de larga duración",
	"situacion.nombre.dificultad.aprendizaje":"Con dificultades para el aprendizaje",
	"situacion.nombre.analfabetos":"Sin formación académica o analfabetos",
	"situacion.nombre.sin.hogar":"Sin hogar",
	"situacion.nombre.inmigrantes":"Inmigrantes",
	"situacion.nombre.refugiados":"Refugiados",
	"situacion.nombre.presidiario":"Presidiarios o ex-presidiarios",
	"situacion.nombre.ingresos.bajos":"De bajos ingresos",
	"situacion.nombre.minorias":"Pertenecen a minorías étnicas",
	"situacion.nombre.pais.desarrollo":"Viven en países en vías de desarrollo",
	"situacion.nombre.vulnerabilidad":"En situación de vulnerabilidad",
	"situacion.nombre.desastre.natural":"Víctimas de una catástrofe o desastre natural",
	"situacion.nombre.enfermedad":"Personas con alguna enfermedad",
	"situacion.nombre.discapacidad":"Personas con discapacidad",
	"situacion.nombre.dependencia":"Personas con alguna dependencia (drogodependencia, alcoholismo, otras adicciones)",
	"situacion.nombre.abusos":"Sufren abusos / agresión sexual",
	"situacion.nombre.beneficiario":"Beneficiarios del proyecto",
	"situacion.nombre.otros":"Otros perfiles",
	"tipo.empresa.empresa":"Empresa",
	"tipo.empresa.onl":"ONL",
	"tipo.empresa.fundacion":"Fundación corporativa",
	"tipo.empresa.otras":"Otras",
	"tipo.empresa.ong":"ONG",
	"tipo.logro.mujeres.hombres.todos":"Mujeres y hombres de todas las edades",
	"tipo.logro.hombres.todos":"Hombres (de todas las edades)",
	"tipo.logro.mujeres.todas":"Mujeres (de todas las edades)",
	"tipo.logro.niño.niñas.todos":"Niños y niñas ( 0-16 años)",
	"tipo.logro.niño":"Niños (0-16 años)",
	"tipo.logro.niña":"Niñas (0-16 años)",
	"tipo.logro.jovenes.todos":"Jóvenes chicos y chicas (16 -18 años)",
	"tipo.logro.joven.chico":"Jóvenes-chicos (16-18 años)",
	"tipo.logro.joven.chica":"Jóvenes-chicas (16-18 años)",
	"tipo.logro.hombre.mujer.mayor.18":"Hombres y mujeres ( >18 años)",
	"tipo.logro.hombre.mayor.18":"Hombres (>18 años)",
	"tipo.logro.mujere.mayor.18":"Mujeres (>18 años)",
	"tipo.logro.mujer.hombre.mayor.45":"Hombres y mujeres ( >45 años)",
	"tipo.logro.hombre.mayor.45":"Hombres (>45 años)",
	"tipo.logro.mujer.mayor.45":"Mujeres (>45 años)",
	"tipo.logro.mujer.hombre.mayor.65":"Hombres y mujeres ( >65 años)",
	"tipo.logro.hombre.mayor.65":"Hombres (>65 años)",
	"tipo.logro.mujer.mayor.65":"Mujeres (>65 años)",
	"pais.nombre.españa":"España",
	"pais.nombre.francia":"Francia",
	"pais.nombre.reini.unido":"Reino Unido",
	"pais.nombre.alemania":"Alemania",
	"pais.nombre.estados.unidos":"Estados Unidos",
	"pais.nombre.mexico":"México",
	"pais.nombre.abjasia":"Abjasia",
	"pais.nombre.afganistan":"Afganistán",
	"pais.nombre.albania":"Albania",
	"pais.nombre.andorra":"Andorra",
	"pais.nombre.angola":"Angola",
	"pais.nombre.anguila":"Anguila",
	"pais.nombre.antigua":"Antigua y Barbuda",
	"pais.nombre.arabia":"Arabia Saudita",
	"pais.nombre.argelia":"Argelia",
	"pais.nombre.argentina":"Argentina",
	"pais.nombre.armenia":"Armenia",
	"pais.nombre.aruba":"Aruba",
	"pais.nombre.australia":"Australia",
	"pais.nombre.austria":"Austria",
	"pais.nombre.azerbaiyan":"Azerbaiyán",
	"pais.nombre.bahamas":"Bahamas",
	"pais.nombre.bangladesh":"Bangladesh",
	"pais.nombre.barbados":"Barbados",
	"pais.nombre.barein":"Baréin",
	"pais.nombre.belgica":"Bélgica",
	"pais.nombre.belice":"Belicea",
	"pais.nombre.benin":"Benín",
	"pais.nombre.bermudas":"Bermudas",
	"pais.nombre.bielorrusia":"Bielorrusia",
	"pais.nombre.birmania":"Birmania",
	"pais.nombre.bolivia":"Bolivia",
	"pais.nombre.bosnia":"Bosnia y Herzegovina",
	"pais.nombre.botsuana":"Botsuana",
	"pais.nombre.brasil":"Brasil",
	"pais.nombre.brunei":"Brunéi",
	"pais.nombre.bulgaria":"Bulgaria",
	"pais.nombre.burkina":"Burkina Faso",
	"pais.nombre.burundi":"Burundi",
	"pais.nombre.butan":"Bután",
	"pais.nombre.cabo":"Cabo Verde",
	"pais.nombre.camboya":"Camboya",
	"pais.nombre.camerun":"Camerún",
	"pais.nombre.canada":"Canadá",
	"pais.nombre.caribe":"Caribe Neerlandés",
	"pais.nombre.chad":"Chad",
	"pais.nombre.chile":"Chile",
	"pais.nombre.china":"China",
	"pais.nombre.chipre":"Chipre",
	"pais.nombre.chipre.septentrional":"Chipre Septentrional",
	"pais.nombre.ciudad.vaticano":"Ciudad del Vaticano",
	"pais.nombre.colombia":"Colombia",
	"pais.nombre.comoras":"Comoras",
	"pais.nombre.corea.norte":"Corea del Norte",
	"pais.nombre.corea.sur":"Corea del Sur",
	"pais.nombre.costa.marfil":"Costa de Marfil",
	"pais.nombre.costa.rica":"Costa Rica",
	"pais.nombre.croacia":"Croacia",
	"pais.nombre.cuba":"Cuba",
	"pais.nombre.curazao":"Curazao",
	"pais.nombre.dinamarca":"Dinamarca",
	"pais.nombre.dominica":"Dominica",
	"pais.nombre.ecuador":"Ecuador",
	"pais.nombre.egipto":"Egipto",
	"pais.nombre.elsalvador":"El Salvador",
	"pais.nombre.emiratos":"Emiratos Árabes Unidos",
	"pais.nombre.eritrea":"Eritrea",
	"pais.nombre.eslovaquia":"Eslovaquia",
	"pais.nombre.eslovenia":"Eslovenia",
	"pais.nombre.estados.federados.micronesia":"Estados Federados de Micronesia",
	"pais.nombre.estonia":"Estonia",
	"pais.nombre.etiopia":"Etiopía",
	"pais.nombre.filipinas":"Filipinas",
	"pais.nombre.finlandia":"Finlandia",
	"pais.nombre.fiyi":"Fiyi",
	"pais.nombre.gabon":"Gabón",
	"pais.nombre.gambia":"Gambia",
	"pais.nombre.georgia":"Georgia",
	"pais.nombre.ghana":"Ghana",
	"pais.nombre.gibraltar":"Gibraltar",
	"pais.nombre.granada":"Granada",
	"pais.nombre.grecia":"Grecia",
	"pais.nombre.groenlandia":"Groenlandia",
	"pais.nombre.guadalupe":"Guadalupe",
	"pais.nombre.guam":"Guam",
	"pais.nombre.guatemala":"Guatemala",
	"pais.nombre.guayana":"Guayana Francesa",
	"pais.nombre.guernsey":"Guernsey",
	"pais.nombre.guinea":"Guinea",
	"pais.nombre.guinea.ecuatorial":"Guinea Ecuatorial",
	"pais.nombre.guinea.bissau":"Guinea-Bissau",
	"pais.nombre.guyana":"Guyana",
	"pais.nombre.haiti":"Haití",
	"pais.nombre.honduras":"Honduras",
	"pais.nombre.hong.kong":"Hong Kong",
	"pais.nombre.hungría":"Hungría",
	"pais.nombre.india":"India",
	"pais.nombre.indonesia":"Indonesia",
	"pais.nombre.irak":"Irak",
	"pais.nombre.irán":"Irán",
	"pais.nombre.irlanda":"Irlanda",
	"pais.nombre.isla.ascension":"Isla Ascensión",
	"pais.nombre.isla.navidad":"Isla de Navidad",
	"pais.nombre.islandia":"Islandia",
	"pais.nombre.islas.caimán":"Islas Caimán",
	"pais.nombre.islas.marshall":"Islas Marshall",
	"pais.nombre.islas.salomón":"Islas Salomón",
	"pais.nombre.islas.turcas y Caicos":"Islas Turcas y Caicos",
	"pais.nombre.islas.virgenes.británicas":"Islas Vírgenes Británicas",
	"pais.nombre.islas.virgenes.estados.unidos":"Islas Vírgenes de Estados Unidos",
	"pais.nombre.israel":"Israel",
	"pais.nombre.italia":"Italia",
	"pais.nombre.jamaica":"Jamaica",
	"pais.nombre.japon":"Japón",
	"pais.nombre.jordania":"Jordania",
	"pais.nombre.kazajistan":"Kazajistán",
	"pais.nombre.kenia":"Kenia",
	"pais.nombre.kirguistan":"Kirguistán",
	"pais.nombre.kiribati":"Kiribati",
	"pais.nombre.kosovo":"Kosovo",
	"pais.nombre.kuwait":"Kuwait",
	"pais.nombre.laos":"Laos",
	"pais.nombre.lesoto":"Lesoto",
	"pais.nombre.letonia":"Letonia",
	"pais.nombre.libano":"Líbano",
	"pais.nombre.liberia":"Liberia",
	"pais.nombre.libia":"Libia",
	"pais.nombre.liechtenstein":"Liechtenstein",
	"pais.nombre.lituania":"Lituania",
	"pais.nombre.luxemburgo":"Luxemburgo",
	"pais.nombre.madagascar":"Madagascar",
	"pais.nombre.malasia":"Malasia",
	"pais.nombre.malaui":"Malaui",
	"pais.nombre.maldivas":"Maldivas",
	"pais.nombre.mali":"Malí",
	"pais.nombre.malta":"Malta",
	"pais.nombre.marruecos":"Marruecos",
	"pais.nombre.martinica":"Martinica",
	"pais.nombre.mauricio":"Mauricio",
	"pais.nombre.mauritania":"Mauritania",
	"pais.nombre.mayotte":"Mayotte",
	"pais.nombre.moldavia":"Moldavia",
	"pais.nombre.mongolia":"Mongolia",
	"pais.nombre.montserrat":"Montserrat",
	"pais.nombre.mozambique":"Mozambique",
	"pais.nombre.namibia":"Namibia",
	"pais.nombre.nauru":"Nauru",
	"pais.nombre.nepal":"Nepal",
	"pais.nombre.nicaragua":"Nicaragua",
	"pais.nombre.niger":"Níger",
	"pais.nombre.nigeria":"Nigeria",
	"pais.nombre.niue":"Niue",
	"pais.nombre.noruega":"Noruega",
	"pais.nombre.nueva.zelanda":"Nueva Zelanda",
	"pais.nombre.oman":"Omán",
	"pais.nombre.paeses.bajos":"Países Bajos",
	"pais.nombre.pakistan":"Pakistán",
	"pais.nombre.palaos":"Palaos",
	"pais.nombre.panama":"Panamá",
	"pais.nombre.papua":"Papúa Nueva Gu",
	"pais.nombre.paraguay":"Paraguay",
	"pais.nombre.peru":"Perú",
	"pais.nombre.polonia":"Polonia",
	"pais.nombre.portugal":"Portugal",
	"pais.nombre.puerto.rico":"Puerto Rico",
	"pais.nombre.qatar":"Qatar",
	"pais.nombre.republica.centroafricana":"República Centroafricana",
	"pais.nombre.republica.checa":"República Checa",
	"pais.nombre.republica.macedonia":"República de Macedonia",
	"pais.nombre.republica.congo":"República del Congo",
	"pais.nombre.republica.democratica.congo":"República Democrática del Congo",
	"pais.nombre.republica.dominicana":"República Dominicana",
	"pais.nombre.reunion":"Reunión",
	"pais.nombre.ruanda":"Ruanda",
	"pais.nombre.rumania":"Rumania",
	"pais.nombre.rusia":"Rusia",
	"pais.nombre.sahara.occidental":"Sahara Occidental",
	"pais.nombre.samoa":"Samoa",
	"pais.nombre.san.bartolome":"San Bartolomé",
	"pais.nombre.san.cristobal":"San Cristóbal y Nieves",
	"pais.nombre.san.marino":"San Marino",
	"pais.nombre.san.martin":"San Martín",
	"pais.nombre.san.pedro":"San Pedro y Miquelón",
	"pais.nombre.san.vicente":"San Vicente y las Granadinas",
	"pais.nombre.santa.helena":"Santa Helena",
	"pais.nombre.santa.lucia":"Santa Lucía",
	"pais.nombre.santo.tome":"santo Tomé y Príncipe",
	"pais.nombre.senegal":"Senegal",
	"pais.nombre.serbia":"Serbia",
	"pais.nombre.seychelles":"Seychelles",
	"pais.nombre.sierra Leona":"Sierra Leona",
	"pais.nombre.singapur":"Singapur",
	"pais.nombre.siria":"Siria",
	"pais.nombre.somalia":"Somalía",
	"pais.nombre.somaliland":"Somaliland",
	"pais.nombre.sri Lanka":"Sri Lanka",
	"pais.nombre.suazilandia":"Suazilandia",
	"pais.nombre.sudafrica":"Sudáfrica",
	"pais.nombre.sudan":"Sudán",
	"pais.nombre.sudan.sur":"Sudán del Sur",
	"pais.nombre.suecia":"Suecia",
	"pais.nombre.suiza":"Suiza",
	"pais.nombre.surinam":"Surinam",
	"pais.nombre.tailandia":"Tailandia",
	"pais.nombre.tanzania":"Tanzania",
	"pais.nombre.tayikistan":"Tayikistán",
	"pais.nombre.togo":"Togo",
	"pais.nombre.tonga":"Tonga",
	"pais.nombre.trinidad y Tobago":"Trinidad y Tobago",
	"pais.nombre.trista.cunha":"Tristán da Cunha",
	"pais.nombre.tunez":"Túnez",
	"pais.nombre.turkmenistan":"Turkmenistán",
	"pais.nombre.turquia":"Turquía",
	"pais.nombre.tuvalu":"Tuvalu",
	"pais.nombre.ucrania":"Ucrania",
	"pais.nombre.uganda":"Uganda",
	"pais.nombre.uruguay":"Uruguay",
	"pais.nombre.uzbekistan":"Uzbekistán",
	"pais.nombre.vanuau":"Vanuatu",
	"pais.nombre.venezuela":"Venezuela",
	"pais.nombre.vietnam":"Vietnam",
	"pais.nombre.wallis":"Wallis y Futuna",
	"pais.nombre.yemen":"Yemen",
	"pais.nombre.yibuti":"Yibuti",
	"pais.nombre.zambia":"Zambia",
	"pais.nombre.zimbabue":"Zimbabue",
	"pais.nombre.islas.turcas":"Islas Turcas y Caicos",
	"pais.nombre.sierra.leona":"Sierra Leona",
	"pais.nombre.sri.lanka":"Sri Lanka",
	"pais.nombre.trinidad.tobago":"Trinidad y Tobago",
	"login.entidad.desactivado":"Tu entidad actualmente se encuentra desactivada",
	"filterReport.informes":"Informes",
	"filterReport.tipoInformes":"Tipos de informes",
	"filterReport.marcarTodos":"Marcar todos",
	"filterReport.desmarcarTodos":"Desmarcar todos",
	"filterReport.divisa":"Divisa",
	"filterReport.seleccionaDivisa":"Debe seleccionar una divisa",
	"filterReport.proyectos":"Proyectos",
	"filterReport.byProjects":"Por proyectos",
	"filterReport.proyectState":"Estado de los proyectos",
	"filterReport.divUdNegocio":"División o Unidad de negocio",
	"filterReport.agrInterna":"Agrupación interna",
	"filterReport.agrIniciativaInterna":"Agrupación interna de iniciativas",
	"filterReport.extractProyecto":"Extraer información sobre presupuestos",
	"filterReport.proyectDate":"Proyectos con fecha de inicio comprendida",
	"filterReport.desde":"Desde",
	"filterReport.hasta":"Hasta",
	"filterReport.y":"Y",
	"filterReport.proyectDateEnd":"Proyectos con fecha de fin comprendida",
	"filterReport.iniciativa":"Iniciativa",
	"filterReport.extraerLBG":"Extraer iniciativas conforme con LBG/ONLBG",
	"filterReport.pais":"País",
	"filterReport.region":"Región",
	"filterReport.area":"Área de actuación",
	"filterReport.motivacion":"Motivación",
	"filterReport.extractODS":"Extraer información sobre ODS",
	"filterReport.ods":"ODS primario",
	"filterReport.odsError":"Debe seleccionar un ODS Primario si Extraer información sobre ODS está puesto a Sí",
	"filterReport.extractInitiative":"Extraer información sobre presupuestos",
	"filterReport.initiativeDate":"Iniciativas con fecha de inicio comprendida",
	"filterReport.initiativeDateEnd":"Iniciativas con fecha de fin comprendida",
	"filterReport.contributions":"Contribuciones",
	"filterReport.validados":"Validados",
	"filterReport.atribucion":"Atribución",
	"filterReport.contributiontype":"Tipo de contribución",
	"filterReport.contributionLey112018":"Aportaciones a Fundación/ONL Ley 11/2018",
	"filterReport.logros":"Logros",
	"filterReport.impactos":"Impactos",
	"filterReport.generate":"Generar informe",
	"filterReport.generateCharts":"Generar gráficos",
	"filterReport.downloadCharts":"Descargar gráficos",
	"filterReport.divisaDelInforme":"Seleccionar la divisa del informe",
	"filterReport.entre":"Entre",
	"filterReport.tipo.contribución":"Tipo de contribución",
	"filterReport.unidad.negocio":"Unidad de negocio",
	"filterReport.ambito.geografico":"País",
	"filterReport.pais.españa":"País España",
	"filterReport.ODS":"ODS",
	"filterReport.empleados.y.voluntarios":"Empleados y voluntarios",
	"filterReport.efecto.multiplicador":"Efecto multiplicador",
	"filterReport.resultados.comunidad":"Resultados de la comunidad",
	"filterReport.resultados.entidad":"Resultados de la entidad",
	"filterReport.dinero":"Dinero",
	"filterReport.especie":"Especie",
	"filterReport.tiempo":"Tiempo",
	"filterReport.probono":"Probono",
	"filterReport.coste.gestion":"Coste de gestión",
	"filterReport.todos":"Todos",
	"filterReport.abiertos":"Abiertos",
	"filterReport.cerrados":"Cerrados",
	"filterReport.si":"Sí",
	"filterReport.no":"No",
	"filterReport.entidad":"Entidad",
	"filterReport.otros":"Otros",
	"filterReport.no.validados":"No Validados",
	"filterReport.todas":"Todas",
	"filterReport.si.cumplen":"Sí Cumplen",
	"filterReport.no.cumplen":"No Cumplen",
	"filterReport.descarga":"Descarga de datos",
	"filterReport.graficos":"Informes en pantalla",
	"reportChart.activeProyects":"Proyectos activos",
	"reportChart.activeInitiatives":"Iniciativas activas",
	"reportChart.contributionProyects":"Proyectos con contribución",
	"reportChart.contributionInitiatives":"Iniciativas con contribución",
	"reportChart.entityContribution":"Contribución {{ITV}}",
	"reportChart.total":"Totales",
	"reportChart.header.contribType":"Tipo de contribución",
	"reportChart.header.udNegocio":"Unidad de negocio",
	"reportChart.header.agrIniciativaInterna":"Agrupación interna de iniciativas",
	"reportChart.header.agrInterna":"Agrupación interna",
	"reportChart.header.motivation":"Motivación",
	"reportChart.header.area":"Área de actuación",
	"reportChart.header.ambitoGeografico":"País",
	"reportChart.header.ES":"País España",
	"reportChart.header.ods":"ODS",	
	"reportChart.contrib.dinero":"Dinero",
	"reportChart.contrib.tiempo":"Tiempo",
	"reportChart.contrib.especie":"En especie",
	"reportChart.contrib.probono":"Probono",
	"reportChart.contrib.costeGestion":"Costes de gestión",
	"reportChart.header.pais":"España",
	"reportChart.proyectos":"Proyectos",
	"reportChart.iniciativas":"Iniciativas",
	"reportChart.areaActuacion":"Área de actuación",
	"reportChart.proyectosConContribucion":"Proyectos con contribución",
	"reportChart.contribucionTotal":"Contribución Total",
	"reportChart.contribucionItv":"Contribución {{ITV}}",
	"reportChart.porcentajeContribucionItv":"Contribución {{ITV}} %",
	"reportChart.contribucionEntidad":"Contribución {{ITV}}",
	"reportChart.porcentajeContribucionEntidad":"Contribución {{ITV}} %",
	"reportChart.areaPrimaria":"Área primaria",
	"reportChart.areaSecundaria":"Área secundaria",
	"reportChart.odsPrimario":"Como 1<sup>er</sup> ODS",
	"reportChart.odsSecundario":"Como 2º ODS",
	"reportChart.odsTerciarios":"Como 3<sup>er</sup> ODS o más",
	"reportChart.contributions":"Contribuciones",
	"reportChart.efectoMultiplicador":"Efecto multiplicador {{ITV}}",
	"reportChart.efectoMultiplicadorOtros":"Efecto multiplicador otros colaboradores",
	"reportChart.Participacion1":"Participantes (empleados) por parte de la entidad: logros",
	"reportChart.Participacion2":"Participantes (no empleados) por parte de la entidad: logros",
	"reportChart.Participacion3":"Impactos sobre los empleados de la entidad",
	"reportChart.Participacion4":"Participación por parte de otros colaboradores: logros",
	"reportChart.Participacion6":"Impactos sobre los empleados de otros colaboradores",
	"reportChart.proyectosParticipacion":"Proyectos con participación",
	"reportChart.iniciativasParticipacion":"Iniciativas con participación",
	"reportChart.numPersonas":"Personas",
	"reportChart.numHoras":"Horas",
	"reportChart.porcentaje":"%",
	"reportChart.costeHora":"Coste",
	"reportChart.emplTotal":"Empleados totales",
	"reportChart.emplEval":"Empleados evaluados",
	"reportChart.impactoAlto":"Impacto alto",
	"reportChart.impactoMed":"Impacto medio",
	"reportChart.impactoBajo":"Impacto bajo",
	"reportChart.impactoSinCamb":"Sin cambios",
	"reportChart.nombreComunidad":"Comunidades",
	"reportChart.numProyectosEfectoMult":"Proyectos con efecto multiplicador",
	"reportChart.numIniciativasEfectoMult":"Iniciativas con efecto multiplicador",
	"reportChart.cantidadEfectoMult":"Efecto multiplicador",
	"reportChart.cantidadEfectoMult":"Efecto multiplicador",
	"reportChart.proyectosConLogros":"Proyectos evaluados",
	"reportChart.iniciativasConLogros":"Iniciativas evaluadas",
	"reportChart.totalBeneficiarios":"Nº",
	"reportChart.beneficariosTotales":"Beneficiarios totales",
	"reportChart.beneficariosEval":"Beneficiarios evaluados",
	"reportChart.totalPorGrado":"Nº",
	"reportChart.totalPorTipo":"Nº",
	"reportChart.proyectosEval":"Proyectos evaluados",
	"reportChart.iniciativasEval":"Iniciativas evaluadas",
	"reportChart.totalOrganizaciones":"Nº Organizaciones",
	"reportChart.organizBenefTotal":"Nº Organizaciones Totales",
	"reportChart.organizBenefEval":"Organizaciones evaluadas",
	"reportChart.numLogros":"Nº",
	"reportChart.iniciativasSobreMA":"Impactos sobre el medioambiente",
	"reportChart.logros":"Logros",
	"reportChart.impactosGrado":"Impactos - grado",
	"reportChart.impactosTipo":"Impactos - tipo",
	"reportChart.logrosOrganizaciones":"Logros de las organizaciones beneficiarias",
	"reportChart.impactos":"Impactos",
	"reportChart.beneficiarios":"Beneficiarios",
	"reportChart.organizacionesBeneficiarios":"Organizaciones Beneficiarias",
	"reportChart.medioAmbiente":"Medio Ambiente",
	"reportChart.logrosEntidad":"Logros de {{ITV}}",
	"reportChart.empleadosEntidad.tiempoRemunerado":"Empleados de entidad en tiempo remunerado",
	"reportChart.empleadosEntidad.tiempoNoRemunerado":"Empleados de entidad en tiempo no remunerado",
	"reportChart.empleadosEntidad.probono":"Empleados de entidad en actividades probono",
	"reportChart.empleadosEntidad.costesGestion":"Empleados de entidad: costes de gestión de iniciativas",
	"reportChart.empleadosEntidad.participacionTotal":"Participación Total",
	"reportChart.noEmpleadosEntidad.familiares":"No empleados (familiares, clientes, otros) entidad",
	"reportChart.noEmpleadosEntidad.voluntariadoEstructural":"No empleados (voluntariado estructural) entidad",
	"reportChart.noEmpleadosEntidad.participacionTotal":"Participación Total (no empleados)",
	"reportChart.noEmpleadosOtros.familiares":"No empleados (familiares, clientes, otros) de otros colaboradores",
	"reportChart.noEmpleadosOtros.participacionTotal":"Participación Total (no empleados) de otros colaboradores",
	"reportChart.impactoEmpleados.habilidades":"Mejoraron sus habilidades laborales",
	"reportChart.impactoEmpleados.desarrollo":"Mejoraron su desarrollo personal",
	"reportChart.impactoEmpleados.comportamiento":"Tuvieron un cambio positivo en su comportamiento",
	"reportChart.empleadosOtros.tiempoRemunerado":"Empleados de otros colaboradores en tiempo remunerado",
	"reportChart.empleadosOtros.tiempoNoRemunerado":"Empleados de otros colaboradores en tiempo no remunerado",
	"reportChart.empleadosOtros.probono":"Empleados de otros colaboradores en actividades probono",
	"reportChart.empleadosOtros.costesGestion":"Empleados de otros colaboradores: Costes de gestión de iniciativas",
	"reportChart.empleadosOtros.participacionTotal":"Participación Total de otros colaboradores",
	"reportChart.efectoMultiplicador.entidad.empleadosPayrollGiving":"Empleados de entidad - payroll giving",
	"reportChart.efectoMultiplicador.entidad.empleadosOtrasDonaciones":"Empleados de entidad - otras donaciones en dinero",
	"reportChart.efectoMultiplicador.entidad.clientesConsumidoresOtrosCash":"Clientes / Consumidores / Otras fuentes en dinero de la entidad",
	"reportChart.efectoMultiplicador.entidad.empleadosEspecie":"Otras aportaciones en especie de empleados de la entidad",
	"reportChart.efectoMultiplicador.entidad.clientesConsumidoresOtrosEspecie":"Clientes / Consumidores / Otras fuentes en especie de la entidad",
	"reportChart.efectoMultiplicador.entidad.Otros":"Otros (entidad)",
	"reportChart.efectoMultiplicador.entidad.Total":"Total (entidad)",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosPayrollGiving":"Empleados Otros colaboradores - payroll giving",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosOtrasDonaciones":"Empleados Otros colaboradores - otras donaciones en dinero",
	"reportChart.efectoMultiplicador.otrosColaboradores.clientesConsumidoresOtrosCash":"Clientes / Consumidores / Otras fuentes en dinero de otros colaboradores",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosEspecie":"Otras aportaciones en especie de empleados de otros colaboradores",
	"reportChart.efectoMultiplicador.entidad.empleadosValorTrabajoVoluntariado":"Empleados de entidad - valor del trabajo de voluntariado",
	"reportChart.efectoMultiplicador.entidad.noEmpleadosVoluntariadoEstructural":"No empleados - voluntariado estructural",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosValorTrabajoVoluntariado":"Empleados otros colaboradores - valor del trabajo de voluntariado",
	"reportChart.efectoMultiplicador.otrosColaboradores.noEmpleadosVoluntariadoEstructural":"No empleados - voluntariado estructural",
	"reportChart.efectoMultiplicador.otrosColaboradores.clientesConsumidoresOtrosEspecie":"Clientes / Consumidores / Otras fuentes en especie de otros colaboradores",
	"reportChart.efectoMultiplicador.otrosColaboradores.Otros":"Otros (Otros colaboradores)",
	"reportChart.efectoMultiplicador.otrosColaboradores.Total":"Total (Otros colaboradores)",
	"reportChart.logros.grado.sinCambios":"No fueron sensibilizados",
	"reportChart.logros.grado.sensibilizados":"Fueron sensibilizados como resultado de la iniciativa",
	"reportChart.logros.grado.mejorados":"Consiguieron una mejora como resultado de la iniciativa",
	"reportChart.logros.grado.transformados":"Consiguieron una transformación como resultado de la iniciativa",
	"reportChart.logros.tipo.comportamiento":"Experimentaron un cambio positivo en su comportamiento o actitud",
	"reportChart.logros.tipo.habilidades":"Adquirieron nuevas habilidades o mejoraron su desarrollo personal",
	"reportChart.logros.tipo.calidadDeVida":"Experimentaron un impacto positivo en su calidad de vida",
	"reportChart.medioambiente.directos":"Número de iniciativas con beneficios ambientales",
	"reportChart.medioambiente.indirectos":"Número de iniciativas que cambiaron el comportamiento ambiental de las personas",
	"salarytables.descripcion":"Descripción",
	"collaborator.form.descripcion":"Descripción",
	"contribuyente.colaborador":"Colaborador",
	"contribuyente.payroll":"Payroll",
	"contribuyente.otras.aportacion.empleado":"Empleado",
	"contribuyente.otras.aportacion.cliente":"Cliente",
	"contribuyente.otros":"Otros",
	"contribuyente.especie":"Especie",
	"contribuyente.clientes":"Clientes",
	"contribuyente.empleado.remunerado":"Empleado remunerado",
	"contribuyente.empleado.no.remunerado":"Empleado no remunerado",
	"contribuyente.no.empleado.familiar":"Familiar",
	"contribuyente.no.empleado.voluntario":"Voluntario",
	"contribuyente.tabla.salarial":"Tabla de contribuciones",
	"contribuyente.probono":"Probono",
	"user.list.filter.active":"Activo",
	"user.list.filter.inactive":"Inactivo",
	"placeholder.impact.select.write.here":"Escribe aquí para filtrar",
	"atribution.label.all":"Todos",
	"atribution.label.e":"E",
	"atribution.label.ef":"EF",
	"atribution.label.o":"O",
	"contributions.num.persons":"Número de personas",
	"contributions.salary.table":"Tabla de contribuciones",
	"contributions.atribution.entity":"Entidad",
	"contributions.atribution.leverage":"Efecto Multiplicador",
	"contributions.atribution.others":"Otros",
	"contributions.atribution.entity.abbreviation":"E",
	"contributions.atribution.others.abbreviation":"O",
	"contributions.atribution.leverage.abbreviation":"EF",
	"contributions.atribution.all":"Todos",
	"contributions.atribution.all.abbreviation":"Todos",
	"manual-altaUsuarios":"https://manual.masimpact.com/es/3/menu_principal/2/gestion/2/alta_usuarios",
	"manual-altaColaboradores":"https://manual.masimpact.com/es/3/menu_principal/2/gestion/3/alta_colaboradores",
	"manual-divisionYAgrupacion":"https://manual.masimpact.com/es/3/menu_principal/3/proyectos/2/division_administrativa_y_agrupacion_interna",
	"manual-presupuesto":"https://manual.masimpact.com/es/3/menu_principal/3/proyectos/3/presupuesto_de_un_proyecto",
	"manual-asignacionUsuarios":"https://manual.masimpact.com/es/3/menu_principal/3/proyectos/4/asignacion_de_usuarios_en_proyectos",
	"manual-nuevaIniciativa":"https://manual.masimpact.com/es/3/menu_principal/4/iniciativas/1/nueva_iniciativa",
	"manual-areaActuacion":"https://manual.masimpact.com/es/3/menu_principal/4/iniciativas/2/area_de_actuacion",
	"manual-motivacion":"https://manual.masimpact.com/es/3/menu_principal/4/iniciativas/3/motivacion_de_la_entidad",
	"manual-entidadesQueContribuyen":"https://manual.masimpact.com/es/3/menu_principal/4/iniciativas/4/colaboradores_en_la_iniciativa",
	"manual-principiosLBG":"https://manual.masimpact.com/es/3/menu_principal/4/iniciativas/5/principios_lbgonlbg",
	"manual-objetivosDesarrollo":"https://manual.masimpact.com/es/3/menu_principal/4/iniciativas/6/objetivos_de_desarrollo_sostenible",
	"manual-presupuestosEntidades":"https://manual.masimpact.com/es/3/menu_principal/4/iniciativas/7/presupuesto_de_entidades",
	"manual-contribuciones":"https://manual.masimpact.com/es/3/menu_principal/5/contribuciones",
	"manual-beneficiarios":"https://manual.masimpact.com/es/3/menu_principal/6/logros/2/beneficiarios",
	"manual-logrosMedioAmbiente":"https://manual.masimpact.com/es/3/menu_principal/6/logros/3/logros_medio_ambiente",
	"manual-organizacionesBeneficiarias":"https://manual.masimpact.com/es/3/menu_principal/6/logros/4/organizaciones_beneficiarias",
	"manual-logrosParaEntidades":"https://manual.masimpact.com/es/3/menu_principal/6/logros/5/logros_para_las_entidades",
	"manual-cumplimientoObjetivos":"https://manual.masimpact.com/es/3/menu_principal/6/logros/6/cumplimiento_de_objetivos",
	"manual-impactosSobreBeneficiarios":"https://manual.masimpact.com/es/3/menu_principal/7/impactos/2/impactos_sobre_los_beneficiarios",
	"manual-impactosSobreMedioAmbiente":"https://manual.masimpact.com/es/3/menu_principal/7/impactos/3/impactos_medio_ambientales",
	"manual-impactosSobreVoluntarios":"https://manual.masimpact.com/es/3/menu_principal/7/impactos/4/impactos_sobre_los_empleados_o_voluntarios",
	"manual-impactosSobreEntidades":"https://manual.masimpact.com/es/3/menu_principal/7/impactos/5/impactos_sobre_las_entidades",
	"activity-history.log-tipo.create":"Creado",
	"activity-history.log-tipo.read":"Accedido",
	"activity-history.log-tipo.update":"Actualizado",
	"activity-history.log-tipo.delete":"Borrado",
	"activity-history.log-tipo.duplicate":"Duplicado",
	"activity-history.seleccion-tipo":"Elementos...",
	"activity-history.seleccion-autor":"Usuarios...",
	"activity-history.seleccion-acciones":"Acciones...",
	"activity-history.seleccion-acciones-detalle":"Acciones detalladas...",
	"activity-history.seleccion-tipo-explicacion":"Filtrar por categoría",
	"activity-history.log-item.entidad":"Entidad",
	"activity-history.log-item.usuario":"Usuario",
	"activity-history.log-item.proyecto":"Proyecto",
	"activity-history.log-item.proyecto_adjuntos":"Proyecto_adjuntos",
	"activity-history.log-item.proyecto_usuarios":"Proyecto_usuarios",
	"activity-history.log-item.iniciativa":"Iniciativa",
	"activity-history.log-item.iniciativa_lbg":"Iniciativa_LBG",
	"activity-history.log-item.iniciativa_adjuntos":"Iniciativa_adjuntos",
	"activity-history.log-item.iniciativa_usuarios":"Iniciativa_usuarios",
	"activity-history.log-item.contribucion":"Contribución",
	"activity-history.log-item.contribucion_adjuntos":"Contribución_adjuntos",
	"activity-history.log-item.contribucion_permisos":"Contribución_permisos",
	"activity-history.log-item.logro":"Logro",
	"activity-history.log-item.logro_adjuntos":"Logro_adjuntos",
	"activity-history.log-item.logro_permisos":"Logro_permisos",
	"activity-history.log-item.impacto":"Impacto",
	"activity-history.log-item.impacto_adjuntos":"Impacto_adjuntos",
	"activity-history.log-item.impacto_permisos":"Impacto_permisos",
	"activity-history.log-item.colaborador":"Colaborador",
	"activity-history.log-item.documento":"Documento",
	"activity-history.log-item.tipocambio":"TipoCambio",
	"activity-history.log-item.tablasalarial":"TablaContribuciones",
	"activity-history.log-action.newproject":"Creado nuevo proyecto",
	"activity-history.log-action.loadproject":"Cargado datos proyecto",
	"activity-history.log-action.updateproject":"Actualizado proyecto",
	"activity-history.log-action.deleteproject":"Eliminado proyecto",
	"activity-history.log-action.closeproject":"Cerrado proyecto",
	"activity-history.log-action.deleteinitiative":"Eliminado iniciativa",
	"activity-history.log-action.updateinitiative":"Actualizado iniciativa",
	"activity-history.log-action.loadinitiative":"Cargado datos iniciativa",
	"activity-history.log-action.newinitiative":"Creado nueva iniciativa",
	"activity-history.log-action.listallinitiatives":"Cargado lista iniciativas",
	"activity-history.log-action.listallprojects":"Cargado lista proyectos",
	"activity-history.log-action.attachfiletoentity":"Adjuntado fichero a entidad",
	"activity-history.log-action.attachfiletocollaborator":"Adjuntado fichero a colaborador",
	"activity-history.log-action.attachfiletoproject":"Adjuntado fichero a proyecto",
	"activity-history.log-action.readattachedfile":"Leído fichero adjunto",
	"activity-history.log-action.newcontribution":"Creado nueva contribución",
	"activity-history.log-action.loadcontribution":"Cargado datos contribución",
	"activity-history.log-action.updatecontribution":"Actualizado contribución",
	"activity-history.log-action.deletecontribution":"Eliminado contribución",
	"activity-history.log-action.listallcontributions":"Cargado lista contribuciones",
	"activity-history.log-action.listattachedfiles":"Cargado lista adjuntos",
	"activity-history.log-action.deletefile":"Eliminado fichero adjunto",
	"activity-history.log-action.openproject":"Abierto proyecto",
	"activity-history.log-action.attachfiletoinitiative":"Adjuntado fichero a iniciativa",
	"activity-history.log-action.attachfiletocontribution":"Adjuntado fichero a contribución",
	"activity-history.log-action.attachfiletooutput":"Adjuntado fichero a logro",
	"activity-history.log-action.attachfiletoimpact":"Adjuntado fichero a impacto",
	"activity-history.log-action.attachfiletosalarytable":"Adjuntado fichero a tabla salarial",
	"activity-history.log-action.attachfiletosalaryexchangerate":"Adjuntado fichero a tipo de cambio",
	"activity-history.log-action.invalidatecontribution":"Invalidado contribución",
	"activity-history.log-action.validatecontribution":"Validado contribución",
	"activity-history.log-action.newoutput":"Creado nuevo logro",
	"activity-history.log-action.loadoutput":"Cargado datos logro",
	"activity-history.log-action.updateoutput":"Actualizado logro",
	"activity-history.log-action.deleteoutput":"Eliminado logro",
	"activity-history.log-action.listalloutputs":"Cargado lista logros",
	"activity-history.log-action.validateoutput":"Validado logro",
	"activity-history.log-action.invalidateoutput":"Invalidado logro",
	"activity-history.log-action.newimpact":"Creado nuevo impacto",
	"activity-history.log-action.loadimpact":"Cargado datos impacto",
	"activity-history.log-action.updateimpact":"Actualizado lista impactos",
	"activity-history.log-action.deleteimpact":"Eliminado impacto",
	"activity-history.log-action.listallimpacts":"Cargado lista impactos",
	"activity-history.log-action.validateimpact":"Validado impacto",
	"activity-history.log-action.invalidateimpact":"Invalidado impacto",
	"activity-history.dialog.title":"Historial de actividad",
	"descripcion.demasiado.larga":"Descripción demasiado larga. El máximo de caracteres es 16383",
	"reportChart.noEmpleadosOtros.voluntariadoEstructural":"No empleados (voluntariado estructural) de otros colaboradores",
	"session.autoExpire":"La sesión expirará automáticamente en",
	"session.autoExpireSeconds":"segundos.",
	"session.autoExpireContinue":"Continuar sesión",
	"contributions.saveContribucion":"Guardar contribución",
	"contributions.saveContribucions":"Guardar contribuciones",
	"contributions.confirmationMessage":"¿Quiere salir sin guardar los cambios?",
	"contributions.mensajerevisar":"Revise y complete los campos no rellenos.",
	"rating.showAllLanguages":"Mostrar todos los idiomas",
	"plan.uso.requerido.usuarios":"Debe indicar el número de usuarios",
	
	"reportChart.mapReportEmpVolSubtitle1":"Participantes (empleados) por parte de la entidad",
	"reportChart.mapReportEmpVolSubtitle2":"Participantes (No empleados) por parte de la entidad",
	"reportChart.mapReportEmpVolSubtitle3":"Impactos sobre los empleados/voluntarios de la entidad",
	"reportChart.mapReportEmpVolSubtitle4":"Participantes por parte de otros colaboradores",
	"reportChart.mapReportEmpVolSubtitle5":"Participantes externos por parte de otros colaboradores",
	"reportChart.mapReportEmpVolSubtitle6":"Impactos sobre los empleados/voluntarios de otros colaboradores",
	"reportChart.mapReportEfectoMul1":"Efecto Multiplicador de la entidad",
	"reportChart.mapReportEfectoMul2":"Efecto Multiplicador de otros colaboradores",	
	"reportChart.mapReportrescom1":"Logros: Beneficiarios",
	"reportChart.mapReportrescom2":"Impactos - Grado: Beneficiarios",
	"reportChart.mapReportrescom3":"Impactos - Tipo: Beneficiarios",
	"reportChart.mapReportrescom4":"Logros: Organizaciones beneficiarias",
	"reportChart.mapReportrescom5":"Logros de las Organizaciones beneficiarias",
	"reportChart.mapReportrescom6":"Impactos: Organizaciones beneficiarias",
	"reportChart.mapReportrescom7":"Logros: Medioambientales",
	"reportChart.mapReportrescom8":"Impactos: Medioambientales",
	"reportChart.mapReportresent1":"Logros: En la entidad",
	"reportChart.mapReportresent2":"Impactos: En la entidad",
	"reportChart.header.logeimp":"Resultados: Empleados y Voluntarios",
	"reportChart.header.efecto":"Efecto Multiplicador",
	"reportChart.header.rescom":"Resultados en la Comunidad: Logros e Impactos",
	"reportChart.header.resent":"Resultados de la Entidad: Logros e Impactos",
	"reportChart.header.sensibilizados":"Sensibilizados",
	"reportChart.header.mejorados":"Mejorados",
	"reportChart.header.transformados":"Transformados",
	"reportChart.mapReportEfectoMul.entidad.Otros":"Otros {{ITV}}",
	"reportChart.mapReportEfectoMul.entidad.Total":"Total {{ITV}}",
	"manual-descargaDatos":"https://manual.masimpact.com/es/3/menu_principal/8/informes/1/descarga_de_datos_en_excel",
	"manual-informesPantalla":"https://manual.masimpact.com/es/3/menu_principal/8/informes/2/informes_en_pantalla",
	"pais.nombre.global":"Global",
	"filterReport.byInitiatives":"Por iniciativas",
	"pdf.download":"Descargar PDF",
	"initiativeform.changeproyect":"Mover iniciativa de proyecto",
	"contribucions.formajuridica":"Forma jurídica"
})